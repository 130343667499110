@import "~bootstrap/scss/bootstrap";
@import "../../assets/scss/base/base-include.scss";

.landing__page__wrapper {
    margin: 0px;
    padding: 0px;
    height: 100vh;

    .landing__page__container {
        height: 100vh;
        padding: 0px;
        display: flex;
        //height: 1400px;
        position: fixed;
    }

    .landing__page__left__card {
        width: 720px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 100px;
        padding-right: 50px;
        //padding: 80px 50px 0px 100px;

        .left__card__inner {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .image__container {
                width: 229px;
                height: 150px;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .image__container img {
                max-width: 100%;
                max-height: 100%;
            }

            .vb__desc {
                padding-top: 20px;
                text-align: center;

                .p {
                    @extend %font15-regular;
                }
            }

            .order__text {
                text-transform: uppercase;
                padding-top: 30px;
            }

            .order__platform__icons {
                padding-top: 20px;
                display: flex;
                justify-content: space-between;

                .icon {
                    width: 60px;
                    height: 60px;
                    margin: 0 10px;
                }
            }

            .preview {
                padding-top: 30px;

                .preview__btn {
                    width: 250px;
                    height: 50px;
                    text-transform: uppercase;
                    background: #EC380B;
                    color: #fff;
                    border-radius: 4px;
                    cursor: pointer;
                    border: none;
                    outline: none;
                }
            }

        }
    }

    .landing__page__right__card {
        height: 100vh;
        width: 720px;
        background-size: contain;
        background-position: center;
        //background-repeat: no-repeat;
    }

         .background__image__container {
             height: 100%;
             width: 100%;
         }
    
         .background__image {
             position: absolute;
             top: 0;
             left: 0;
             width: 100%;
             height: 100%;
             background-size: contain;
             background-position: center;
             filter: blur(5px);
             z-index: -1;
         }
    
         .overlay__container {
             height: 100%;
             width: 60%;
             top: 0;
             left: 0;
             right: 0;
             bottom: 0;
             margin: auto;
             position: absolute;
             display: flex;
             flex-direction: column;
             justify-content: center;
             align-items: center;

            .overlay__inner {
                    background-color: #fff;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    .image__container {
                        margin-top: 20px;
                        width: 200px;
                        height: 100px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }

                    .image__container img{
                        max-width: 100%;
                        max-height: 100%;
                    }
            
                    .vb__desc {
                        padding-top: 20px;
                        @extend %font15-regular;
                        text-align: center;
                        padding-left: 15px;
                        padding-right: 15px;
                    }
                    .landing__track__btn{
                        margin-right: 0px !important;
                        margin-bottom: 20px;
                    }
                }
             .order__text {
                 text-transform: uppercase;
                 padding-top: 30px;
                @extend %font15-semibold;
                color: white;
             }
    
             .order__platform__icons {
                 padding-top: 20px;
                 display: flex;
                 justify-content: space-between;
    
                 .icon {
                     width: 60px;
                     height: 60px;
                     margin: 0 10px;
                 }
             }
    
         }
}