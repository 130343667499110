@import "~bootstrap/scss/bootstrap";
@import "../../assets/scss/base/base-include.scss";


.addNewModifier__wrapper{

    h2{
        @extend %font25-bold;
        line-height: 1.32;
        color: #131414;


        @include bp(mobile){
            font-size: 25px !important;
        }

    }

    .addNewModifier__wrapper__inner{
        padding-top: 32px;
        text-align: left;
    }

    .addmodifiers__options {
        padding-top: 40px;
        > button {
            background: unset;
            border: none;
            outline: none;
        }
    }
}

.addModifiers:hover{
    color: $theme-red;
    text-decoration: none;
}

.modifieroptions__wrap{
    display: flex;

    > div{
        width: 50%;

        &:not(:last-child){
            margin-right: 12px;
        }
    }
}

.delete_modifier_wrapper{
    display: flex;

    .delete_modifier{
        border: none;
        outline: none;
        background: none;
        margin-left: 12px;
        img{
            margin:unset;
          }
    }
}