@import "~bootstrap/scss/bootstrap";
@import "../../assets/scss/base/base-include.scss";

.errorPage__wrapper {
    height: 85vh;
    display: flex;
    animation: ge;
    align-items: center;
    justify-content: center;

    .error__box {
        max-width: 500px;
        margin: auto;
        text-align: center;
        .error__imgbox {
            margin-bottom: 36px;
        }
        .error__details{
            h1{
                font-family: 'Work Sans', sans-serif;  
                font-weight: 700;
                font-size: 31px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: center;
                color: #161626;
                margin-bottom: 16px;
            }

            p{
                @extend %font15-regular;
                line-height: 1.53;
                letter-spacing: normal;
                text-align: center;
                color: #949494;
            }

            .error-btn{
                @extend %font16-semibold;
                padding: 14px 34px 14px 35px;
                border-radius: 4px;
                background-color: #ec380b;
                border: none;
                color: #fff;
                margin-top: 16px;
                display: inline-block;

                &:hover{
                    text-decoration: none;
                }
            }
        }
    }    
}