@import "~bootstrap/scss/bootstrap";
@import "../../../assets/scss/base/base-include.scss";

@media screen and (min-width:1200px) and (max-width:1320px) {
    .container1 {
        width: 700px;
    }

    .container2{
        width: 440px;
    }
}

@media screen and (min-width: 1024px) and (max-width: 1199px) {
    .container1 {
        width: 600px;
    }

    .container2{
        width: 360px;
    }
  }

@media screen and (max-width: 720px) {
    .container1 {
        width: 100% !important;
    }
  }

  @media screen and (min-width:768px) and (max-width:1024px) {
      .statistics_container {
          padding-top: 25px;
      }
  }

  .statistics_container {
      @include bp(mobile) {
          padding-top: 25px;
      }
  }

.statistics__card__wrapper {
    height: 490px;
    border-radius: 4px;
    box-shadow: 0 5px 9px -3px rgba(0, 0, 0, 0.08);
    background-color: #fff;
    /* padding: 29px 26px 47px 32px; */
    padding: 30px 40px 35px 40px;

    @include bp(ipadpro) {
        padding: 29px 26px 30px 32px;
    }

    @include bp(mobile) {
        padding: 25px 15px;
        max-width: 100%;
        width: 345px;
        height: 570px;
    }

    @include bp(991) {
        max-width: 400px;            
    }

    .statistics__card__wrapper__inner {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 20px;

        @include bp(xl) {
            grid-template-columns: repeat(1, 1fr);
            grid-gap: 20px;
        }

        @include bp(991) {
            grid-template-columns: repeat(1, 1fr);
            grid-gap: 22px;
            max-width: 100% !important;
        }

        @include bp(mobile) {
            grid-template-columns: repeat(1, 1fr);
            grid-gap: 20px;
            max-width: 100% !important;
        }

        .summary__card {
            padding: 20px 20px;
            border-radius: 4px;
            background-color: #f6f9fb;
            min-height: 130px;

            .summary__card__data {
                display: flex;
                justify-content: space-between;
                height: 100%;

                @include bp(mobile) {
                    // flex-direction: column-reverse;
                }

                .summary__card__data__left {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: space-between;

                    p {
                        margin: 0;

                    }

                    .label {
                        @extend %font15-regular;
                        line-height: 1.53;
                        letter-spacing: normal;
                        color: #131414;

                        @include bp(mobile) {
                            font-size: 12px;
                            line-height: 1.33;
                            margin-bottom: 8px;
                        }
                    }

                    .label__data {
                        @extend %font25-bold;
                        line-height: 1.32;
                        letter-spacing: normal;
                        color: #131414;

                        @include bp(mobile) {
                            font-size: 21px;
                            line-height: 1.38;
                        }
                    }
                }

                .summary__card__data__right {

                    @include bp(mobile) {
                        text-align: right;
                        margin-bottom: 10px;
                    }

                    .img-box {
                        width: 60px;
                        height: 60px;
                        box-shadow: 0 6px 9px 0 rgba(0, 0, 0, 0.1);
                        background-color: #fff;
                        border-radius: 50%;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        @include bp(mobile) {
                            width: 40px;
                            height: 40px;
                            margin-left: auto;
                        }
                    }
                }
            }
        }
    }
}