@import "~bootstrap/scss/bootstrap";
@import "../../../assets/scss/base/base-include.scss";

.customers__listinPage {
    .column_1 {
      width: 30% !important;
      text-transform: capitalize;
      overflow: hidden;
      p{
        margin: 8px 0;
      }
    }
  
    .column_2 {
      width: 15% !important;
      text-transform: capitalize;
      p{
        margin: 8px 0;
      }
    }
  
    .column_3 {
      width: 27%;
      text-transform: capitalize;
      p{
        margin: 8px 0;
      }
    }
  
    .column_4 {
      width: 15% !important;
      text-transform: capitalize;
      white-space: nowrap;
      p{
        margin: 8px 0;
      }
    }
  
    .column_5 {
      width: 10% !important;
      text-transform: capitalize;
      white-space: nowrap;
      p{
        margin: 8px 0;
      }
    }
  
    .column_6 {
      width: 30% !important;
      text-transform: capitalize;
      justify-content: flex-end !important;
      p{
        margin: 8px 0;
      }
    }
  
    .column_7 {
      width: 7% !important;
      text-transform: capitalize;
      justify-content: flex-end !important;
      p{
        margin: 8px 0;
      }
    }
  
      @include bp(991) {
        .column_1,
        .column_2,
        .column_3,
        .column_4,
        .column_5,
        .column_6,
        .column_7 {
          width: 100% !important;
          p{
            margin: 0;
          }
        }
      }

      @include bp(mobile) {
        .column_6 {
        justify-content: flex-start !important;
        }
      }
      
      .email{
      text-overflow: ellipsis;
      overflow: hidden;
      }
      .customers__channel__filter{
        width: 180px !important;
      }
      .customers__date__filter{
        width: 265px !important;
      }
      .customers__kitchen__filter{
        width: 250px !important;
      }
      .customers__search{
        width: 235px !important;
      }   
  }
  