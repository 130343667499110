@import "~bootstrap/scss/bootstrap";
@import "../../assets/scss/base/base-include.scss";

.confirmation__wrapper {
  div#confirmation__modal {
    max-width: 610px;
  }
}

div#confirmation__modal .modal-content {
  padding: 32px;
  border-radius: 4px !important;
}

div#confirmation__heading {
  font-family: 'Work Sans', sans-serif;
          font-weight: 700;
  font-size: 25px;
  line-height: 1.32;
  letter-spacing: normal;
  color: #131414;
  margin-bottom: 16px;
}

div#confirmation__modal .modal-footer {
  .btn {
    @extend %font16-semibold;
    min-width: 128px;
    border: 1px solid #ec380b;
    background-color: $theme-red;
    color: #fff;
    // display: flex;
    // align-items: center;
    width: auto;
    padding: 10px 24px;

    &:not(:last-child) {
      margin-right: 30px;

      @include bp(mobile) {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
      }
    }

    &:focus {
      box-shadow: none;
    }

    &:hover {
      color: #fff;
    }

    .icons-right {
      margin-top: -5px;
    }

    &.red-btn-ghost {
      background: unset;
      color: $theme-red;

      &:hover {
        color: #fff;
        color: $theme-red;
        box-shadow: 0 2px 10px 0 rgba(218, 0, 0, 0.17);
      }
    }

    @include bp(mobile) {
      width: 100%;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
