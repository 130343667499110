@import "~bootstrap/scss/bootstrap";
@import "../../../../assets/scss/base/base-include.scss";

.AddNewConnection__wrapper {
  .editModifier__wrapper .basic__info__content select.form-control {
    background: #fff url(../../../../assets/images/select-arrow-icon.svg)
      no-repeat 96% !important;
  }

  .editModifier__wrapper .basic__info__content select.form-control.big-select {
    background: #fff url(../../../../assets/images/select-arrow-icon.svg)
      no-repeat 96% !important;
  }

  .editModifier__wrapper .basic__info__content select[name="stateId"] {
    background: #fff url(../../../../assets/images/select-arrow-icon.svg)
      no-repeat 92% !important;
  }

  .basic__info__content .form-control {
    font-size: 15px;
  }

  .basic__info__content {
    label {
      margin-bottom: 16px;
    }
  }

  @include bp(991) {
    .editModifier__wrapper .basic__info__content .form-row {
      max-width: 100%;
    }
  }

  @include bp(mobile) {
    .modal-footer {
      margin-top: 40px;
    }
  }
}

.edit__connection__wrapper {
  .modifierDetails__wrapper {
    background-color: #f8f9fa;
    padding-top: 48px;
    padding-bottom: 65px;
    height: auto;

    @include bp(mobile) {
      padding-top: 40px;
      padding-bottom: 64px;
      height: auto;
    }

    .goBack {
      margin-bottom: 32px;
      @include bp(mobile) {
        margin-bottom: 24px;
        display: none;
      }
    }

    .main_heading {
      padding-bottom: 24px;

      h1 {
        @extend %font32-bold;
        line-height: 1.25;
        color: #131414;
        margin-bottom: 0;

        @include bp(mobile) {
          font-size: 21px;
          margin-bottom: 8px;
        }
      }
    }

    .details__wrapper {
      box-shadow: none;

      .details__wrapper__inner {
        @include bp(mobile) {
          flex-direction: column;

          .right_content {
            > span {
              flex: 1;
              text-align: center;
            }
          }
        }
      }

      .left_content {
        max-width: 471px;

        @include bp(991) {
          max-width: 400px;
        }

        @include bp(mobile) {
          max-width: 100%;
        }

        select.form-control {
          background-position: 96%;
        }
      }

      .right_content {
        @include bp(mobile) {
          margin-top: 32px;
        }
      }
    }
  }

  .modal-footer {
    @include bp(mobile) {
      margin-top: 40px;
    }
  }
}
//Choose date time
.rdt {
  position: relative;
  display: flex;
  //reduced height
  .basic__info__content .form-control {
      //border: 1px solid #c0c0c8;
      //background-color: #fff;
      border-radius: 4px;
      height: 48px;
      //max-width: 503px;
      //font-size: 15px;
      font-weight: 400;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.53;
      letter-spacing: normal;
      color: #131414;
    }
        .form-control[readonly] {
          border: 1px solid #c0c0c9 !important;
          background-color: none;
          /* background-color: #f5f5f5!important; */
        }
}

.rdtPicker {
  display: none;
  position: absolute;
  width: 250px;
  padding: 4px;
  margin-top: 1px;
  z-index: 99999 !important;
  background: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, .1);
  border: 1px solid #f9f9f9;
  font-size: 12px;
}

.rdtOpen .rdtPicker {
  display: block;
}

.rdtStatic .rdtPicker {
  box-shadow: none;
  position: static;
}

.rdtPicker .rdtTimeToggle {
  text-align: center;
}

.rdtPicker table {
  width: 100%;
  margin: 0;
}

.rdtPicker td,
.rdtPicker th {
  text-align: center;
  height: 28px;
}

.rdtPicker td {
  cursor: pointer;
}

.rdtPicker td.rdtDay:hover,
.rdtPicker td.rdtHour:hover,
.rdtPicker td.rdtMinute:hover,
.rdtPicker td.rdtSecond:hover,
.rdtPicker .rdtTimeToggle:hover {
  background: #eeeeee;
  cursor: pointer;
}

.rdtPicker td.rdtOld,
.rdtPicker td.rdtNew {
  color: #999999;
}

.rdtPicker td.rdtToday {
  position: relative;
}

.rdtPicker td.rdtToday:before {
  content: '';
  display: inline-block;
  border-left: 7px solid transparent;
  border-bottom: 7px solid #428bca;
  border-top-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  bottom: 4px;
  right: 4px;
}

.rdtPicker td.rdtActive,
.rdtPicker td.rdtActive:hover {
  background-color: rgb(236, 56, 11);
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  border-top-left-radius: 1.042em;
  border-bottom-left-radius: 1.042em;
  border-top-right-radius: 1.042em;
  border-bottom-right-radius: 1.042em;
}

.rdtPicker td.rdtActive.rdtToday:before {
  border-bottom-color: #fff;
}

.rdtPicker td.rdtDisabled,
.rdtPicker td.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}

.rdtPicker td span.rdtOld {
  color: #999999;
}

.rdtPicker td span.rdtDisabled,
.rdtPicker td span.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}

.rdtPicker th {
  border-bottom: 1px solid #f9f9f9;
}

.rdtPicker .dow {
  width: 14.2857%;
  border-bottom: none;
  font-weight: 400;
  line-height: 2.667em;
  color: #849095;
}

.rdtPicker th.rdtSwitch {
  width: 100px;
}

.rdtPicker th.rdtNext,
.rdtPicker th.rdtPrev {
  font-size: 21px;
  vertical-align: top;
}

.rdtPrev span,
.rdtNext span {
  display: block;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
}

.rdtPicker th.rdtDisabled,
.rdtPicker th.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}

.rdtPicker thead tr:first-child th {
  cursor: pointer;
  font-weight: 600;
  color: #849095;
}

.rdtPicker thead tr:first-child th:hover {
  background: #eeeeee;
}

.rdtPicker tfoot {
  border-top: 1px solid #f9f9f9;
}

.rdtPicker button {
  border: none;
  background: none;
  cursor: pointer;
}

.rdtPicker button:hover {
  background-color: #eee;
}

.rdtPicker thead button {
  width: 100%;
  height: 100%;
}

td.rdtMonth,
td.rdtYear {
  height: 50px;
  width: 25%;
  cursor: pointer;
}

td.rdtMonth:hover,
td.rdtYear:hover {
  background: #eee;
}

.rdtCounters {
  display: inline-block;
}

.rdtCounters>div {
  float: left;
}

.rdtCounter {
  height: 100px;
}

.rdtCounter {
  width: 40px;
}

.rdtCounterSeparator {
  line-height: 100px;
}

.rdtCounter .rdtBtn {
  height: 40%;
  line-height: 40px;
  cursor: pointer;
  display: block;

  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
}

.rdtCounter .rdtBtn:hover {
  background: #eee;
}

.rdtCounter .rdtCount {
  height: 20%;
  font-size: 1.2em;
}

.rdtMilli {
  vertical-align: middle;
  padding-left: 8px;
  width: 48px;
}

.rdtMilli input {
  width: 100%;
  font-size: 1.2em;
  margin-top: 37px;
}

.rdtDay.in-selecting-range {
  background-color: rgb(236, 56, 11);
}
