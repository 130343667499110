@import "~bootstrap/scss/bootstrap";
@import "../../../assets/scss/base/base-include.scss";

ul {
  margin: 0;
}

.nav__menu {
  // background-color: #fff;
  // width: 492px;
  // height: 100vh;
  // display: flex;
  // justify-content: center;
  // position: fixed;
  // top: 0;
  // left: -100%;
  // transition: all 0.5s ease-in-out;
  // box-shadow: 1px 2px 18px 0 #414a6b;
  // z-index: 99;

  @include bp(mobile) {
    width: 0;
  }

  &.active {
    transition: 0.5s;
    left: 0;

    @include bp(mobile) {
      width: 325px;
    }
  }

  .nav__menu__items {
    padding: 0;
    width: 100%;
    height: calc(100vh - 52px);
    overflow-y: scroll;

    .nav__text {
      font-family: "Work Sans", sans-serif;
      font-weight: 400;
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: normal;
      color: $theme-gray;
      display: block;
      transition: 0.3s;
      margin-left: 96px;
      border-radius: 4px;
      margin-bottom: 8px;
      
      @include bp(mobile) {
        margin-left: 10px;
        margin-bottom: 12px;
      }

      > a {
        color: $theme-gray;
        text-decoration: none;
        padding: 12px 17px 12px 17px;
        display: inline-block;
        max-width: 290px;
        width: 100%;
        border-radius: 4px;
        font-family: "Work Sans", sans-serif;
        font-weight: 400;

        &:hover {
          background-color: rgba(236, 56, 11, 0.07);
          font-family: "Work Sans", sans-serif;
          font-weight: 500;
          color: #ec380b;

          > .icons {
            > img {
              &:first-child {
                display: none;
              }

              &:last-child {
                display: inline-block;
              }
            }
          }
        }

        > .icons {
          margin-right: 25px;
          width: 20px;
          display: inline-block;
          vertical-align: text-top;

          > img {
            &:last-child {
              display: none;
            }
          }
        }

        &.active {
          background-color: rgba(236, 56, 11, 0.07);
          font-family: "Work Sans", sans-serif;
          font-weight: 500;
          color: #ec380b;

          > .icons {
            > img {
              &:first-child {
                display: none;
              }

              &:last-child {
                display: inline-block;
              }
            }
          }
        }
      }
    }
  }
}

.navbar__toggle {
  height: 63px;
  padding: 16px 25px;
  border: solid 1px #efefef;
  margin-bottom: 16px;

  .sidebar__logo {
    margin-left: 70px;
    img {
      width: 110px;
    }

    @include bp(mobile) {
      margin-left: 16px;
    }
  }
}

.modal.left.fade.show .modal-dialog {
  left: 0px;
}

.modal.left.fade .modal-dialog {
  left: -492px;
  -webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, left 0.3s ease-out;
  -o-transition: opacity 0.3s linear, left 0.3s ease-out;
  transition: opacity 0.3s linear, left 0.3s ease-out;
}

.modal.left .modal-dialog {
  position: fixed;
  margin: auto;
  width: 492px;
  height: 100%;
  box-shadow: -1px 1px 23px 0 rgba(0, 0, 0, 0.7);
  -webkit-transform: translate3d(0%, 0, 0);
  -ms-transform: translate3d(0%, 0, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);

  @include bp(mobile) {
    width: 325px;
  }
}

#sidebarnav {
  .modal-content {
    height: 100%;
    padding: 0;

    .modal-body {
      padding: 0;
    }
  }
}

.mySidenav {
  position: relative;
}

.nav__text.last__tab{
  margin-bottom: 62px !important;
}

.nav__text.logout {
  position: absolute;
  bottom: -1px;
  background-color: #f7f7f7;
  width: 100%;
  margin-left: 0 !important;
  height: 60px;
  margin-bottom: 0 !important;
  @include bp(mobile) {
    margin-bottom: 0px !important;
  }

  > a {
    padding: 18px 107px 17px 110px !important;
    max-width: 100% !important;
    width: 100% !important;

    @include bp(mobile) {
      padding: 18px 27px 17px !important;
      display: flex;
    }
  }
}

@media screen and (max-width: 991px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
  .nav__menu .nav__menu__items .nav__text {
    margin-bottom: 0px;
  }

  .nav__menu .nav__menu__items .nav__text > a {
    padding: 7px 17px;
    font-size: 14px;
  }
}

@media screen and (min-width: 768px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
  .nav__text.logout > a {
    padding: 18px 107px 17px 114px !important;
  }
}
