@import "~bootstrap/scss/bootstrap";
@import "../../../assets/scss/base/base-include.scss";

.footer {
  padding: 22px 0px;
  border-top: solid 1px #ececec;

  @include bp(mobile) {
    text-align: center;
    padding-bottom: 0px;
  }
  .footer-container {
    padding: 17px 95px 18px;
    border-top: solid 1px #ececec;
    background: #fff;
  }

  .footer-logo {
    > img {
      width: 100px;
    }
    @include bp(mobile) {
      padding-top: 10px;
      width: 100%;

      > img {
        width: 150px;
      }
    }

    @include bp(360) {
      > img {
        width: 120px;
      }
    }
  }

  .footer-wrapper {

    > div{align-items: center;}
    .footer-left {
      display: flex;
      align-items: center;

      @include bp(mobile) {
        justify-content: center;
      }

      .copyright {
        margin-left: 40px;

        @include bp(mobile) {
          margin-left: unset;
          padding: 32px 0px;
          width: 100%;
          border-top: solid 1px #ececec;
        }

        > p {
          margin-bottom: 0;
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #6d7f8b;
        }
      }
    }
  }

  .footer-link {
    @include bp(mobile) {
      padding: 40px 0px 48px;
    }

    ul {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-bottom: 0;

      @include bp(mobile) {
        padding: 0;
        justify-content: center;
      }

      > li {
        list-style-type: none;

        > a {
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: normal;
          text-align: right;
          color: $footerlink-color;
          position: relative;
          padding: 0px 20px;
          text-decoration: none;

          @include bp(ipad) {
            padding: 0px 14px;
          }

          &::after {
            position: absolute;
            right: 0;
            content: "";
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background-color: #c0c0c8;
            top: 0;
            bottom: 0;
            margin: auto;
          }

          &:hover {
            text-decoration: none;
          }
        }

        &:last-child {
          a {
            padding-right: 0;

            &::after {
              display: none;
            }
          }
        }

        &:first-child {
          a {
            padding-left: 0;
          }
        }
      }
    }
  }
}


@media screen and (min-width:768px) and (max-width:999px){
  .footer .footer-wrapper .footer-left .copyright {
    margin-left: 28px;
}
}