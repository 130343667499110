@import "~bootstrap/scss/bootstrap";
@import "../../../assets/scss/base/base-include.scss";

.manageUser__landingPage__wrapper{
    .managermodifier__header{
        .manage-user-search{
            max-width: 400px;

            @include bp(ipadpro){
                max-width: 100%;
            }
        }
    
        .savedraft__wrapper .saveAsDraft__btn > .btn{
            height: 46px;
            color: #1b2236;

            @include bp(mobile){
                height: 38px;
                padding: 10px 15px;
            }
        }
    
        .savedraft__wrapper .saveAsDraft__btn__gray{
            border: solid 1px #6d7f8b;
    
            > button{
                background: transparent !important;
            }
        }
    
        .form{
            justify-content: flex-end;
            flex-direction: row !important;

            .manage-user-search{
                margin-bottom: 0 !important;
            }

            .adduser__btn{
                .red-btn.btn{
                    height: 48px;
                    @include bp(mobile){
                        height: 40px;
                    }
                }
            }
    
            @include bp(mobile){
                flex-direction: row;
                flex-wrap: wrap;

                .manage-user-search{
                    width: 48%;
                    margin-bottom: 0;
                    margin-right: 16px;
                }

                .adduser__btn{
                    width: 100%;
                    max-width: 100%;
                }
            }
        }
        
        .userselect{
            .savedraft__wrapper{
                padding-top: 0px;
            }
            @include bp(mobile){
                width: 48%;
                margin-bottom: 16px;
            }
        }
    }

    .savedraft__wrapper .saveAsDraft__btn button#dropdown-split-basic::after{
        background: url(../../../assets/images/select-arrow-icon.svg) center no-repeat;
        background-size: 14px;
    }

    .savedraft__wrapper .saveAsDraft__btn button#dropdown-split-basic{
        padding-top: 14px;
        @include bp(mobile){
            padding-top: 12px;
        }
    }
}



.basic__info__content{
    .user__custom__select {
      .custom-select-ckc__control::after {
        position: absolute;
        content: url("../../../assets/images/Icon-arrow-top-ckc.svg");
        width: 15px;
        // height: 30px;
        height: 20px;
        top: 0;
        bottom: 0;
        right: 15px;
        margin: auto;

        @include bp(mobile){
            height: 15px;
        }
      }
    }
  }