@import "~bootstrap/scss/bootstrap";
@import "../../../assets/scss/base/base-include.scss";

.basic__info {
  .main__heading {
    padding-bottom: 40px;

    @include bp(mobile) {
      padding-bottom: 24px;
    }

    h2 {
      @extend %font32-bold;
      margin-bottom: 12px;
      color: $heading-color;
      line-height: 1.25;

      @include bp(mobile) {
        font-size: 21px;
        margin-bottom: 6px;
      }
    }

    p {
      @extend %font15-regular;
      color: #6d7f8b;
      opacity: 0.9;
      margin-bottom: 0;
    }
  }
}

.basic__info__content {
  label {
    @extend %font14-semibold;
    color: #131414;
    margin-bottom: 12px;
  }

  .form-control {
    // box-shadow: 0 6px 10px 0 rgba(13, 116, 134, 0.04);
    border: solid 1px #c0c0c8;
    background-color: #fff;
    border-radius: 4px;
    height: 52px;
    max-width: 503px;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.53;
    letter-spacing: normal;
    color: #131414;

    &:focus {
      border: solid 1px #414a6b;
    }

    &::placeholder,
    &::-moz-placeholder,
    &::-webkit-input-placeholder {
      color: #c0c0c9;
    }

    @include bp(xl) {
      font-size: 13px;
    }
  }

  textarea {
    min-height: 174px;
  }
}

.switches {
  .switch {
    position: relative;
    display: inline-block;
    width: 54px;
    height: 26px;
    margin-bottom: 5px !important;

    input {
      opacity: 0;
      width: 0;
      height: 0;

      &:checked + .slider {
        background-color: #fff;
        border-color: $theme-red;
        &:before {
          background-color: $theme-red;
        }
      }

      &:focus + .slider {
        box-shadow: 0 0 1px $theme-red;
      }

      &:checked + .slider:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
      }
    }

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #fff;
      border: 1px solid #c0c0c8;
      -webkit-transition: 0.4s;
      transition: 0.4s;

      /* Rounded sliders */
      &.round {
        border-radius: 34px;

        &:before {
          border-radius: 50%;
        }
      }

      &:before {
        position: absolute;
        content: "";
        height: 20px;
        width: 20px;
        left: 3px;
        bottom: 2px;
        background-color: #c0c0c8;
        -webkit-transition: 0.4s;
        transition: 0.4s;
      }
    }
  }
}

.actions-wrapper {
  display: flex;
  align-items: center;

  > div {
    display: flex;
    align-items: center;

    label.days {
      width: 100px;
      margin-bottom: 0;
      text-transform: capitalize;

      @include bp(ipad) {
        margin-bottom: 12px;
      }
    }

    &:nth-of-type(2) {
      flex: 1;
    }
  }

  @include bp(991) {
    flex-direction: column !important;
    justify-content: flex-start !important;

    > div {
      width: 100%;
      display: flex;
    }
  }

  > label {
    margin-bottom: 0px !important;
    flex: 1;
  }

  select.form-control.time-select {
    max-width: 162px;
    width: 100%;
    height: 44px;
    margin-right: 24px;
  }
}

.basic__info__content {
  .left-side-form-wrapper {
    padding: 24px 32px 37px;
    border-radius: 8px;
    border: solid 1px #c0c0c8;
    background-color: #fff;

    .operating-hours {
      h3 {
        @extend %font21-bold;
        line-height: 1.38;

        @include bp(mobile) {
          font-size: 18px;
        }
      }

      p {
        @extend %font15-regular;
        line-height: 1.53;
        color: #5e5e5e;

        @include bp(mobile) {
          font-size: 12px;
        }
      }
    }

    select.form-control.time-select {
      @include bp(991) {
        max-width: 42%;
        margin-right: 21px;
      }

      @include bp(mobile) {
        height: 44px;
      }
    }
  }
}

.progress {
  border-radius: 10px;
  height: 8px;

  .progress-bar {
    background-color: $theme-red;
  }
}

.deliveryRadiuscount {
  font-family: 'Work Sans', sans-serif;
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3f4a6e;
  display: flex;
  justify-content: space-between;

  span {
      margin-top: 9px;
    }
}

.radius__value {
  font-size: 13px !important;
  width: 58px !important;
  height: 32px !important;
  padding: 0px 4px;
  border-radius: 4px !important;
  background-color: #414a6b !important;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
      margin: 0px !important;
    }
}

.right__switch{
  margin-right: 24px;
}

.timings__icon{
  border: none;
  outline: none;
  background: transparent;
  img{
      margin:unset;
    }
}

.timing__row{
  display: flex;
  width: 350px;
  margin-bottom: 8px;
}

