@import "~bootstrap/scss/bootstrap";
@import "../../assets/scss/base/base-include.scss";

.nothingadded__wrapper{
    .nothing__card__wrapper{
        .nothing__card__wrapperInner{
            max-width: 350px;
            margin: auto;
            text-align: center;
            padding: 60px 0px;

            .img__box{
                margin-bottom: 24px;
            }

            .content__box{
                h2{
                    font-family: 'Work Sans', sans-serif;  
                    font-size: 31px;
                    font-weight: 700;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    text-align: center;
                    color: #161626;

                    @include bp(mobile){
                        font-size: 21px;
                        line-height: 1.43;
                    }
                }

                p{
                    @extend %font15-regular;
                    color: #949494;

                    .text__highlighted{
                        color: #414a6b;
                    }
                }
            }
        }
    }
}