@import "~bootstrap/scss/bootstrap";
@import "../../../../../assets/scss/base/base-include.scss";

.right > .modal-dialog {
  width: 705px;
  right: -704px;
  position: fixed;
  margin: auto;
  height: 100%;
  // box-shadow: -1px 1px 23px 0 rgba(0, 0, 0, 0.07);
  -webkit-transform: translate3d(0%, 0, 0);
  -ms-transform: translate3d(0%, 0, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
  @include bp(ipad-min) {
    max-width: 705px;
    width: 100%;
  }

  @include bp(mobile) {
    max-width: 100%;
  }
}

.right > .modal-dialog > .modal-content {
  height: 100%;
  padding-right: 95px;

  @include bp(mobile) {
    padding: 24px 15px !important;
    border-radius: 0;
  }
}

.right .modal-content {
  overflow-y: auto;
  // box-shadow: -1px 1px 23px 0 rgba(0, 0, 0, 0.07);
}

.right .modal-body {
  padding: 0;
}

/*Right*/
.right > .modal-dialog {
  right: -704px;
  -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
  -o-transition: opacity 0.3s linear, right 0.3s ease-out;
  transition: opacity 0.3s linear, right 0.3s ease-out;
}

.right.show > .modal-dialog {
  right: 0px;
}

/* ----- MODAL STYLE ----- */
.modal-content {
  border-radius: 0;
  border: none;
  padding: 40px 40px 41px 40px;
}

.modal-header {
  padding: 0;
  h1 {
    @extend %font32-bold;
    color: #131414;

    @include bp(mobile) {
      font-size: 21px;
    }
  }
}

.createcatTbs__wrapper {
  margin-top: 16px;
}

.modal-footer {
  @include bp(mobile) {
    flex-direction: column-reverse;
  }

  .btn {
    min-width: 128px;
    border: 1px solid #ec380b;
    background-color: $theme-red;
    color: #fff;
    display: flex;
    align-items: center;
    width: auto;
    padding: 10px 24px;

    &:not(:last-child) {
      margin-right: 30px;
    }

    &:hover {
      color: #fff;
    }

    .icons-right {
      margin-top: -10px;
    }

    @include bp(mobile) {
      max-width: 100%;
      width: 100% !important;
      margin-left: 0 !important;
      margin-right: 0 !important;
    }

    &.red-btn-ghost {
      background: unset;
      color: $theme-red;

      @include bp(mobile) {
        margin: 0;
        margin: 24px 0px;
      }

      &:hover {
        color: #fff;
        color: $theme-red;
      }
    }
  }

  .skip__link {
    margin-right: 30px;
    @include bp(mobile) {
      margin: 0;
    }
  }
}

.switches {
  .switch {
    position: relative;
    display: inline-block;
    width: 54px;
    height: 26px;

    input {
      opacity: 0;
      width: 0;
      height: 0;

      &:checked + .slider {
        background-color: #fff;
        border-color: $theme-red;
        &:before {
          background-color: $theme-red;
        }
      }

      &:focus + .slider {
        box-shadow: 0 0 1px $theme-red;
      }

      &:checked + .slider:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
      }
    }

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #fff;
      border: 1px solid #c0c0c8;
      -webkit-transition: 0.4s;
      transition: 0.4s;

      /* Rounded sliders */
      &.round {
        border-radius: 34px;

        &:before {
          border-radius: 50%;
        }
      }

      &:before {
        position: absolute;
        content: "";
        height: 20px;
        width: 20px;
        left: 3px;
        bottom: 2px;
        background-color: #c0c0c8;
        -webkit-transition: 0.4s;
        transition: 0.4s;
      }
    }
  }
}

.actions-wrapper {
  display: flex;
  align-items: center;

  > label {
    margin-bottom: 0px !important;
    flex: 1;
  }

  > select.form-control.time-select {
    max-width: 205px;
    height: 44px;
    margin-right: 24px;
    line-height: initial;
  }
}

.basic__info__content {
  .left-side-form-wrapper {
    padding: 24px 32px 37px;
    border-radius: 8px;
    border: solid 1px #c0c0c8;
    background-color: #fff;

    @include bp(991) {
      padding: 16px 15px;
    }
  }
}

.CreateMenuAvailabity__wrapper {
  margin-bottom: 56px;

  h2 {
    @extend %font25-bold;

    @include bp(mobile) {
      font-size: 18px !important;
    }
  }

  .main-heading {
    p {
      @extend %font15-regular;
      color: #6d7f8b;
    }
  }

  h3 {
    @extend %font21-bold;

    @include bp(mobile) {
      font-size: 18px;
    }
  }

  .operating-hours {
    margin-bottom: 36px;

    @include bp(mobile) {
      margin-bottom: 24px;
    }
  }
}

.operatingHours__wrapper {
  margin-top: 32px;
}

.basic__info__content .form-control {
  max-width: 100%;

  @include bp(mobile) {
    height: 48px;
  }
}

.modal-footer {
  margin-top: 56px;
}

.CreateCatagoryModal__wrapper {
  .button__wrapper {
    text-align: right;

    @include bp(mobile) {
      .bg-blue-btn {
        width: 100%;
        max-width: 100%;
      }
    }
  }
}

.modal.right.fade.show .modal-dialog {
  right: 0px;
}

.modal.right.fade .modal-dialog {
  right: -704px;
  -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
  -o-transition: opacity 0.3s linear, right 0.3s ease-out;
  transition: opacity 0.3s linear, right 0.3s ease-out;
}

.modal.right .modal-dialog {
  position: fixed;
  margin: auto;
  width: 704px;
  height: 100%;
  // box-shadow: -1px 1px 23px 0 rgba(0, 0, 0, 0.7);
  -webkit-transform: translate3d(0%, 0, 0);
  -ms-transform: translate3d(0%, 0, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
}
