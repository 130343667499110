@import "~bootstrap/scss/bootstrap";
@import "../../../../../assets/scss/base/base-include.scss";

.createMenuTbs__wrapper {
  margin-top: 30px;
  padding: 0px 48px;

  @include bp(mobile) {
    padding: 0px;
  }

  .nav.nav-tabs {
    margin-bottom: 32px;
    border-bottom: 1px solid #c0c0c830;
    align-items: center;
    flex-wrap: nowrap;
    white-space: nowrap;
    overflow: hidden;
    overflow-x: scroll;

    @include bp(mobile) {
      flex-wrap: nowrap;

      .nav-item .nav-link{
        width: max-content;

        &:not(:last-child){
          margin-right: 65px;
        }
      }
    }

    .addMenubuttonwrapper{
      margin-left:auto;
      @include bp(991){
        display: none;
      }
    }
  }
}
