@import "~bootstrap/scss/bootstrap";
@import "../../assets/scss/base/base-include.scss";

.myaccount__wrapper{
    .restaurantDetails__wrapper {
        .details__wrapper__inner{
            flex-direction: row;
        }
    }

    .modifierDetails__wrapper{
        padding-top: 48px;
    }
}


.changePassword___wrapper{
    form .input-icon{
        right: 16px;
        top: 47px;

        @include bp(mobile){
            right: 16px;
        }
    }

    .modifierDetails__wrapper {
        height: 85vh;

        @include bp(mobile){
            height: auto;
          }
    }
}
