@import "~bootstrap/scss/bootstrap";
@import "../../../assets/scss/base/base-include.scss";

.managermodifier__wrapper {
  background-color: #f8f9fa;
  padding-bottom: 76px;

  .managermodifier__header {
    padding: 48px 0px 48px 0px;

    @include bp(xl) {
      padding-top: 40px;
      padding-bottom: 40px;
    }

    @include bp(mobile) {
      padding-top: 80px;
      padding-bottom: 40px;
    }

    h1 {
      @extend %font32-bold;
      line-height: 1.25;
      color: #131414;
      margin-bottom: 0;

      @include bp(mobile) {
        font-size: 21px;
        margin-bottom: 32px;
      }

      @include bp(360) {
        margin-bottom: 16px;
      }
    }

    .form {
      display: flex;
      align-items: center;

      @include bp(ipadpro) {
        flex-direction: column;
      }

      .search__modifiers {
        flex: 1;
        @include bp(ipadpro) {
          width: 100%;
          margin-bottom: 16px;
        }

        .form-control {
          height: 48px;
          @include bp(mobile) {
            height: 40px;
          }
        }

        .search_modifier_group {
          @include bp(ipadpro) {
            margin-right: auto;
          }
        }
      }
    }

    .red-btn.btn {
      @include bp(ipadpro) {
        width: 100%;
        max-width: 100%;
        height: 40px;
      }
    }
  }

  .red-btn {
    min-width: 128px;
    border: 1px solid #ec380b;
    background-color: #ec380b;
    color: #fff;
    display: flex;
    align-items: center;
    width: auto;
    padding: 10px 24px;

    &:hover {
      text-decoration: none;
      color: #fff;
      box-shadow: 0 2px 10px 0 rgba(218, 0, 0, 0.17);
    }
  }

  .managermodifier__noListing {
    border-radius: 4px;
    box-shadow: 0 5px 9px -3px rgba(0, 0, 0, 0.08);
    background-color: #fff;
    height: 60vh;

    .managermodifier__noListing__innerwrapper {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .content {
        @include bp(mobile) {
          max-width: 288px;
          margin: auto;
        }
        .img-box {
          margin-bottom: 24px;
          text-align: center;
        }

        .content-desc {
          text-align: center;
          h3 {
            @extend %font20-bold;
            color: $theme-red;
            margin-bottom: 28px;
          }

          p {
            @extend %font15-medium;
            color: #949494;
            line-height: 1;
          }
        }
      }
    }
  }

  .restaurantdetails__noListing {
    height: 60vh;

    .managermodifier__noListing__innerwrapper {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .content {
        @include bp(mobile) {
          max-width: 288px;
          margin: auto;
        }
        .img-box {
          margin-bottom: 24px;
          text-align: center;
        }

        .content-desc {
          text-align: center;
          h3 {
            @extend %font20-bold;
            color: $theme-red;
            margin-bottom: 28px;
          }

          p {
            @extend %font15-medium;
            color: #949494;
            line-height: 1;
          }
        }
      }
    }
  }

  .managermodifier__listinPage__wrapper {
    .modifierListing__table {
      border-collapse: separate;
      border-spacing: 0 12px;
      // margin-bottom: 60px;

      @include bp(mobile) {
        margin-bottom: 8px;
      }

      thead {
        th {
          @extend %font12-bold;
          color: #6d7f8b;
          line-height: 1.67;
          border: 0px !important;
          padding: 0px 26px;
          text-transform: uppercase;
        }
      }

      tbody {
        tr {
          border-radius: 4px;
          box-shadow: 0 5px 9px -3px rgba(0, 0, 0, 0.08);
          background-color: #fff;

          td {
            border: 0px !important;
            @extend %font15-semibold;
            color: #131414;
            line-height: 1.53;
            padding: 0px 26px;

            .modifierDeatil__link {
              @extend %font15-semibold;
              color: #131414;
              line-height: 1.53;
              padding: 0px;

              &:hover {
                text-decoration: none;
                color: inherit;
              }
            }

            .table__actions {
              display: flex;
              align-items: center;

              > div:not(:last-child) {
                margin-right: 22px;
              }

              .onOff-toggle-action {
                .switch {
                  margin-bottom: 0px;
                }
              }
            }
          }
        }
      }
    }
  }
}

.mat-header-row {
  display: flex;
  height: auto;
  margin-bottom: 10px;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

th.mat-header-cell:first-of-type {
  padding-left: 34px;
}

td.column-1,
th.column-1 {
  width: 55%;
  text-transform: capitalize;
}

td.column_1,
th.column_1 {
  width: 25%;
  text-transform: capitalize;
}

td.column_2,
th.column_2 {
  width: 30%;
  text-transform: capitalize;
}

td.column-2,
th.column-2 {
  width: 15%;
  text-transform: capitalize;
}

td.column-3,
th.column-3 {
  width: 15%;
  text-transform: capitalize;
}

td.column-4,
th.column-4 {
  width: 15%;
  text-transform: capitalize;
}

td.column-5,
th.column-5 {
  width: 15%;
  text-transform: capitalize;
}

th.mat-header-cell,
td.mat-cell {
  border-bottom: none;
  display: flex;
}

.mat-row {
  padding: 7px 36px;
  flex-wrap: wrap;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
  padding-left: 0;
  padding-right: 0;
}

.table__actions {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.modifierListing__table {
  @include bp(mobile) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    background: transparent;
    border-radius: 0;
    padding-bottom: 0;

    tr.mat-header-row {
      display: none;
    }

    .column-1,
    .column_1,
    .column-2,
    .column_2,
    .column-3,
    .column-4,
    .column-5 {
      width: 100%;
    }

    .column-5 {
      justify-content: space-between;
      align-items: center;
      padding-bottom: 0;
    }

    tbody {
      display: flex;
      width: 100%;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    .mat-row {
      height: auto;
      border-radius: 4px;
      float: left;
      width: 49.5%;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
      background-color: #ffffff;
      margin-bottom: 32px;
      padding: 17px 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
    }

    .mat-cell,
    .mat-cell:first-of-type {
      position: relative;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
      padding: 0px 0px 16px 0px !important;
    }

    .mat-cell{
      @include bp(991){
        &:last-child{
          padding-bottom: 0px !important
        }
      }
    }

    .mat-cell::before {
      content: attr(data-column);
      left: 15px;
      padding-right: 10px;
      position: static;
      width: 100%;
      font-size: 12px;
      font-weight: 600;
      color: #6d7f8b;
      line-height: 1.67;
      top: 10px;
    }

    .managermodifier__wrapper
      .managermodifier__listinPage__wrapper
      .modifierListing__table
      tbody
      tr
      td
      .modifierDeatil__link,
    .managermodifier__wrapper
      .managermodifier__listinPage__wrapper
      .modifierListing__table
      tbody
      tr
      td {
        font-family: 'Work Sans', sans-serif;
          font-weight: 400 !important;
      font-size: 15px !important;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.53;
      letter-spacing: normal;
      color: #131414;
    }
  }

  @include bp(mobile) {
    .mat-row {
      width: 100%;
    }
  }
}


.table__actions {
  .right_content{
    > span{
      @extend %font15-medium;
    }
  }
}


@include bp(ipadpro){
  #manage__modifier__search__form{

    .search__modifiers{
      margin-bottom: 0px;
      height: 40px;
      .form-control{
        height: 40px;
      }
    }

    .red-btn.btn{
      max-width: 220px;
      margin-left: 16px;
    }
  }
}

@include bp(mobile){
  #manage__modifier__search__form{
  .red-btn.btn{
    max-width: 100%;
    width: 100%;
    margin-left: 0px;
  }
}
}

@media screen and (min-width:992px) and (max-width:1199px){
  #manage__modifier__search__form .search__modifiers .form-control::placeholder{
    font-size: 14px;
  }
}

.modifiers_heading_padding {
  padding: 0px 0px 20px 0px !important;

  @include bp(xl) {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  @include bp(mobile) {
    padding-top: 80px;
    padding-bottom: 40px;
  }

    h2 {
      @extend %font22-semibold;
      color: #131414;
  
      @include bp(mobile) {
        font-size: 15px;
      }
    }

}