@import "~bootstrap/scss/bootstrap";
@import "../../assets/scss/base/base-include.scss";

.modifierDetails__wrapper {
  background-color: #f8f9fa;
  padding-top: 48px;
  padding-bottom: 65px;
  height: auto;

  @include bp(mobile) {
    padding-top: 40px;
    padding-bottom: 64px;
    height: auto;
  }

  .goBack {
    margin-bottom: 32px;
    @include bp(mobile) {
      margin-bottom: 24px;
      display: none;
    }
  }

  .main_heading {
    padding-bottom: 24px;

    h1 {
      @extend %font32-bold;
      line-height: 1.25;
      color: #131414;
      margin-bottom: 0;

      @include bp(mobile) {
        font-size: 21px;
        margin-bottom: 8px;
      }
    }
  }

  .details__wrapper {
    border-radius: 4px;
    box-shadow: 0 5px 9px -3px rgba(0, 0, 0, 0.08);
    background-color: #fff;
    padding: 29px 26px 47px 32px;

    @include bp(ipadpro){
      padding: 29px 26px 30px 32px;
    }

    @include bp(mobile) {
      padding: 20px 15px;
    }

    .details__wrapper__inner {
      display: flex;
      justify-content: space-between;

      .detailCard {
        h5 {
          @extend %font12-bold;
          line-height: 1.67;
          color: #6d7f8b;
          text-transform: uppercase;
          @include bp(mobile) {
            margin-bottom: 0px;
          }
        }

        p {
          @extend %font15-regular;
          line-height: 1.53;
          color: #131414;
          margin-bottom: 0;
        }

        &:not(:last-child) {
          padding-bottom: 24px;

          @include bp(mobile) {
            padding-bottom: 0px;
          }
        }
      }

      .card-two-col {
        display: flex;
        width: 288px;
        justify-content: space-between;

        @include bp(mobile) {
          width: 210px;
        }
      }
    }
  }
}

.restaurantDetails__wrapper {
  .details__wrapper__inner {
    @include bp(991) {
      flex-direction: column;

      .right_content {
        display: flex;
        > span {
          // flex: 1;
          text-align: center;
          margin-right: 8px;
        }
      }
    }
  }
}

.shared__vb__listing__wrapper {
  padding: 40px 0px 0px 0px;

  @include bp(mobile) {
    padding-top: 64px;
  }

  h2 {
    font-family: "Work Sans",sans-serif;
    font-weight: 600;
    font-size: 24px;
    font-stretch: normal;
    font-style: normal;
    line-height: .95;
    letter-spacing: normal;
    color: #131414;
    @include bp(mobile) {
      font-size: 15px;
    }
  }

  .shared__vb__table {
    margin-top: 24px;
    .mat-header-row {
      margin-bottom: 0;
    }
  }
}

.address-map {
  height: 234px;
  background: #eee;

  @include bp(mobile) {
    margin-bottom: 16px;
  }
}

.restaurantDetails__wrapper {
  .shared__vb__listing__wrapper {
    padding-top: 64px;

    .managermodifier__wrapper {
      padding-bottom: 0;
    }
  }
}

// .restaurantDetails__wrapper
//   .managermodifier__wrapper
//   .managermodifier__listinPage__wrapper
//   .modifierListing__table
//   tbody
//   tr:last-child {
//   margin-bottom: 0;
// }
