$hover-color:#0083bb;
$btn-color:#18bdf6;
$nav-font-color:#161626;
$footerlink-color:#161626;
$left-nav-color: #6d7f8b;
$active-red-text:#ec380b;
$theme-red: #ec380b;
$theme-gray:#6d7f8b;
$theme-blue: #008bda;
$h1-color: #131414;
$heading-color:#131414;
$error-color: #da0000;
$theme-yellow: #DFAE1E;
