@import "~bootstrap/scss/bootstrap";
@import "../../../../../../assets/scss/base/base-include.scss";

.addNewItemModal__wrapper {
  .button__wrapper {
    text-align: right;

    @include bp(mobile) {
      width: 100%;
    }
  }

  .browsefile {
    margin-top: 12px;
  }

  .actions-wrapper {
    width: 100%;
    max-width: 100%;

    > select.form-control.time-select {
      max-width: 100%;
      margin-right: 0;
    }

    > select.form-control.time-select:not(:last-child) {
      margin-right: 24px;
    }
  }

  .preptime {
    > .actions-wrapper {
      flex-direction: row !important;
    }
  }
}

.modifiers__wrapper {
  padding: 17px 16px 16px;
  border-radius: 4px;
  border: solid 1px #c0c0c8;
  background-color: #fff;
  margin-bottom: 16px;

  .modifiersCard {
    .modifiersCard__header {
      margin-bottom: 16px;

      @include bp(mobile) {
        margin-bottom: 8px;
      }
    }

    .heading__wrapper {
      display: flex;
      justify-content: space-between;

      button {
        background: unset;
        border: none;
        outline: none;
      }
    }

    .noSelected {
      p {
        @extend %font15-medium;
        line-height: 1.53;
        color: #131414;
      }
    }
  }
}

.addModifiers {
  @extend %font15-medium;
  display: flex;
  color: $theme-red;
}

.modifierGroupFrom__header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include bp(mobile) {
    flex-direction: column;
  }

  .bg-blue-btn {
    max-width: 165px;
    padding: 9px 16px 8px;
    font-size: 15px;
    height: 40px;

    @include bp(mobile) {
      max-width: 100%;
    }
  }
}

.search_modifier_group {
  position: relative;
  flex: 1;
  margin-right: 24px;

  @include bp(mobile) {
    width: 100%;
    margin-right: unset;
    margin-bottom: 16px;
  }

  .form-control {
    height: 40px;
  }
}

.search-btn {
  background: none;
  border: none;
  outline: none;
  position: absolute;
  right: 15px;
  top: 4px;
}

.accordion {
  .card-header {
    height: 48px;
    background-color: #e0e0e0;

    button {
      padding: 0;
      @extend %font15-semibold;
      line-height: 1;
      color: #161626;
      position: relative;
    }

    // button[aria-expanded="true"]::after {
    //   content: "";
    //   position: absolute;
    //   background-image: url("../../../../../../assets/images/accordion_minus-icon.svg");
    //   background-repeat: no-repeat;
    //   background-position: center;
    //   width: 10px;
    //   height: 3px;
    //   top: 0;
    //   right: 0;
    //   bottom: 0;
    //   margin: auto;
    // }

    // button[aria-expanded="false"]::after {
    //   content: "";
    //   position: absolute;
    //   background-image: url("../../../../../../assets/images/open-plus.svg");
    //   background-repeat: no-repeat;
    //   background-position: center;
    //   width: 10px;
    //   height: 3px;
    //   top: 0;
    //   right: 0;
    //   bottom: 0;
    //   margin: auto;
    // }

    button{
      &::after {
          content: "";
          position: absolute;
          background-image: url("../../../../../../assets/images/open-plus.svg");
          background-repeat: no-repeat;
          background-position: center;
          width: 10px;
          height: 10px;
          top: 0;
          right: 0;
          bottom: 0;
          margin: auto;
        }
     }

    button.collapsed{
      &::after{
        content: "";
          position: absolute;
          background-image: url("../../../../../../assets/images/accordion_minus-icon.svg");
          background-repeat: no-repeat;
          background-position: center;
          width: 10px;
          height: 3px;
          top: 0;
          right: 0;
          bottom: 0;
          margin: auto;
      }
    }
  }
}

.modifierGroupTable {
  margin-top: 8px;

  .custom-checkbox-container {
    @extend %font12-bold;
    line-height: 1.67;
    color: #6d7f8b;
    padding-left: 32px;
  }

  thead {
    th {
      @extend %font12-bold;
      line-height: 1.67;
      color: #6d7f8b;
      border-bottom: 0px !important;
      text-transform: uppercase;
      border: none;

      &:last-child {
        text-align: right;
      }
    }
  }

  tbody {
    td {
      .custom-checkbox-container {
        @extend %font12-SemiBold;
        line-height: 1.92;
        color: #131414;
        padding-left: 32px;
      }

      .modifiers__type {
        @extend %font12-SemiBold;
        line-height: 1.92;
        color: #131414;
        text-align: right;
        width: 100%;
        display: inline-block;
      }
    }
  }
}

.selectedModifiers__wrapper {
  border-top: 1px solid #c0c0c8;
  border-bottom: 1px solid #f2efef;
  padding: 24px 0px;

  h3 {
    @extend %font14-semibold;
    line-height: 1;
    color: #131414;
  }
}

.selectedModifiers {
  padding-top: 8px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  > div {
    padding: 7px 7px 7px 10px;
    border-radius: 18px;
    background-color: #edeff6;
    @extend %font12-SemiBold;
    line-height: 1.5;
    color: #666c7f;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    &:not(:last-child) {
      margin-right: 10px;
    }

    span {
      .icon-btn {
        background: none;
        border: none;
        outline: none;
        margin-left: 8px;

        @include bp(mobile) {
          img {
            width: 18px;
          }
        }
      }
      &:last-child {
        width: 35px;
      }
    }

    @include bp(mobile) {
      padding: 6px 5px 5px 8px;
      font-size: 10px;
    }
  }
}

.modifiersCard__body {
  button.addModifiers {
    @include bp(mobile) {
      font-size: 0px;
      .icons {
        margin-right: 6px;
      }
    }
  }
}

.continue-btn-wrapper {
  margin-top: 36px;
  margin-bottom: 20px;
  .red-btn {
    outline: none;
    border: none;
    margin-left: auto;
  }
}

.addedModifiers__list__wrap {
  ul {
    li.modifiersadded {
      padding: 16px 0px;
      display: flex;
      width: 100%;
      align-items: center;
      border-bottom: solid 1px #f2efef;

      // &:not(:last-child) {
      //   border-bottom: solid 1px #f2efef;
      // }

      p {
        @extend %font15-medium;
        line-height: 1.53;
        letter-spacing: normal;
        color: #131414;
        margin-bottom: 0px;
        max-width: 200px;
        width: 100%;
      }

      .delete_modifier {
        border: none;
        background: none;
        outline: none;
        margin-left: auto;
        img {
          margin: unset;
        }
      }
    }
  }
}

#addModifiers {
  h5 {
    text-transform: uppercase;
  }

  select.form-control {
    margin: 0;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: #fff url(../../../../../../assets/images/dropdown-arrow.svg)
      no-repeat 95%;
  }
}
