@import "~bootstrap/scss/bootstrap";
@import "../../assets/scss/base/base-include.scss";

.prelogin__wrapper {
  background-color: #f8f9fa;
  padding: 74px 0px;
  display: flex;
  align-items: center;

  @include bp(mobile){
    background-color: #fff;
  }

  .inner__wrapepr {
    max-width: 645px;
    margin-left: auto;
    margin-right: auto;
    padding: 76px 119px 92px 118px;
    border-radius: 8px;
    box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.04);
    background-color: #fff;

    @include bp(mobile) {
      padding: 48px 32px;
      box-shadow: none;
      max-width: 400px;
    }

    .prelogin__logo {
      text-align: center;
      margin-bottom: 56px;
    }

    .prelogin__heading {
      margin-bottom: 24px;
      text-align: center;

      h1 {
        @extend %font25-bold;
        color: #131414;
        margin-bottom: 0;
      }
    }

    .prelogin__subtext {
      padding-top: 8px;
      p {
        @extend %font15-regular;
        color: #6d7f8b;
        line-height: 1.53;
      }
    }

    .preloginForm__wrapper {
      label {
        @extend %font14-semibold;
        color: #131414;
        margin-bottom: 12px;
      }

      form {
        .form-control {
          box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.04);
          border: solid 1px #c0c0c9;
          background-color: #fff;
          border-radius: 4px;
          height: 48px;
          max-width: 405px;
          font-size: 15px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.53;
          letter-spacing: normal;
          color: #131414;

          &:focus {
            border: solid 1px #414a6b;
          }

          &::placeholder,
          &::-moz-placeholder,
          &::-webkit-input-placeholder {
            color: #c0c0c9;
          }
        }

        textarea {
          min-height: 174px;
        }

        .input-icon {
          position: absolute;
          top: 40px;
          bottom: 0;
          right: 15px;
          cursor: pointer;
        }

        .prelogin-links {
          @extend %font12-medium;
          text-align: right;
          color: $theme-gray;
          width: auto;
          display: inline-block;

          &:hover {
            color: $theme-gray;
            text-decoration: none;
            color: $theme-red;
          }
        }

        .action__wrapper {
          button,
          .prelogin-links-btn {
            @extend %font15-semibold;
            width: 100%;
            background: $theme-red;
            color: #fff;
            border: 1px solid $theme-red;
            border-radius: 4px;
            height: 48px;
            display: inline-flex;
            justify-content: center;
            align-items: center;

            &:hover {
              text-decoration: none;
            }

            &.prelogin-links-outline-btn {
              background-color: unset;
              color: $theme-red;
            }
          }
        }

        .rememberPass {
          padding-top: 24px;
          display: flex;
          justify-content: center;
          align-items: center;

          .prelogin-links {
            width: unset;
            display: unset;
            margin: unset;
            color: #414a6b;

            &.no-link {
              color: #c0c0c8 !important;
              margin-right: 5px;
            }
          }
        }
      }
    }
  }
}

.otp__field__wrapper {
  .otpInput__wrap {
    justify-content: space-between;
  }

  .form-control,
  .otpInput__box {
    @extend %font21-semibold;
    box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.04);
    border: solid 1px #c0c0c9;
    background-color: #fff;
    border-radius: 4px;
    height: 56px;
    width: 100% !important;
    max-width: 56px;
    color: #131414;

    &:focus {
      border: solid 1px #414a6b !important;
    }

    &::placeholder,
    &::-moz-placeholder,
    &::-webkit-input-placeholder {
      color: #c0c0c9;
    }
  }
}

.otp__form {
  .prelogin-links {
    text-align: center !important;
  }
}


.enable-link{
  color: $theme-red !important;
  text-decoration: underline;
  font-weight: 500;
}