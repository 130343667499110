@import "~bootstrap/scss/bootstrap";
@import "../../assets/scss/base/base-include.scss";

.order__details__wrapper {
  .detailsCard__wrapper {
    border-radius: 4px;
    //box-shadow: 0 5px 9px -3px rgba(0, 0, 0, 0.08);
    background-color: #fff;

    .order-details-body-header {
      padding: 20px 30px 20px 30px;
      background-color: #e5870b;
      //border-top-left-radius: 4px;

      @include bp(mobile){
          padding: 15px;
          border-top-right-radius: 4px;
      }

      > span {
        display: block;

        &:nth-of-type(1) {
          @extend %font12-medium;
          line-height: 1;
          color: #fff;
          text-transform: uppercase;
          margin-bottom: 5px;
        }

        &:nth-of-type(2) {
          @extend %font15-bold;
          line-height: 1.53;
          color: #fff;
        }
      }
    }

    .order-details-body-details {
      padding: 32px 30px;

      @include bp(mobile){
        padding: 20px 15px;
        border-bottom: 1px solid #dfe5ec;
        border-right: 0 !important;
    }

      .body-heading {
        margin-bottom: 32px;
        @include bp(mobile){
            margin-bottom: 20px
        }
        h2 {
          @extend %font21-semibold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.38;
          letter-spacing: normal;
          color: #131414;
          margin-bottom: 0;
        }
      }

      .order-detail-card {
        padding-bottom: 24px;

        @include bp(mobile){
            padding-bottom: 0px;
        }

        label {
          @extend %font12-bold;
          line-height: 1.67;
          letter-spacing: normal;
          color: #6d7f8b;
          text-transform: uppercase;

          @include bp(mobile){
            margin-bottom: 4px;
            }
        }

        p {
          @extend %font15-regular;
          line-height: 1.53;
          letter-spacing: normal;
          color: #131414;
        }
      }
    }

    .logo__area {
      text-align: center;
      padding-top: 32px;
      padding-bottom: 16px;

      .img-box {
        margin-bottom: 12px;
        img {
          width: 64px;
        }
      }

      .content-box {
        h3 {
          @extend %font18-bold;
          line-height: 1.44;
          letter-spacing: normal;
          color: #131414;
          margin-bottom: 0;
        }

        .order-no {
          @extend %font12-medium;
          line-height: 1;
          letter-spacing: normal;
          color: #6d7f8b;
        }
      }
    }

    .items__price__breakups {
      padding: 0px 32px 0px 30px;

      @include bp(mobile){
        padding-left: 15px;
        padding-right: 15px;
        padding-bottom: 30px;
      }

      .main__heading {
        margin-bottom: 20px;
        label {
          margin-bottom: 0;
        }
      }

      label {
        @extend %font12-bold;
        line-height: 1.67;
        letter-spacing: normal;
        color: #6d7f8b;
        text-transform: uppercase;
      }

      .item__block {
        &:not(:last-child){
            border-bottom: 2px solid #dfe5ec;
        }

        .item {
          padding: 12px 0px;
          display: flex;
          justify-content: space-between;

          > span {
            @extend %font15-medium;
            line-height: 1.53;
            letter-spacing: normal;
            color: #1b2236;

            &:nth-of-type(2) {
              @extend %font15-bold;
              color: #131414;
            }
          }
        }

        .item__modifiers__wrapper{
            .item-modifiers{
                display: flex;
                justify-content: space-between;
                margin-bottom: 8px;

                > span {
                    @extend %font12-regular;
                    line-height: 1.53;
                    letter-spacing: normal;
                    color: #161626;
        
                    &:nth-of-type(2) {
                      @extend %font12-medium;
                      color: #131414;
                    }
                  }
            }
        }

        .item__notes{
            padding-top: 8px;

            label{
                margin-bottom: 2px;
            }

            p{
                @extend %font15-regular;
                line-height: 1.53;
                letter-spacing: normal;
                color: #131414
            }
        }
      }
    }
  }
}
