@import "~bootstrap/scss/bootstrap";
@import "../../assets/scss/base/base-include.scss";

.preloader {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  // background-color: rgba(0, 0, 0, 0.5);
  opacity: 0.83;
  background-color: #414a6b;
  z-index: 100;
}

.preloader {
  z-index: 9999 !important;
}

.preloader .loader {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.lds-roller {
  display: inline-block;
  position: relative;
  width: 112px;
  height: 112px;
  text-align: center;
}

@media screen and (max-width:1440px){
  .lds-roller {
    display: inline-block;
    position: relative;
    width: 90px;
    height: 90px;
    text-align: center;
  }
}

@media screen and (max-width:1200px){
  .lds-roller {
    display: inline-block;
    position: relative;
    width: 50px;
    height: 50px;
    text-align: center;
  }
}

.lds-roller div {
  animation: lds-roller 1.5s infinite;
  margin-bottom: 32px;
}

@keyframes lds-roller {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
}

.loader {
  color: #fff;
  p {
    font-family: 'Work Sans', sans-serif;
          font-weight: 500;
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.15;
    letter-spacing: normal;
    margin-bottom: 0px;
  }
}

.loader__full {
  .lds-roller div {
    content: url("../../assets/images/loader.svg");
    object-fit: cover;
    width: 60px;
    margin-left: auto;
    margin-right: auto;
  }
}

.loader__block {
  .preloader-wrapper {
    display: flex;
    justify-content: center;
  }

  .lds-roller {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .loader {
    color: #ec380b;
    p {
      font-family: 'Work Sans', sans-serif;
      font-size: 16px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.15;
      letter-spacing: normal;
      margin-bottom: 0px;
    }
  }
  .lds-roller div {
    content: url("../../assets/images/loader-block.svg");
    margin-bottom: 16px;
  }
}

.loader__inline {
  .loader {
    display: flex;
    justify-content: center;
  }
  .lds-roller {
    height: auto;
    width: auto;
  }
  .lds-roller div {
    content: url("../../assets/images/loader-inline.svg");
    margin-bottom: 0px;
  }
}
