@import "~bootstrap/scss/bootstrap";
@import "../../../assets/scss/base/base-include.scss";

.header {
  // transition: 0.3s;
  position: -webkit-sticky;
  position: sticky;
  top: 0;

  @extend %nav-font;
  .header-container {
    padding: 0px 25px;
    @include bp(ipad) {
      padding: 0;
    }
  }
  .header-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 17px 0px 20px 0px;

    .header-left,
    .header-right .profile {
      display: flex;
      align-items: center;
    }

    .header-left {
      .logo {
        margin-left: 20px;
        > a {
          display: inline-block;

          > img {
            width: 89px;
            @include bp(mobile) {
              width: 72px;
            }
          }
        }

        @include bp(ipad) {
          margin-left: 12px;
        }

        @include bp(mobile) {
          margin-left: 12px;
        }
      }

      .logo.auth {
        margin-left: 20px;
        > a {
          display: inline-block;

          > img {
            width: 89px;
            @include bp(mobile) {
              width: 63px;
            }
          }
        }

        @include bp(ipad) {
          margin-left: 12px;
        }

        @include bp(mobile) {
          margin-left: 12px;
        }
      }
    }

    .header-right {
      .profile {
        .profile-initials {
          @extend %nav-font;
          margin-right: 12px;

          @include bp(ipad) {
            margin-right: 10px;
          }

          span {
            height: 30px;
            width: 30px;
            border-radius: 50%;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            vertical-align: middle;
            padding: 10px 11px;
            border: solid 1px #e4e9ed;
            background-color: #f6f9fb;
          }
        }

        .profile-dropdown {
          font-size: 15px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #6d7f8b;
          background: transparent;
          outline: none;
          border: none;

          &::after {
            display: none;
          }

          @include bp(ipad) {
            font-size: 14px;
            padding-right: 16px;
          }

          @include bp(mobile) {
            font-size: 12px;
          }

          &:focus {
            box-shadow: none;
            outline: none;
          }
        }
      }
      .dropdown-menu {
        border: 0;
        top: 13px;
        border-radius: 4px;
        box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.4);
        background-color: #fff;
      }
    }
  }
}

.header.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background: #fff;
  // transition: 0.3s;
  z-index: 999;
  // box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.4);
}

.non-auth-subheader {
  top: 60px;
}

.header-left .navbar{
  padding-left: 0;
  @include bp(991){
    padding-left: 15px;
  }
}