@import "~bootstrap/scss/bootstrap";
@import "../../../../../assets/scss/base/base-include.scss";

.menuCategoriesCard__wrapper {
  padding: 24px 0;
  border-radius: 4px;
  border: solid 1px #c0c0c8;
  background-color: #fff;
  margin: 32px 0px;
  margin-bottom: 0;

  @include bp(mobile) {
    margin-top: 16px;
    padding-bottom: 0;
  }

  .menuCategoriesCard__header {
    padding: 0px 32px;
    padding-bottom: 25px;
    border-bottom: 1px solid #c0c0c8;

    @include bp(mobile) {
      padding: 0px 15px 24px 15px;
    }

    .main__heading {
      display: flex;
      align-items: flex-end;

      @include bp(mobile) {
        justify-content: space-between;
      }

      h2 {
        @extend %font21-bold;
        margin-bottom: 0;
      }

      .edit-btn {
        background: none;
        border: none;
        outline: none;
        margin-left: 12px;

        img{
          vertical-align: middle;
        }
      }
    }

    p {
      @extend %font12-bold;
      line-height: 2.17;
      color: #6d7f8b;
      margin-bottom: 0;
    }
  }

  .menuCategoriesCard__body {
    .menuItem__card {
      padding: 24px 0px;
      border-bottom: solid 1px #f1f1f1;
      margin: 0px 32px;
      position: relative;

      @include bp(mobile) {
        margin: 0px 15px;
        padding:32px 0px;

        &:last-child{
          border-bottom: 0px;
        }
      }

      .threedotsbtn {
        position: absolute;
        right: 0;

        @include bp(mobile){
          top: 32px;
        }
      }

      .threedots_btn {
      @include bp(mobile) {
          position: absolute;
          bottom: 130px;
          right: 15px;
        }
      }         

      .menuItem_price {
        font-size: 15px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.73;
        letter-spacing: -0.6px;
        color: #1b2236;
        margin-bottom: 0px;

        p{
          font-size: 15px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.73;
          letter-spacing: -0.6px;
          color: #1b2236;
          margin-bottom: 0px;
        }
      }
    }

    .menuItem__card__details__wrapper {
      display: flex;

      .img-box {
        width: 70px;
        margin-right: 16px;

        > img{
          width: 70px;
          height: 70px;
          border-radius: 4px;
          object-fit: cover;
          max-width: 70px;
        }
      }

      .menuItem_desc {
        max-width: 265px;
        h5 {
          @extend %font15-bold;
          line-height: 1.53;
          color: #1b2236;
          margin-bottom: 5px;
          display: flex;
          align-items: center;

          > .edit-btn{
            padding-bottom: 3px;
            img{
              width: 15px;
              height: 15px;
            }
          }
        }

        p {
          @extend %font15-regular;
          color: #6d7f8b;
          margin-bottom: 0;
        }
      }
    }
  }

  .menuCategoriesCard__footer {
    padding-top: 20px;
    padding-right: 32px;

    @include bp(mobile){
      padding-right: 0;
      padding-top: 0;
      .bg-blue-btn{
        width: 100%;
        max-width: 100%;
      }
    }
  }
}


.basePrice {
  margin-top: 16px;
  p{
    @extend %font12-bold;
    line-height: 1.67;
    color: #6d7f8b;
    margin-bottom: 0;
  }
}


.leftDrowpdown {
  margin-left: 32px;

  @include bp(mobile){
    margin-left: 9px;
  }

  #dropdown-button-drop-left {
    background: #fff;
    border: none;
    padding: 0;

    &::before {
      background: #fff
        url("../../../../../assets/images/icon-three-dots.svg") no-repeat;
      border: none;
      height: 18px;
      width: 5px;
    }

    &:focus {
      box-shadow: none;
      outline: none;
    }
  }

  .dropdown-menu {
    padding: 11px 12px;
    border-radius: 4px;
    box-shadow: 0 2px 13px 0 rgba(63, 74, 110, 0.1);
    background-color: #fff;
    border: none;

    .dropdown-item {
      border-radius: 4px;
      background-color:#fff;
      color: #3f4a6e;
      @extend %font15-medium;
      line-height: 1.53;

      &:hover,
      &:focus {
        background-color: rgba(236, 56, 11, 0.07);
        color: #ec380b;
      }
    }
  }
}