@import "~bootstrap/scss/bootstrap";
@import "../../assets/scss/base/base-include.scss";

.edit__connection__wrapper {
  .modifierDetails__wrapper {
    background-color: #f8f9fa;
    padding-top: 48px;
    padding-bottom: 65px;
    height: auto;

    @include bp(mobile) {
      padding-top: 40px;
      padding-bottom: 64px;
      height: auto;
    }

    .goBack {
      margin-bottom: 32px;
      @include bp(mobile) {
        margin-bottom: 24px;
        display: none;
      }
    }

    .main_heading {
      padding-bottom: 24px;

      h1 {
        @extend %font32-bold;
        line-height: 1.25;
        color: #131414;
        margin-bottom: 0;

        @include bp(mobile) {
          font-size: 21px;
          margin-bottom: 8px;
        }
      }
    }

    .details__wrapper {
        box-shadow: none;

        .details__wrapper__inner{
            @include bp(mobile){
                flex-direction: column;

                .right_content {
                    > span{
                        flex: 1;
                        text-align: center;
                    }
                }
            }
        }

        .left_content{
            max-width: 471px;

            @include bp(991){
                max-width: 400px;
            }

            @include bp(mobile){
                max-width: 100%;
            }

            select.form-control{
                background-position: 96%;
            }
        }

        .right_content{
            @include bp(mobile){
                margin-top: 32px;
            }
        }
    }


  }

  .modal-footer{
      @include bp(mobile){
          margin-top: 40px;
      }
  }
}
