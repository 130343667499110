@import "~bootstrap/scss/bootstrap";
@import "../../../assets//scss/base/base-include.scss";

.prepStepSlideShow__wrapper {
    margin-top: 20px;

    .tab__mainHeading {
        margin-top: 40px;
        margin-bottom: 32px;

        @include bp(mid-device) {
        margin-top: 0px !important;
        margin-bottom: 10px !important;
        }

        @include bp(mobile) {
            margin-top: 40px;
            margin-bottom: 32px;
        }

        h1 {
            @extend %font32-bold;
            line-height: 1.25;
            color: $h1-color;
            margin-bottom: 0;

            @include bp(mobile) {
                font-size: 21px;
            }
        }

        .img-video-wrapper {
            height: 430px;
            width: 620px;
            margin-left: auto;
            margin-right: auto;
            
            @include bp(mid-device) {
                height: 260px !important;
                width: 400px !important;
            }
          
            @include bp(mobile) {
                max-width: 100%;
                height: auto;
                overflow: hidden;
            }

            .imgStyle {
                max-width: 100%;
                height: auto;
            
                /* @include bp(lg) {
                    max-width: 100%;
                } */
            }
        }

        .prepstep-step {
            font-family: Work Sans;
            font-size: 26px;
            font-weight: 700;
            line-height: 40px;
            letter-spacing: 0px;
            text-align: center;

            @include bp(mid-device) {
                font-size: 18px !important; 
            }
        }

        .prepstep-desc {
            font-family: Work Sans;
            font-size: 16px;
            font-weight: 400;
            line-height: 28px;
            letter-spacing: 0px;
            text-align: center;
        }


        .prepstep-slideshow-footer {
            position: absolute;
            bottom: 0px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }

        .prepstep-btn-next {
            display: flex;
            align-items: center;
            justify-content: center;
            background: none;
            border: none;

            >span {
                color: #EC380B;
                @extend %font18-bold;
            }

        }

        .prepstep-pages {
            font-family: Work Sans;
            font-size: 20px;
            font-weight: 600;
            line-height: 40px;
            letter-spacing: 0px;
            color: #303131;
            opacity: 0.6;
        }

        .prepstep-btn-prev {
            display: flex;
            align-items: center;
            justify-content: center;
            background: none;
            border: none;

            >span {
                color: #EC380B;
                @extend %font18-bold;
            }

        }

    }

    @media screen and (min-width: 700px) and (max-width: 1350px) {
        .tab__mainHeading {
            margin-top: 0px !important;
            margin-bottom: 10px !important;
        }
        .img-video-wrapper {
                height: 260px !important;
                width: 400px !important;
            }
    }
          
}