@import "~bootstrap/scss/bootstrap";
@import "../../../../assets/scss/base/base-include.scss";

.createmenu__wrapper {
  padding: 16px 16px 48px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.04);

  @include bp(mobile) {
    padding: 16px 15px 0px;
  }

  .inner__wrapper {
    // padding: 0px 48px;

    @include bp(mobile) {
      padding: 0px;
    }
  }
}
