@import "~bootstrap/scss/bootstrap";
@import "../../assets/scss/base/base-include.scss";

.manageUser__landingPage__wrapper{
    .managermodifier__header{
        .manage-user-search{
            max-width: 400px;

            @include bp(ipadpro){
                max-width: 100%;
            }
        }
    
        .savedraft__wrapper .saveAsDraft__btn > .btn{
            height: 46px;
            color: #1b2236;

            @include bp(mobile){
                height: 38px;
                padding: 10px 15px;
            }
        }
    
        .savedraft__wrapper .saveAsDraft__btn__gray{
            border: solid 1px #6d7f8b;
    
            > button{
                background: transparent !important;
            }
        }
    
        form{
            justify-content: flex-end;
            flex-direction: row;

            .manage-user-search{
                margin-bottom: 0;
            }

            .adduser__btn{
                .red-btn.btn{
                    height: 48px;
                    @include bp(mobile){
                        height: 40px;
                    }
                }
            }
    
            @include bp(mobile){
                flex-direction: row;
                flex-wrap: wrap;

                .manage-user-search{
                    width: 48%;
                    margin-bottom: 0;
                    margin-right: 16px;
                }

                .adduser__btn{
                    width: 100%;
                    max-width: 100%;
                }
            }
        }
        
        .userselect{
            .savedraft__wrapper{
                padding-top: 0px;
            }
            @include bp(mobile){
                width: 48%;
                margin-bottom: 16px;
            }
        }
    }
}

.details__wrapper{
    .details__wrapper__inner{
        .left_content{
            max-width: 50%;
            width: 50%;

            @include bp(991){
                max-width: 100%;
                width: 100%;
            }
        }
    }
}