@import "~bootstrap/scss/bootstrap";
@import "../../../assets/scss/base/base-include.scss";

.tracking>.modal-dialog>.modal-content {
    padding: 0px !important;
    padding-top: 55px !important;
}

.tracking__iframe {
    width: 100%;
    height: 100%;
    border: none;
}