@import "~bootstrap/scss/bootstrap";
@import "../../../../assets/scss/base/base-include.scss";

.createMenuBanner__wrapper {
  position: relative;

  .bg {
    background: #ccc;
    min-height: 233px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;

    @include bp(mobile) {
      min-height: 130px;
    }

    .logo {
      position: absolute;
      bottom: -95px;
      left: 32px;

      > .logo-wrapper {
        width: 160px;
        height: 160px;
        border-radius: 50%;
        border: 2px solid #fff;
        background-color: #ccc;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        display: flex;
        justify-content: center;
        align-items: center;

        .loader__block {
          margin: 0 !important;
        }
        .loader__block .lds-roller div {
          margin: 0;
        }

        @include bp(mobile) {
          width: 95px;
          height: 95px;
        }
      }

      // > img {
      //   width: 160px;
      //   height: 160px;
      //   border-radius: 50%;
      //   object-fit: cover;
      //   border: 2px solid #fff;

      //   @include bp(mobile) {
      //     width: 95px;
      //     height: 95px;
      //   }
      // }

      @include bp(mobile) {
        width: 95px;
        left: 0;
        right: 0;
        margin: auto;
        bottom: -50px;
      }
    }
  }

  .brand__details {
    margin-top: 116px;
    display: flex;
    justify-content: space-between;
    padding: 0px 48px;

    @include bp(mobile) {
      padding: 0px;
    }

    @include bp(mobile) {
      flex-direction: column-reverse;
      margin-top: 55px;
    }

    .left__side {
      display: flex;

      @include bp(mobile) {
        flex-direction: column;
        margin-top: 24px;
      }

      h5 {
        @extend %font12-bold;
        line-height: 1.5;
        color: #6d7f8b;
        margin-bottom: 8px;
      }

      p {
        @extend %font15-regular;
        line-height: 1.53;
        color: #131414;
        margin-bottom: 0;

        @include bp(mobile) {
          font-size: 15px;
        }
      }

      .brand__name {
        padding-right: 115px;

        @include bp(991) {
          width: 60%;
          padding-right: 0px;
        }

        @include bp(mobile) {
          margin-bottom: 24px;
        }
      }

      .brand__desc {
        max-width: 450px;

        @media screen and (min-width: 768px) and (max-width: 991px) {
          padding-left: 30px;
        }
      }
    }

    .right__side {
      display: flex;

      @include bp(mobile) {
        justify-content: space-between;
        align-items: center;
      }

      .status {
        @extend %font15-semibold;
        color: #949494;

        @include bp(mobile) {
          font-size: 12px;
        }
      }

      .leftDrowpdown {
        margin-left: 32px;

        #dropdown-button-drop-left {
          background: #fff;
          border: none;
          padding: 0;

          &::before {
            background: #fff
              url("../../../../assets/images/icon-three-dots.svg") no-repeat;
            border: none;
            height: 18px;
            width: 5px;
          }

          &:focus {
            box-shadow: none;
            outline: none;
          }
        }

        .dropdown-menu {
          padding: 11px 12px;
          border-radius: 4px;
          box-shadow: 0 2px 13px 0 rgba(63, 74, 110, 0.1);
          background-color: #fff;
          border: none;
          transform: translate3d(-15px, 16px, 0px) !important;

          .dropdown-item {
            border-radius: 4px;
            background-color: rgba(236, 56, 11, 0.07);
            color: #ec380b;
            @extend %font15-medium;
            line-height: 1.53;

            &:hover,
            &:focus {
              background-color: rgba(236, 56, 11, 0.07);
              color: #ec380b;
            }
          }
        }
      }
    }
  }
}

.createMenuBanner__wrapper .bg .logo > .logo-wrapper .lds-roller{
  width: 60px;
  height: 60px;
}