@import "~bootstrap/scss/bootstrap";
@import "../../assets/scss/base/base-include.scss";

.dashboardstabs {
  background: #f8f9fa;
  padding-bottom: 220px;

  @include bp(mobile) {
    padding-bottom: 64px;
  }

  .bg {
    background: #fff;
    border-bottom: solid 1px #dfdfdf;
    position: sticky;
    top: 100px;
    z-index: 9;

    @include bp(mobile) {
      top: 84px;
    }
  }
}

.dashboardstabs .nav.nav-tabs::-webkit-scrollbar {
  display: none;
}

.dashboardstabs .nav.nav-tabs{
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.dashboardstabs .nav.nav-tabs {
  justify-content: flex-end;
  border: 0px !important;
  background: #fff;

  @include bp(991) {
    justify-content: flex-start;
    flex-wrap: nowrap;
    overflow: scroll;
  }

  .nav-item {
    .nav-link {
      border: 0;
      color: $theme-gray;
      @extend %font18-medium;
      line-height: inherit;
      padding: 16px 0px !important;
      border-bottom: 3px solid transparent;

      @include bp(mobile) {
        margin-right: 22px;
        width: max-content;
        font-size: 15px;
        margin-bottom: 0px;
      }

      &.active {
        @extend %font18-bold;
        color: $theme-red;
        border-bottom: 3px solid $theme-red;
        line-height: inherit;

        @include bp(mobile) {
          border-bottom: 3px solid $theme-red;
          font-size: 15px;
        }
      }
    }

    &:not(:last-child) {
      .nav-link {
        margin-right: 40px;
        @include bp(mobile) {
          margin-right: 24px;
        }
      }
    }
  }
}

.multibrand__wrapper {
  display: grid;
  grid-gap: 30px;
  grid-template-columns: repeat(4, 1fr);

  @include bp(ipadpro) {
    grid-template-columns: repeat(3, 1fr);
  }

  @include bp(lg) {
    grid-template-columns: repeat(3, 1fr);
  }

  @include bp(991) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include bp(mobile) {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 32px;
  }
}

.tab__mainHeading {
  margin-top: 42px;
  margin-bottom: 32px;
  @include bp(mobile) {
    margin-top: 40px;
    margin-bottom: 32px;
  }

  h1 {
    @extend %font32-bold;
    line-height: 1.25;
    color: $h1-color;
    margin-bottom: 0;

    @include bp(mobile) {
      font-size: 21px;
    }
  }
}


.dashboardstabs .tab-content{
  padding-top: 32px;
}

.dashboardstabs{
  .loader__block{
    height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media screen and (min-width:768px) and (max-width:1199px){
  .dashboardstabs .nav.nav-tabs .nav-item:not(:last-child) .nav-link {
    margin-right: 32px;
}
}