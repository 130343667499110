
body {
  font-family: 'Work Sans', sans-serif;
  transition: background-color 0.5s;
}

%nav-font {
  font-size: 15px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $nav-font-color;
}

%h1-font {
  font-size: 76px;
  line-height: normal;
  letter-spacing: normal;
  color: $white;
}


%banner {
  min-height: 560px;
  background-repeat: no-repeat;
  background-position: center;
}

%btn-red {
  width: 200px;
  height: 48px;
  border-radius: 4px;
  background-color: #ec380b;
  color: #fff;
}

%counter-label{
  font-family: 'Work Sans', sans-serif;
  font-weight: 500;
  font-size: 18px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #949494;
}

%choosefile-btn{
  font-family: 'Work Sans', sans-serif;
  font-weight: 500;
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #fff;
}

%font16-semibold{
  font-family: 'Work Sans', sans-serif;
  font-weight: 600;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
}

%red-btn-w-arrow{
  @extend %font16-semibold;
  color: #fff;
  background: $theme-red url('../../../assets/images/white-right-arrow-btn.svg') no-repeat center;
}

%font18-regular{
  font-family: 'Work Sans', sans-serif;
  font-weight: 400;
  font-size: 18px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

%font18-medium{
  font-family: 'Work Sans', sans-serif;
  font-weight: 500;
  font-size: 18px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

%font18-bold{
  font-family: 'Work Sans', sans-serif;
  font-weight: 700;
  font-size: 18px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

%font11-medium{
  font-family: 'Work Sans', sans-serif;
  font-weight: 500;
  font-size: 11px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

%font21-semibold{
  font-family: 'Work Sans', sans-serif;
  font-weight: 600;
  font-size: 21px;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.95;
  letter-spacing: normal;
  color: #131414;
}

%font14-semibold{
  font-family: 'Work Sans', sans-serif;
  font-weight: 600;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.95;
  letter-spacing: normal;
}

%font13-semibold{
font-family: 'Work Sans';
font-size: 13px;
font-weight: 600;
line-height: 20px;
letter-spacing: 0px;
text-align: center;
}

%font21-regular{
  font-family: 'Work Sans', sans-serif;
  font-weight: 400;
  font-size: 21px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

%font12-regular{
  font-family: 'Work Sans', sans-serif;
  font-weight: 400;
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
}

%font12-medium{
  font-family: 'Work Sans', sans-serif;
  font-weight: 500;
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

%font12-SemiBold{
  font-family: 'Work Sans', sans-serif;
  font-weight: 600;
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

%font12-bold{
  font-family: 'Work Sans', sans-serif;
  font-weight: 700;
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
}

%font32-bold{
  font-family: 'Work Sans', sans-serif;
  font-weight: 700;
  font-size: 32px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

%font21-bold{
  font-family: 'Work Sans', sans-serif;
  font-weight: 700;
  font-size: 21px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

%font25-bold{
  font-family: 'Work Sans', sans-serif;
  font-weight: 700;
  font-size: 25px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.28;
  letter-spacing: normal;
}

%font20-bold{
  font-family: 'Work Sans', sans-serif;
  font-weight: 700;
  font-size: 20px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.15;
  letter-spacing: normal;
}

%font15-semibold{
  font-family: 'Work Sans', sans-serif;
  font-weight: 600;
  font-size: 15px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
}

%font15-regular{
  font-family: 'Work Sans', sans-serif;
  font-weight: 400;
  font-size: 15px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.53;
  letter-spacing: normal;
}

%font15-medium{
  font-family: 'Work Sans', sans-serif;
  font-weight: 500;
  font-size: 15px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

%font15-bold{
  font-family: 'Work Sans', sans-serif;
  font-weight: 700;
  font-size: 15px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

%font22-semibold {
  font-family: "Work Sans", sans-serif;
  font-weight: 600;
  font-size: 22px;
  font-stretch: normal;
  font-style: normal;
  line-height: .95;
  letter-spacing: normal;
}

%big-red-btn{
  max-width: 202px;
  width: 100%;
  height: 48px;
  background: $theme-red;
  border-radius: 4px;
  border: none;
}

@mixin bp($point) {
  @if $point==desktop {
    @media (max-width: 1700px) {
      @content;
    }
  }
  @if $point==laptop {
    @media (max-width: 1440px) {
      @content;
    }
  }
  @if $point==mid-device {
    @media (max-width: 1350px) {
      @content;
    }
  }
  @if $point==xl {
    @media (max-width: 1280px) {
      @content;
    }
  }

  @if $point==lg {
    @media (max-width: 1239px) {
      @content;
    }
  }

  @if $point==ipadpro {
    @media (max-width: 1024px) {
      @content;
    }
  }


  @if $point==991 {
    @media (max-width: 991px) {
      @content;
    }
  }

  @if $point==ipad {
    @media (max-width: 768px) {
      @content;
    }
  }

  @if $point==mobile {
    @media (max-width: 767px) {
      @content;
    }
  }

  @if $point==mobile-p {
    @media (max-width: 430px) {
      @content;
    }
  }

  @if $point==360 {
    @media (max-width: 360px) {
      @content;
    }
  }

  @if $point==ipad-min {
    @media (min-width: 768px) {
      @content;
    }
  }
}
