@import "~bootstrap/scss/bootstrap";
@import "../../../../../assets/scss/base/base-include.scss";

.mainMenu__wrapper {
  h5 {
    @extend %font12-bold;
    line-height: 1.5;
    color: #6d7f8b;
    margin-bottom: 8px;
  }

  p {
    @extend %font15-regular;
    line-height: 1.53;
    color: #131414;
    margin-bottom: 0;
  }

  .leftside > div:not(:last-child) {
    margin-bottom: 40px;

    @include bp(mobile) {
      margin-bottom: 24px;
    }
  }

  .addmenu-mobile {
    margin-top: 48px;

    @include bp(991) {
      .button__wrapper {
        text-align: right;
        .bg-blue-btn {
          width: 100%;
          max-width: 250px;
          margin-left: auto;
        }
      }

      @include bp(mobile) {
        .button__wrapper {
          .bg-blue-btn {
            width: 100%;
            max-width: 100%;
          }
        }
      }
    }
  }

  .operatingHours {
    p {
      &:not(:last-child) {
        margin-bottom: 16px;
      }
      span {
        &:first-child {
          width: max-content;
          min-width: 90px;
          display: inline-block;
          margin-right: 60px;

          @include bp(360){
            margin-right: 20px;
          }
        }
      }
    }
  }
}


.menu__title{
  h5{
    display: flex;
    align-items: center;
    .edit-btn{
      vertical-align: text-bottom;
      img{
        width: 15px;
        height: 15px;
      }
    }
  }
}

.menu__desc{
  h5{
    display: flex;
    align-items: center;
    .edit-btn{
      vertical-align: text-bottom;
      img{
        width: 15px;
        height: 15px;
      }
    }
  }
}

.operatingHours{
  h5{
    display: flex;
    align-items: center;
    .edit-btn{
      vertical-align: text-bottom;
      img{
        width: 15px;
        height: 15px;
      }
    }
  }
}

.mainMenu__wrapper{
  .mobile-dots-btn{
    position: absolute;
    right: 15px;
  }
}