@import "~bootstrap/scss/bootstrap";
@import "../../assets/scss/base/base-include.scss";

.shared__vbs__wrapper {
  padding-top: 16px;
  .accordion {
    > .card {
      border: 0px;
      border-radius: 4px;
      box-shadow: 0 5px 9px -3px rgba(0, 0, 0, 0.08);
      background-color: #fff;
      &:not(:last-child) {
        margin-bottom: 12px;
      }
    }

    .card-body {
      padding-top: 10px;
    }
  }

  .accodion__header__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    @include bp(mobile) {
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
    }

    .heading {
      flex: 1;
      text-align: left;

      span {
        @extend %font15-semibold;
      }

      @include bp(mobile) {
        padding-bottom: 16px;
      }
    }
  }

  .accordion .card-header button::after {
    display: none;
  }

  .card-header {
    height: 28px;
    padding: 0px 26px 4px 26px;
    background: #fff;
    border: none;
    display: flex;
    align-items: center;
    justify-content: start;
    > .btn {
      display: inline-block;
      width: auto;
      position: relative;
    }

    @include bp(mobile) {
      height: auto;
      padding: 4px 16px 6px 16px;
    }

    .btn-link {
      &:hover {
        text-decoration: none;
      }
    }
  }

  .right__side__content {
    display: flex;
    align-items: center;
    @include bp(mobile) {
      width: 100%;
      align-items: flex-end;
      padding-bottom: 16px;

      .threeDotsBropdown #dropdown-button-drop-left {
        margin-top: 0px;
        padding-top: 0;
        height: 36px !important;
        width: 36px !important;
      }

      .threeDotsBropdown.drop-icon .dropdown-menu {
        left: 36px !important;
      }
    }
    .status__wrapper {
      @include bp(mobile) {
        flex: 1;
      }

      .custom__badge {
        height: 36px;
        @extend %font15-semibold;

        @include bp(mobile) {
          width: 100%;
          flex: 1;
          display: inline-block;
          text-align: center;
          margin-top: 6px;
        }
      }
    }
  }

  .accordion__body__wrapper {
    padding: 0px 10px;

    @include bp(mobile) {
      padding: 0;
    }
    .accordion__data__card {
      &:first-child {
        padding-top: 4px;
      }

      &:not(:last-child) {
        border-bottom: solid 1px #f1f1f1;
      }

      &:not(:first-child) {
        padding-top: 24px;
      }

      @include bp(mobile) {
        &:not(:first-child) {
          padding-top: 16px;
        }
      }

      .data {
        label {
          @extend %font12-bold;
          line-height: 1.67;
          letter-spacing: normal;
          color: #6d7f8b;
          text-transform: uppercase;
        }

        p {
          @extend %font15-regular;
          line-height: 1.53;
          letter-spacing: normal;
          color: #131414;
          margin-bottom: 0px;
        }
      }
    }

    .edit__button__wrapper {
      text-align: right;

      .edit-btn {
        padding: 0;

        img {
          vertical-align: baseline;
        }
      }
    }
  }
}

// new update as per design on sheared VB list

.shared__vbs__wrapper__inner {
  background: #fff;
  padding: 0px 26px;
  @include bp(mobile) {
    padding: 0px 16px;
  }

  .accodion__header__content {
    border-bottom: 1px solid #f1f1f1;
    padding: 5px 0px;

    @include bp(mobile) {
      padding-top: 20px;
    }
  }
}

.shared__vbs__wrapper {
  .marketPlace__counts {
    font-size: 12px;
    color: #ec380b;
    position: relative;
    padding-right: 20px;

    &.open::after {
      content: "";
      position: absolute;
      background: url("../../assets/images/arrow-down-red.svg") no-repeat;
      height: 16px;
      width: 16px;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      transform: rotate(180deg);
      transition: all 0.25s linear;
    }

    &::after {
      content: "";
      position: absolute;
      background: url("../../assets/images/arrow-down-red.svg") no-repeat;
      height: 16px;
      width: 16px;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      transition: all 0.25s linear;
    }
  }

  .marketplace__accordion__wrapper {
    .market__place__card {
      &:not(:last-child) {
        margin-bottom: 16px;

        @include bp(mobile) {
          margin-bottom: 32px;
        }
      }
    }
  }

  @include bp(mobile) {
    .mobile-screen-label {
      font-family: "Work Sans", sans-serif;
      font-weight: 700;
      font-size: 12px;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.67;
      letter-spacing: normal;
      color: #6d7f8b;
      text-transform: uppercase;
    }
  }
}
