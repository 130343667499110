@import "~bootstrap/scss/bootstrap";
@import "../../assets/scss/base/base-include.scss";


.users__wrapper{
    .editModifier__wrapper .basic__info__content select.form-control{
        background: #fff url(../../assets/images/select-arrow-icon.svg) no-repeat 96% !important;
    }

    .editModifier__wrapper .basic__info__content select.form-control.big-select {
        background: #fff url(../../assets/images/select-arrow-icon.svg) no-repeat 96% !important;
    }

    .editModifier__wrapper .basic__info__content select[name="stateId"]{
        background: #fff url(../../assets/images/select-arrow-icon.svg) no-repeat 92% !important;
    }

    .basic__info__content {
        label{
            margin-bottom: 16px;
        }
    }

    @include bp(991){
        .editModifier__wrapper .basic__info__content .form-row {
            max-width: 100%;
        }
    }
}
