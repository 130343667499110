@import "~bootstrap/scss/bootstrap";
@import "../../assets/scss/base/base-include.scss";

.landingPage__wrapper {
  .container-custom {
    max-width: 1720px;
    margin: auto;
  }

  @media screen and (max-width:1600px){
    .container-custom {
      max-width: 100%;
      margin: auto;
      padding: 0px 20px;
    }
  }

  @include bp(mobile){
    .container-custom {
      padding: 0;
    }
  }

  .sections {
    padding-top: 64px;

    @include bp(mobile) {
      padding-top: 40px;
    }

    h2 {
      font-family: 'Work Sans', sans-serif;
      font-weight: 700;
      font-size: 40px;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: normal;
      text-align: center;
      color: #131414;

      @include bp(mobile) {
        font-size: 21px;
        line-height: 1.52;
        margin-bottom: 8px;
      }
    }

    h3 {
      font-family: 'Work Sans', sans-serif;
      font-weight: 700;
      font-size: 32px;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: normal;
      color: #131414;
      margin-bottom: 24px;

      @include bp(mobile) {
        font-size: 18px;
        line-height: 1.33;
        margin-bottom: 8px;
      }
    }

    p {
      font-family: 'Work Sans', sans-serif;
      font-weight: 400;
      font-size: 20px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.6;
      letter-spacing: -0.6px;
      color: #131414;

      @include bp(mobile) {
        font-size: 15px;
        line-height: 1.6;
      }
    }

    .section__action__wrapper {
      padding-top: 24px;
      .section__action__link {
        width: 142px;
        height: 48px;
        padding: 11px 10px 12px 10px;
        border-radius: 4px;
        background-color: #ec380b;
        font-family: 'Work Sans', sans-serif;
      font-weight: 600;
        font-size: 15px;
        font-stretch: normal;
        font-style: normal;
        line-height: inherit;
        letter-spacing: normal;
        text-align: center;
        color: #fff;
        cursor: pointer;

        &:hover {
          box-shadow: 0 2px 10px 0 rgba(218, 0, 0, 0.17);
          background-color: #ec380b;
        }

        &:focus {
          box-shadow: none;
        }
      }

      .section__action__link__inline {
        font-family: 'Work Sans', sans-serif;
      font-weight: 600;
        font-size: 15px;
        font-stretch: normal;
        font-style: normal;
        line-height: inherit;
        letter-spacing: normal;
        color: #ec380b;
        cursor: pointer;
        display: flex;

        span {
          margin-right: 8px;
          > img {
            vertical-align: baseline;
          }
        }
      }
    }
  }

  .section2 {
    padding-top: 0;
    .bg {
      padding: 64px 0px;
      background-color: #f3c130;
      position: relative;

      @include bp(ipadpro) {
        padding-top: 166px;
        padding-bottom: 40px;
      }

      &::after {
        content: url("../../assets/images/section2-graphic-left.svg");
        position: absolute;
        left: 0;
        top: auto;
        bottom: 0;
        background-size: cover;

        @include bp(ipadpro) {
          display: none;
        }
      }

      &::before {
        content: url("../../assets/images/section2-graphic-right.svg");
        position: absolute;
        right: 0;
        top: auto;
        bottom: 0;
        background-size: cover;

        @include bp(mobile) {
          content: url("../../assets/images/section2-graphic-right-mobile.svg");
          top: auto;
          bottom: 0;
        }
      }
    }
  }

  .section4 {
    .bg {
      padding-top: 24px;
      padding-bottom: 24px;
      background-color: #f8f9fa;

      @include bp(mobile) {
        padding-bottom: 40px;

        .section__action__wrapper {
          padding-top: 4px;
        }
      }
    }
  }

  .section5 {
    padding-top: 0;

    .bg {
      padding: 64px 0px;
      background-color: #3f4a6e;

      @include bp(mobile) {
        padding: 40px 0px;
      }

      position: relative;

      &::after {
        content: url("../../assets/images/section5-graphic-left.svg");
        position: absolute;
        left: 0;
        top: 0;
        background-size: cover;

        @include bp(mobile) {
          content: url("../../assets/images/section5-graphic-left-mobile.svg");
          top: auto;
          bottom: 0;
        }
      }
    }

    h2,
    p {
      color: #fff;
    }
  }

  .section2,
  .section5 {
    h2 {
      max-width: 1037px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 24px;
      text-align: center;
    }

    p {
      max-width: 1037px;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
    }

    .section__action__wrapper {
      text-align: center;
    }
  }

  .section2 {
    @include bp(mobile) {
      h2 {
        margin-bottom: 8px;
      }

      .section__action__wrapper {
        padding-top: 16px;
      }
    }
  }

  .section5 {
    @include bp(mobile) {
      h2 {
        margin-bottom: 8px;
      }

      .section__action__wrapper {
        padding-top: 16px;
      }
    }
  }

  .section3 {
    @include bp(mobile) {
      .section__action__wrapper {
        padding-top: 16px;
      }
    }
  }

  .landing__banner {
    padding-top: 0;
    .bg {
      background: url("../../assets/images/landingpage__banner.jpg") no-repeat;
      height: auto;
      background-size: cover;
      height: 800px;

      @include bp(laptop) {
        height: 650px;
      }

      @include bp(mobile) {
        background: url("../../assets/images/landingpage__banner__mobile_375.jpg")
          no-repeat;
        height: 496px;
        background-size: cover;
      }
    }

    .banner__content {
      padding-top: 144px;
      padding-bottom: 56px;
      display: flex;
      align-items: center;
      position: relative;
      overflow: hidden;
      justify-content: space-between;
      max-width: 100%;
      // padding-left: 16px;

      @media screen and (max-width:1600px){
        padding-left: 36px;
      }

      @include bp(mobile){
        padding-top: 124px;
      }

      @media (min-width: 1800px) {
        margin-left: 92px;
      }

      @include bp(ipadpro) {
        flex-direction: column;
        padding-right: 16px;
      }

      @include bp(mobile) {
        padding-left: 16px;
      }

      .section__action__wrapper{
        @include bp(mobile){
          padding-top: 0px;
        }
      }

      .left__content {
        // max-width: 824px;
        width: 50%;
        flex: 0 1 auto;

        @include bp(ipadpro) {
          width: 100%;
        }

        h1 {
          font-family: 'Work Sans', sans-serif;
          font-weight: 500;
          font-size: 64px;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.03;
          letter-spacing: -1.6px;
          color: #fff;
          margin-bottom: 36px;

          @include bp(mobile) {
            font-size: 22px;
            line-height: 1.27;
            margin-bottom: 8px;
          }
        }

        p {
          color: #fff;
        }
      }

      .big-screens{
        .video-box{
          background-image: url('../../assets/images/tablet-bg.png');
          background-size: cover;
          position: relative;
          z-index: 9;
          height: 600px;
          width: 789px;

          @include bp(laptop){
            max-width: 600px;
            height: 460px;
          }

          video{
            position: absolute;
            top: 0px;
            padding: 28px 0px 28px 28px;
            border-top-left-radius: 15px;
            border-bottom-left-radius: 15px;
            width: 100%;
            height: 100%;

            @include bp(laptop){
              padding: 20px 0px 20px 20px;
            }
          }
        }
      }

      .small-screens{

        @include bp(mobile){
          margin-top: 32px;
        }

        .video-box{
          background-image: url('../../assets/images/tablet-bg-m.png');
          background-size: cover;
          position: relative;
          z-index: 9;
          height: 500px;
          width: 690px;

          @include bp(mobile){
            height: 250px;
            width: 345px;
          }

          video{
            position: absolute;
            top: 0px;
            padding:24px;
            border-top-left-radius: 15px;
            border-top-right-radius: 15px;
            width: 100%;
            height: 100%;

            @include bp(mobile){
              padding:12px;
            }
          }
        }
      }
    }
  }

  .landingpage__header {
    position: fixed;
    top: 0px;
    padding: 12px 16px;
    z-index: 999;
    width: 100%;
    transition: 0.1s all ease-in-out;

    .header__content__wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .logo__link {
        img {
          max-width: 89px;
          transition: 0.1s all ease-in-out;

          @include bp(mobile) {
            max-width: 89px;
          }
        }
      }

      .login__action__wrapper {
        .btn-link {
          font-family: 'Work Sans', sans-serif;
          font-weight: 600;
          font-size: 15px;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.53;
          letter-spacing: normal;
          text-align: center;
          color: #fff;

          &:focus {
            box-shadow: none;
          }
        }

        .signIn__link {
          width: 142px;
          height: 40px;
          margin: 0 15px 0 0;
          padding: 8px 15px 8px 15px;
          border-radius: 4px;
          background-color: #3f4a6e;

          &:hover {
            box-shadow: 0 2px 10px 0 rgba(65, 74, 107, 0.17);
            background-color: #414a6b;
            text-decoration: none;
          }
        }

        .signUp__link {
          width: 142px;
          height: 40px;
          padding: 8px 15px 8px 15px;
          border-radius: 4px;
          background-color: #ec380b;

          &:hover {
            box-shadow: 0 2px 10px 0 rgba(218, 0, 0, 0.17);
            background-color: #ec380b;
            text-decoration: none;
          }
        }

        @include bp(mobile) {
          .btn-link,
          .signIn__link,
          .signUp__link {
            background: none;
            width: auto;
            padding: 0;
            height: auto;
            border: 0;
          }
        }
      }
    }
  }
}

.black__logo {
  display: none;
  transition: 0.1s all ease-in-out;
}

.landingpage__header.sticky {
  background: #fff;
  transition: 0.1s all ease-in-out;
  padding: 12px 16px;
  z-index: 999;
  // box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.4);
  border-bottom: 1px solid #ececec;

  .white__logo {
    display: none;
    transition: 0.1s all ease-in-out;
  }

  .black__logo {
    display: block;
    transition: 0.1s all ease-in-out;
  }

  @include bp(mobile) {
    .btn-link,
    .signIn__link,
    .signUp__link {
      color: #ec380b !important;
  }
}
}

// some extra media css
@media screen and (min-width:768px) and (max-width:1024px){
  .landingPage__wrapper .landing__banner .banner__content .left__content h1{font-size: 48px; margin-bottom: 16px;}

  .landingPage__wrapper .landing__banner .banner__content  .section__action__wrapper{
    padding-top: 0;
  }

  .landingPage__wrapper .landing__banner .banner__content .small-screens{
    margin-top: 40px;
  }

  .landingPage__wrapper .section2 .bg{
    padding-top: 380px;
    padding-bottom: 64px;
  }

  .landingPage__wrapper .section2 .bg::before{bottom: 0; top: auto;}
  .landingPage__wrapper .section4 .bg {
    padding-top: 36px;
    padding-bottom: 64px;
  }

  .landingPage__wrapper .section5 .bg::after{
    top: auto;
    bottom: 0;
  }
}

@media screen and (min-width:1024px) and (max-width:1199px){
  .landingPage__wrapper .section2 .bg{
    padding-top: 320px;
  }
}


// static__pages css

.static__pages__wrapper{

  .login__action__wrapper {
    @include bp(mobile){
      .btn-link{
        color: #ec380b !important;
      }
    }
  }
    

    .landingpage__header{
        padding: 12px 16px;
    }

    .landingpage__header.sticky {
      padding: 12px 16px;
      .logo{
          img{
              max-width: 89px;
              transition: 0.1s all ease-in-out;
          }
      }
    }

    main{
        background-color: #f8f9fa;
        margin-top: 64px;
        padding-bottom: 64px;
        
    }

    .page__title__section{
        padding-top: 48px;

        .goBack{
            margin-bottom: 32px;
        }
        .main_heading {
            padding-bottom: 24px;
      
            h1 {
              @extend %font32-bold;
              line-height: 1.25;
              color: #131414;
              margin-bottom: 0;
      
              @include bp(mobile) {
                font-size: 21px;
                margin-bottom: 8px;
              }
            }
          }
    }

    .inner__section__wrapper{
        background: #fff;
        padding: 0px 48px;
        box-shadow: 0 5px 9px -3px rgba(0, 0, 0, 0.08);
        border-radius: 4px;

        @include bp(mobile){
          padding: 0px 15px;
        }

        .sections{
            border-top: 1px solid #c0c0c818;
            padding: 0;
        }

        .section-1{
            padding: 48px 0px;

            @include bp(mobile){
              padding-top: 16px;
              padding-bottom: 32px;
            }
        }

        .section-2{
            padding: 32px 0px; 

            @include bp(mobile){
              padding-bottom: 24px;
            }
        }

        .section-3{
            padding: 64px 0px 16px; 

            @include bp(mobile){
              padding-top: 32px;
              padding-bottom: 16px;
            }
        }

        .section-4{
            padding: 64px 0px;

            @include bp(mobile){
              padding-top: 32px;
              padding-bottom: 16px;
            }
        }

    }

    .steps-count{
        width: 56px;
        height: 56px;
        background-color: #ec380b;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 40px;

        @include bp(mobile){
          width: 34px;
        height: 34px;
        margin-bottom: 16px
        }

        span{
            @extend %font15-semibold;
            color: #fff;

            @include bp(mobile){
              font-size: 12px;
            }
        }
        
    }

    .bg{
        background: #fff !important;
         > .section-content{

            h3{
                font-size: 25px;
                margin-bottom: 12px;

                @include bp(mobile){
                  font-size: 18px;
                  margin-bottom: 8px;
                }
            }

            h4{
                @extend %font15-bold;
                margin-bottom: 24px;

                @include bp(mobile){
                  font-size: 15px;
                  margin-bottom: 8px;
                }
            }

            p{
                font-size: 15px;
            }
        }
    }
}

.terms__content__wrapper{
    padding: 0px 30px;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 5px 9px -3px rgba(0, 0, 0, 0.08);
    margin-bottom: 40px;
    padding-bottom: 40px;

    @include bp(mobile){
        padding: 0px 16px;
    }

    .sections{
        padding-top: 32px;

        @include bp(mobile){
            padding-top: 16px;
        }

        h3{
            font-size: 21px;
            margin-bottom: 16px;

            @include bp(mobile){
                margin-bottom: 8px;
                font-size: 18px;
            }
        }

        p, ul, li{
            font-size: 15px;
            color: #131414;
        }
    }

    .intro__section{
        padding-top: 24px;

        p{
          font-family: 'Work Sans', sans-serif !important;
          font-weight: 600;
        }
    }

    .restrictions__section{
        padding-bottom: 0px;

        li{
            list-style-type: disc;
            margin-left: 20px;
            line-height: 1.53;
            font-size: 15px;

            &::marker{
                color: #c0c0c8;
                background-color: #c0c0c8;
            }

            &:not(:last-child){
                margin-bottom: 8px;

                @include bp(mobile){
                    margin-bottom: 8px;
                }
            }
        }
    }
}