@import "~bootstrap/scss/bootstrap";
@import "../../../assets/scss/base/base-include.scss";

.orders__page__wrapper {
  .managermodifier__wrapper {
    background-color: #f8f9fa;
    padding-bottom: 76px;

    .managermodifier__header {
      padding: 48px 0px 48px 0px;

      @include bp(xl) {
        padding-top: 40px;
        padding-bottom: 40px;
      }

      @include bp(mobile) {
        padding-top: 80px;
        padding-bottom: 40px;
      }

      h1 {
        @extend %font32-bold;
        line-height: 1.25;
        color: #131414;
        margin-bottom: 0;

        @include bp(mobile) {
          font-size: 21px;
          margin-bottom: 0px;
        }

        @include bp(360) {
          margin-bottom: 16px;
        }
      }

      @media screen and (min-width:1321px) and (max-width:1380px) {
        .customers__search {
            width: 210px;
        }
      }

      @media screen and (min-width:1200px) and (max-width:1320px) {
        .customers__search {
            width: 210px;
        }
      }

      @media screen and (min-width:1440px) {
        .customers__search {
            width: 235px;
        }
      }

      .form {
        display: flex;
        align-items: center;

        @include bp(ipadpro) {
          flex-direction: column;
        }

        .search__modifiers {
          flex: 1;
          @include bp(ipadpro) {
            width: 100%;
            margin-bottom: 16px;
          }

          .form-control {
            height: 48px;
            @include bp(mobile) {
              height: 40px;
            }
          }

          .search_modifier_group {
            @include bp(ipadpro) {
              margin-right: auto;
            }
          }
        }
      }

      .red-btn.btn {
        @include bp(ipadpro) {
          width: 100%;
          max-width: 100%;
          height: 40px;
        }
      }
    }

    .red-btn {
      min-width: 128px;
      border: 1px solid #ec380b;
      background-color: #ec380b;
      color: #fff;
      display: flex;
      align-items: center;
      width: auto;
      padding: 10px 24px;

      &:hover {
        text-decoration: none;
        color: #fff;
        box-shadow: 0 2px 10px 0 rgba(218, 0, 0, 0.17);
      }
    }

    .managermodifier__noListing {
      border-radius: 4px;
      box-shadow: 0 5px 9px -3px rgba(0, 0, 0, 0.08);
      background-color: #fff;
      height: 60vh;

      .managermodifier__noListing__innerwrapper {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .content {
          @include bp(mobile) {
            max-width: 288px;
            margin: auto;
          }
          .img-box {
            margin-bottom: 24px;
            text-align: center;
          }

          .content-desc {
            text-align: center;
            h3 {
              @extend %font20-bold;
              color: $theme-red;
              margin-bottom: 28px;
            }

            p {
              @extend %font15-medium;
              color: #949494;
              line-height: 1;
            }
          }
        }
      }
    }

    .managermodifier__listinPage__wrapper {
      .modifierListing__table {
        border-collapse: separate;
        border-spacing: 0 12px;
        // margin-bottom: 60px;

        @include bp(mobile) {
          margin-bottom: 8px;
        }

        thead {
          th {
            @extend %font12-bold;
            color: #6d7f8b;
            line-height: 1.67;
            border: 0px !important;
            padding: 0px 26px;
            text-transform: uppercase;
          }
        }

        tbody {
          tr {
            border-radius: 4px;
            box-shadow: 0 5px 9px -3px rgba(0, 0, 0, 0.08);
            background-color: #fff;

            td {
              border: 0px !important;
              @extend %font15-semibold;
              color: #131414;
              line-height: 1.53;
              padding: 0px 26px;

              .modifierDeatil__link {
                @extend %font15-semibold;
                color: #131414;
                line-height: 1.53;
                padding: 0px;

                &:hover {
                  text-decoration: none;
                  color: inherit;
                }
              }

              .table__actions {
                display: flex;
                align-items: center;

                > div:not(:last-child) {
                  margin-right: 22px;
                }

                .onOff-toggle-action {
                  .switch {
                    margin-bottom: 0px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .mat-header-row {
    display: flex;
    height: auto;
    // margin-bottom: 10px;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  th.mat-header-cell:first-of-type {
    padding-left: 34px;
  }

  th.mat-header-cell,
  td.mat-cell {
    border-bottom: none;
    display: flex;
  }

  .mat-row {
    padding: 7px 36px;
    flex-wrap: wrap;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 10px;
    padding-left: 0;
    padding-right: 0;
  }

  .table__actions {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
  }

  .modifierListing__table {
    @include bp(991) {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      background: transparent;
      border-radius: 0;
      padding-bottom: 0;

      tr.mat-header-row {
        display: none;
      }

      .column-1,
      .column-2,
      .column-3,
      .column-4,
      .column-5,
      .column-6 {
        width: 100%;
      }

      .column-6 {
        justify-content: space-between;
        align-items: center;
        padding-bottom: 0;
      }

      tbody {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        justify-content: space-between;
      }

      .mat-row {
        height: auto;
        border-radius: 4px;
        float: left;
        width: 49.5%;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
        background-color: #ffffff;
        margin-bottom: 32px;
        padding: 17px 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
      }

      .mat-cell,
      .mat-cell:first-of-type {
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        padding: 0px 0px 16px 0px !important;
      }

      .mat-cell {
        @include bp(991) {
          &:last-child {
            padding-bottom: 0px !important;
          }
        }
      }

      .mat-cell::before {
        content: attr(data-column);
        left: 15px;
        padding-right: 10px;
        position: static;
        width: 100%;
        font-size: 12px;
        font-weight: 600;
        color: #6d7f8b;
        line-height: 1.67;
        top: 10px;
      }

      .managermodifier__wrapper
        .managermodifier__listinPage__wrapper
        .modifierListing__table
        tbody
        tr
        td
        .modifierDeatil__link,
      .managermodifier__wrapper
        .managermodifier__listinPage__wrapper
        .modifierListing__table
        tbody
        tr
        td {
          font-family: 'Work Sans', sans-serif;
          font-weight: 400 !important;
        font-size: 15px !important;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.53;
        letter-spacing: normal;
        color: #131414;
      }
    }

    @include bp(991) {
      .mat-row {
        width: 100%;
      }
    }
  }

  .modifierListing__table {
    .right_content {
      display: flex;
      // justify-content: space-between;
      align-items: center;
      width: 100%;

      > span {
        text-align: center;
        margin-right: 8px;

        @include bp(mobile) {
          flex: 1;
        }
      }
    }
  }

  .manage-restra-search {
    .search_modifier_group {
      margin-right: 0;
    }
  }
}

.orderListing__listinPage {
  .column-1 {
    width: 20%;
    text-transform: capitalize;
  }

  .column-2 {
    width: 25%;
    text-transform: capitalize;
  }

  .column-3 {
    width: 15%;
    text-transform: capitalize;
  }

  .column-4 {
    width: 10%;
    text-transform: capitalize;
  }

  .column-5 {
    width: 12%;
    text-transform: capitalize;
    justify-content: center;
  /*   .right_content {
    display: flex;
    justify-content: center;
    } */
  }

  .column-6 {
    width: 10%;
    text-transform: capitalize;
    justify-content: flex-end;
    .right_content {
      justify-content: flex-end;
    }
  }
  .column-7{
    width: 8%;
  }
}

.ordersListing-table {
  tbody {
    tr {
      td {
        .customer-data {
          display: flex;

          @include bp(991) {
            flex-direction: column;
          }

          > .img-box {
            margin-right: 24px;

            @include bp(991) {
              margin-right: 0px;
              margin-bottom: 16px;
            }
          }
        }

        p {
          margin-bottom: 0;
          @extend %font15-semibold;
          line-height: 1.6;
          letter-spacing: normal;
          color: #131414;
        }

        .order-no,
        .order-date,
        .kitchen-name {
          @extend %font12-bold;
          letter-spacing: normal;
          color: #6d7f8b;
        }

        .failed-text{
          @extend %font12-bold;
          letter-spacing: normal;
          color: #EC380B;

        }

        .kitchen-name {
          text-transform: uppercase;
          @include bp(991) {
            text-transform: capitalize;
          }
        }

        .table__actions {
          .right_content {
            @include bp(991) {
              justify-content: flex-start !important;
            }
          }
        }
      }
    }

    .preparing {
      border-left: 4px solid #fac101;
    }

    .cancelled {
      border-left: 4px solid #797981;
    }

    .completed {
      border-left: 4px solid #008bda;
    }

    .ready {
      border-left: 4px solid #03b27f;
    }

    .pending {
      border-left: 4px solid #e5870b;
    }

    .new {
      border-left: 4px solid #e5870b;
    }

    .delivery {
      border-left: 4px solid #9966cc;
    }

    .label__background {
      border-radius: 20px;
      padding: 7px 14px 7px 14px;
      box-sizing: border-box;
    }

     .new__color {
      color: #e5870b;
      background-color: rgba(229, 135, 11, 0.08);
    }

    .preparing__color {
      color: #fac101;
      background-color: rgba(250, 193, 1, 0.08);
    }

    .cancelled__color {
      color: #797981;
      background-color: rgba(121, 121, 129, 0.08);
    }

    .completed__color {
      color: #008bda;
      background-color: rgba(0, 139, 218, 0.08);
    }

    .ready__color {
      color: #03b27f;
      background-color: rgba(3, 178, 127, 0.08);
    }

    .pending__color {
      color: #e5870b;
      background-color: rgba(229, 135, 11, 0.08);
    }

    .new {
      color: #e5870b;
      background-color: rgba(229, 135, 11, 0.08);
    }
    .delivery__color {
      color: #9966cc;
      background-color: rgba(243, 234, 253, 1);
    }
  }

  @include bp(991) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    background: transparent;
    border-radius: 0;
    padding-bottom: 0;

    tr.mat-header-row {
      display: none;
    }

    .column-1,
    .column-2,
    .column-3,
    .column-4,
    .column-5,
    .column-6 {
      width: 100%;
    }

    .column-6 {
      justify-content: space-between;
      align-items: center;
      padding-bottom: 0;
    }

    tbody {
      display: flex;
      width: 100%;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    .mat-row {
      height: auto;
      border-radius: 4px;
      float: left;
      width: 49.5%;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
      background-color: #ffffff;
      margin-bottom: 32px;
      padding: 17px 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
    }

    .mat-cell,
    .mat-cell:first-of-type {
      position: relative;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
      padding: 0px 0px 16px 0px !important;
    }

    .mat-cell {
      @include bp(991) {
        &:last-child {
          padding-bottom: 0px !important;
        }
      }
    }

    .mat-cell::before {
      content: attr(data-column);
      left: 15px;
      padding-right: 10px;
      position: static;
      width: 100%;
      font-size: 12px;
      font-weight: 600;
      color: #6d7f8b;
      line-height: 1.67;
      top: 10px;
    }

    .managermodifier__wrapper
      .managermodifier__listinPage__wrapper
      .modifierListing__table
      tbody
      tr
      td
      .modifierDeatil__link,
    .managermodifier__wrapper
      .managermodifier__listinPage__wrapper
      .modifierListing__table
      tbody
      tr
      td {
        font-family: 'Work Sans', sans-serif;
        font-weight: 400 !important;
      font-size: 15px !important;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.53;
      letter-spacing: normal;
      color: #131414;
    }
  }

  @include bp(991) {
    .mat-row {
      width: 100%;
    }
  }
}

.order__status__multiselect__wrapper {
  .dropdown-menu.show {
    padding-top: 0;
    padding-bottom: 0px;
    transform: translate(0px, 48px);
    border: none;
    max-width: 200px;
    z-index: 9;

    @include bp(mobile) {
      width: 165px;
    }
  }
  .dropdown {
    .btn.btn-primary,
    .btn.btn-primary:active {
      background: transparent;
      padding: 12px 15px 13px;
      border-radius: 4px;
      border: solid 1px #6d7f8b;
      height: 48px;
      width: 175px;
      text-align: left;
      @extend %font15-medium;
      line-height: 1.53;
      letter-spacing: normal;
      color: #1b2236;

      @include bp(mobile) {
        height: 40px;
        padding-top: 8px;
        padding-bottom: 9px;
        width: 165px;
        margin-left: 16px;
      }

      &:focus {
        box-shadow: none;
      }

      &::after {
        position: absolute;
        content: url("../../../assets/images/Icon-arrow-top-ckc.svg");
        width: 15px;
        height: 30px;
        top: 5px;
        bottom: 0;
        right: 15px;
        margin: auto;
        background: unset;
        border: none;
      }
    }

    .dropdown__action__wrapper {
      padding: 16px 11px 16px 16px;
      border-radius: 4px;
      box-shadow: 0 2px 13px 0 rgba(63, 74, 110, 0.1);
      background-color: #fff;

      .main__heading {
        @extend %font15-regular;
        line-height: 1.53;
        letter-spacing: normal;
        color: #3f4a6e;
        // border-bottom: solid 1px #f1f1f1;

        a {
          padding: 0;
        }
      }

      .sub__heading {
        padding-top: 16px;
        border-top: solid 1px #f1f1f1;
        @extend %font12-medium;
        text-transform: uppercase;
      }

      .custom-checkbox-container {
        @extend %font15-regular;
        line-height: inherit;
        letter-spacing: normal;
        color: #3f4a6e;
        padding-left: 24px !important;
        align-items: center;
      }
    }
  }

  .button__wrapper {
    text-align: left;
    .btn {
      width: 168px;
      height: 36px;
      padding: 5px 16px 7px 16px;
      border-radius: 4px;
      background-color: #ec380b;
      color: #fff;
      font-weight: 600;

      &:hover {
        box-shadow: 0 2px 10px 0 rgba(218, 0, 0, 0.17);
        background-color: #ec380b;
      }

      @include bp(mobile) {
        max-width: 100%;
      }
    }
  }
}

.orders__page__wrapper {
  .search__modifiers {
    //margin-right: 24px;
    @include bp(mobile) {
      max-width: 240px;
      margin-right: 0px;
    }

    .search_modifier_group {
      margin-right: 0px;

      @include bp(991) {
        margin-bottom: 0;
      }
    }
  }

  .export__wrapper {
    text-align: center;
    .btn {
      @extend %font15-semibold;
      line-height: 1.53;
      letter-spacing: normal;
      color: #414a6b;
      width: 158px;
      height: 48px;
      padding: 8px;
      border-radius: 4px;
      border: solid 1px #414a6b;
      background-color: #fff;

      &:focus {
        box-shadow: none;
      }

      .icons {
        margin-right: 8px;
        vertical-align: text-bottom;
      }
    }
  }

  .order__filters__mobile__screens {
    width: 100%;
    max-width: 100%;
    .filters__hidden__options {
      padding: 25px 0px;
      background-color: #edf0f2;
      margin-left: -16px;
      margin-right: -16px;
      padding-left: 16px;
      padding-right: 16px;
      border-radius: 4px;

      @include bp(mobile) {
        border-radius: 0;
      }

      @include bp(ipad-min) {
        width: 100%;
        max-width: 100%;
      }
    }

    .search_modifier_group,
    .input-group,
    .custom-file {
      height: 48px;
      @include bp(mobile) {
        height: 40px;
        width: 165px;
      }

      .search-btn {
        top: 8px;

        @include bp(mobile) {
          top: 4px;
        }
      }
    }

    .filters__wrapper__mobile {
      display: flex;
      justify-content: space-between;
      width: 100%;

      .filters__toggle__mobile {
        .filters {
          height: 48px;
          width: 48px;
          border-radius: 4px;
          // box-shadow: 0 2px 10px 0 rgba(65, 74, 108, 0.17);
          border: solid 1px #414a6b;
          background-color: #fff;

          @include bp(mobile) {
            height: 40px;
            width: 40px;
          }

          span img {
            vertical-align: sub;
          }
        }
      }

      .export__wrapper {
        .btn {
          height: 48px;

          @include bp(mobile) {
            height: 40px;
          }
        }
      }
    }
  }

  @include bp(mobile) {
    .user__custom__select .custom-select-ckc__control::after {
      height: 11px;
    }
  }
}

//date picker css here

.date__filter__wrapper {
  max-width: 394px;
  // padding: 0px 16px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  border: solid 1px #c0c0c9;
  background-color: #fff;
  height: 48px;
  white-space: nowrap; 

  @include bp(mid-device) {
    max-width: 100%;
    padding: 0px 0px;
  }

  @include bp(ipadpro) {
    max-width: 100%;
    padding: 0px 0px;
    height: 48px;
  }

  @include bp(mobile) {
    height: 40px;
    padding: 0px;
  }

  .date__filter__inner {
    @extend %font12-medium;
    line-height: 1;
    letter-spacing: normal;
    color: #6d7f8b;
    width: 100%;

    @include bp(ipadpro) {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    > span {
      &:nth-of-type(2) {
        margin: 0px 16px;

        @include bp(mid-device) {
          margin: 0px 5px;
        }

        @include bp(ipadpro) {
          margin: 0px 5px;
        }

        @include bp(mobile) {
          margin: 0px 15px;
        }
      }
    }
  }

  input {
    border: none;
    @extend %font12-medium;
    line-height: 1;
    letter-spacing: normal;
    color: #6d7f8b;
    max-width: 116px;
    flex-direction: row-reverse;

    @include bp(mid-device) {
      max-width: 100px;
    }

    @include bp(ipadpro) {
      max-width: 100px;
    }

    @include bp(mobile) {
      max-width: 120px;
    }

    &::-webkit-calendar-picker-indicator {
      margin-left: 0px;
      margin-right: 10px;

      @include bp(mid-device) {
        margin-right: 0px;
      }

      @include bp(ipadpro) {
        margin-right: 8px;
      }
    }
  }
}

.custom-datepicker {
  .react-daterange-picker__wrapper {
    border: unset;
  }
  .react-daterange-picker__range-divider {
    margin: 0 7px;
  }
  .react-calendar__navigation {
    font-size: 12px;
    font-weight: bold;
  }
  .react-calendar__month-view__weekdays__weekday {
    background-color: rgba(128, 128, 128, 0.234);
  }
  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus,
  .react-calendar__tile--active {
    background: #ec380b;
  }
}

.orders__page__wrapper {
  .userselect {
    button {
      height: 48px;
    }
    .searchable__dropdown__ckc__control {
      min-width: unset !important;
    }

    .searchable-menu-close::after,
    .searchable-menu-open::after {
      top: 10px;
    }
  }

  .ordersListing-table {
    .content-box {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
}

.ordersListing-table tbody tr td p.item-names {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 270px;
}

@media screen and (max-width:1199px){
  .orders__page__wrapper .order__filters__mobile__screens .search_modifier_group .search-btn, .orders__page__wrapper .order__filters__mobile__screens .input-group .search-btn, .orders__page__wrapper .order__filters__mobile__screens .custom-file .search-btn {
    top: 0px;
}
}

// Notification toast styling in Orders page
.toast-dimensions {
  width: 1024px !important;
  border-radius: 11.37px !important;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
  bottom: 8px !important;
  left: 50% !important;
  transform: translateX(-50%) !important;
  @media screen and (max-width:1024px){
    width: 720px !important;
  }
  @include bp(mobile){
    width: 95% !important;
  }
}

.notification_container {
  min-height: 65px !important;
  margin-bottom: 0.3125rem !important;
  align-items: center !important;
  padding-left: 30px !important;
  padding-right: 30px !important;
  @media screen and (max-width:1024px){
    padding: 0 12px 0 4px !important;
  }
}


.Toastify__toast-icon {
  margin-inline-end: 20px !important;
}

.toast-close-icon {
  height: 16.5px;
  width: 16.5px;
  @media screen and (max-width:1024px){
    height: 12px;
    width: 12px;
  }
}

.toast-text {
  font-family: Work Sans;
  font-size: 18px;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  @media screen and (max-width:1024px){
    font-size: 14px;
    line-height: 21px;
  }
}

.yellow-toast {
  background: rgba(255, 219, 94, 1) !important;
  color: rgba(101, 79, 0, 1) !important;
}

.red-toast {
  background: #FCE8DB !important;
  color: #71192F !important;
}

.green-toast {
  background: rgba(169, 213, 126, 1) !important;
  color: rgba(51, 96, 7, 1) !important;
}

.driver-status-text{
  @extend %font12-SemiBold;
  color: #6D7F8B;
  text-transform: uppercase;
}

.driver-status-timer{
  @extend %font15-semibold;
}

//--- Order listing row 3 dots loader
.loader {
  display: flex;
  justify-content: center;
}

.dot {
  background-color: #333;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  margin: 0 5px;
  animation: move 1s ease-in-out infinite;
}

@keyframes move {
  0%, 80%, 100% {
    background: #D9D9D9;
    transform: scale(0.5);
  }
  40% {
    background: #6D7F8B;
    opacity: 0.6;
    transform: scale(1);
  }
}

.dot1 {
  animation-delay: 0s;
}

.dot2 {
  animation-delay: 0.3s;
}

.dot3 {
  animation-delay: 0.6s;
}

//--- Orders filtering styling ---//
.order__filters__modal>.modal-dialog {
    width: 650px !important;
  }

.orders__filter__icon{
  height: 48px !important;
  width: 48px !important;
}

.orders__filters__width{
  max-width: 0px !important;
}

.filter__height{
  height: 48px !important;
}

.order__filters {
  .search_modifier_group,
  .input-group,
  .custom-file {
    height: 48px !important;
    margin-right: 0px;

    @include bp(mobile) {
        margin-bottom: 0px;
      }
  }

  .user__custom__select{
   .custom-select-ckc__control {
    height: 48px;
   }
  }
 
  .user__custom__select{
    @include bp(mobile){
      margin-bottom: 24px;
    }

  }

  .channel__wrapper{
  .dropdown-menu.show {
      padding-top: 0;
      padding-bottom: 0px;
      transform: translate(0px, 48px);
      border: none;
      z-index: 9;
  
      @include bp(mobile) {
        //width: 165px;
      }
    }
  
    .dropdown {
  
      .btn.btn-primary,
      .btn.btn-primary:active {
        background: transparent;
        padding: 12px 15px 13px;
        border-radius: 4px;
        border: solid 1px #6d7f8b;
        height: 48px;
        text-align: left;
        @extend %font15-medium;
        line-height: 1.53;
        letter-spacing: normal;
        color: #1b2236;
        width: 100%;
  
        /* @include bp(mobile) {
          height: 40px;
          padding-top: 8px;
          padding-bottom: 9px;
          width: 165px;
          margin-left: 16px;
        } */
  
        &:focus {
          box-shadow: none;
        }
  
        &::after {
          position: absolute;
          content: url("../../../assets/images/Icon-arrow-top-ckc.svg");
          width: 15px;
          height: 30px;
          top: 5px;
          bottom: 0;
          right: 15px;
          margin: auto;
          background: unset;
          border: none;
        }
      }
  
      .dropdown__action__wrapper {
        padding: 16px 11px 16px 16px;
        border-radius: 4px;
        box-shadow: 0 2px 13px 0 rgba(63, 74, 110, 0.1);
        background-color: #fff;
  
        .main__heading {
          @extend %font15-regular;
          line-height: 1.53;
          letter-spacing: normal;
          color: #3f4a6e;
  
          a {
            padding: 0;
          }
        }
  
        .sub__heading {
          padding-top: 16px;
          border-top: solid 1px #f1f1f1;
          @extend %font12-medium;
          text-transform: uppercase;
        }
  
        .custom-checkbox-container {
          @extend %font15-regular;
          line-height: inherit;
          letter-spacing: normal;
          color: #3f4a6e;
          padding-left: 24px !important;
          align-items: center;
        }
      }
    }
  
    .button__wrapper {
      text-align: left;
  
      .btn {
        width: 168px;
        height: 36px;
        padding: 5px 16px 7px 16px;
        border-radius: 4px;
        background-color: #ec380b;
        color: #fff;
        font-weight: 600;
  
        &:hover {
          box-shadow: 0 2px 10px 0 rgba(218, 0, 0, 0.17);
          background-color: #ec380b;
        }
  
        @include bp(mobile) {
          max-width: 100%;
        }
      }
    }
  }
}

// -- Full screen KDS mode for Order Listing --- //
.fullscreen__no__padding{
  padding: 0px !important;
}

.orders__fullscreen__icon{
  height: 48px;
  width: 48px;
  background-color: #fff;
  border: solid 1px #da0000 !important;
}
.orders__refresh__icon{
  height: 48px;
  width: 48px;
  background-color: #fff;
  border: solid 1px #da0000 !important;
  img{
    height: 24px;
    width: 24px;
  }
  @include bp(mobile) {
    height: 40px;
  }
}
.refresh__active{
  position: relative;
  &::before{
    content: "";
    position: absolute;
    width: 8px;
    height: 8px;
    border: solid 1px #fff;
    background-color: #ec380b;
    border-radius: 50%;
    top:6px;
    right:6px;
  }
}

.header__fullscreen__icon{
  height: 35px;
  width: 35px;
}

.filter__applied__fullscreen {
  &::before {
    top: 0px !important;
    right: 0px !important;
  }
}

.delivery__service__icon {
  height: 64px;
  width: 64px;
}

.details__mat__row{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.selct__page__dropdown {
  width: 136px;
  height: 48px;
  flex-shrink: 0;
}