.subheader {
  border-bottom: solid 1px #ececec;
  position: fixed;
  width: 100%;
  top: 70px !important;
  background: #fff;
  z-index: 999;

  h3 {
    font-family: 'Work Sans', sans-serif;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #161626;

    @media screen and (max-width: 767px) {
      font-size: 12px;
    }
  }
}

@media screen and (max-width: 767px) {
  .goBack {
    display: flex;
    align-items: center;
    button {
      display: flex;
      align-items: center;
      // padding-top: 5px;
    }
  }

  .back-btn-red{
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: -0.6px;
    color: #161626;

    .icons{
      margin-top: 0px;
      display: initial;
    }
  }
}
