@import "~bootstrap/scss/bootstrap";
@import "../../assets/scss/base/base-include.scss";


.conatctUs__wrapper{
    .modifierDetails__wrapper{
        padding-top: 0px;

        @include bp(mobile){
            padding-top: 0px !important;
        }
    }
}


.static__pages__wrapper{
    .main_heading{
        p{
          @extend %font15-regular;  
          line-height: 1.53;
          letter-spacing: normal;
          color: #6d7f8b90;
          margin-bottom: 0;
          padding-top: 4px;
        }
    }
}