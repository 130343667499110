@import "~bootstrap/scss/bootstrap";
@import "../../assets/scss/base/base-include.scss";

.browsefile {
  .custom-file-input {
    color: transparent;
    opacity: 1;
    background: #414a6b;
    width: 126px;
    text-align: center;
    border-radius: 4px;
    height: 40px;
  }
  .custom-file-input::-webkit-file-upload-button {
    visibility: hidden;
  }
  .custom-file-input::before {
    @extend %choosefile-btn;
    height: 100%;
    content: "Choose File";
    display: flex;
    justify-content: center;
    align-items: center;
    background: -webkit-linear-gradient(#414a6b);
    border: 1px solid #414a6b;
    border-radius: 3px;
    outline: none;
    white-space: nowrap;
    -webkit-user-select: none;
    cursor: pointer;
  }
  .custom-file-input:hover::before {
    border-color: #414a6b;
  }
  .custom-file-input:active {
    outline: 0;
  }
  .custom-file-input:active::before {
    background: -webkit-linear-gradient(#414a6b);
  }
}

.logo-image-wrapper {
  text-align: center;
  // padding: 57px 32px 56px;
  height: 385px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 4px;
  border: dashed 1px #414a6b;
  background-color: #fdfdff;

  &.logo-image-wrapper {
    max-width: 290px;

    @include bp(ipad) {
      width: 100%;
      max-width: 100%;
      height: 355px;
    }
  }
  .inner-content {
    > .icon {
      margin-bottom: 10px;
    }

    > p {
      @extend %font12-medium;
      color: #414a6b;
    }
  }

  .new-virtual-btn-create {
    @extend %font12-medium;
    padding: 13px 23px 12px 25px;
    border-radius: 4px;
    background-color: #414a6b;
    line-height: normal;
    border: 0;
    color: #fff;
    margin-top: 28px;
    display: inline-block;

    &:hover {
      text-decoration: none;
      box-shadow: 0 2px 10px 0 rgba(65, 74, 107, 0.17);
      background-color: #414a6b;
    }
  }
}

.multibrand__wrapper .logo-image-wrapper{
  min-height: 350px;
  height: 350px
}

.instructions {
  @extend %font12-regular;
  text-align: center;
  color: #c0c0c8;
  max-width: 226px;
  margin-left: auto;
  margin-right: auto;
}

.uploadAssets-wrapper {
  h3 {
    @extend %font21-semibold;
    margin-bottom: 16px;
  }
}
