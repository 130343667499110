@import "~bootstrap/scss/bootstrap";
@import "../../assets/scss/base/base-include.scss";

.rangeslider-horizontal {
  height: 8px;
}

.rangeslider-horizontal .rangeslider__fill {
  background-color: $theme-red;
  box-shadow: none;
}

.rangeslider-horizontal .rangeslider__handle {
  background-color: $theme-red;
  border: none;
  height: 20px;
  width: 20px;
  box-shadow: none;
}

.rangeslider-horizontal .rangeslider__handle:after {
  display: none;
}

.value {
  display: none;
}

.rangeslider,
.rangeslider .rangeslider__fill {
  box-shadow: none;
}

.rangeslider {
  background-color: #e6e6e6;
}


.rangeslider__handle-tooltip{
  font-size: 13px !important;
  width: 58px !important;
  height: 32px !important;
  padding: 0px 8px;
  border-radius: 4px !important;
  background-color: #323232 !important;
  top: -40px !important;
}