@import "~bootstrap/scss/bootstrap";
@import "../../assets/scss/base/base-include.scss";


.signIn__wrapper{
    background-color: #f8f9fa;
    // height: 90vh;
    padding-top: 80px;
    padding-bottom: 165px;
    @include bp(mobile){
        padding-bottom: 64px;
    }

    .loginHeading__wrapper{
        padding-bottom: 8px;
        h1{
            font-family: 'Work Sans', sans-serif; 
            font-weight: 700;
            font-size: 32px;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.25;
            letter-spacing: normal;
            color: #131414;

            @include bp(mobile){
                font-size: 21px;
                line-height: 1.43;
            }
        }

        p{
            @extend %font15-regular;
            color: #6d7f8b;
        }
    }
}

.signcard__wrapper{
    min-height: 500px;
    border-radius: 4px;
    
    .leftcard{
        padding: 32px;
        background: #fff;
        min-height: 500px;
        height: 100%;
        box-shadow: 0 5px 9px -3px rgba(0, 0, 0, 0.08);

        @include bp(mobile){
            padding: 20px 10px;
            height: auto;
        }
        
        .leftcard__inner{
            max-width: 471px;
        }

        .action__wrapper{
            max-width: 132px;

            @include bp(mobile){
                max-width: 100%;
            }

            img.icon{
                margin-left: 12px;
            }
        }
    }

    .rightside{
        height: 100%;
        .rightside__bg{
            padding: 126px 80px 90px 80px;
            box-shadow: 0 5px 9px -3px rgba(0, 0, 0, 0.08);
        }

        .content__box{
            max-width: 297px;
            display: flex;
            height: 100%;
            flex-direction: column;
            justify-content: space-between;

            h2{
                font-family: 'Work Sans', sans-serif;  
                font-weight: 700;
                font-size: 38px;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.21;
                letter-spacing: normal;
                color: #fff;
                margin-bottom: 48px;
            }

            .action__wrapper{
                max-width: 148px;
                margin-bottom: 75px;
            }

            .rightside__links{
                display: flex;
                align-items: center;

                .rightside__link{
                    @extend %font15-semibold;
                    color: #fff;

                    &:not(:last-child){
                        margin-right: 32px;
                    }
                }
            }
        }

        @include bp(mobile){
            height: auto;
        }
    }
}

form {
    label {
      @extend %font14-semibold;
      color: #131414;
      margin-bottom: 12px;
    }
    .form-control {
      box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.04);
      border: solid 1px #c0c0c9;
      background-color: #fff;
      border-radius: 4px;
      height: 48px;
      max-width: 471px;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.53;
      letter-spacing: normal;
      color: #131414;

      &:focus {
        border: solid 1px #414a6b;
      }

      &::placeholder,
      &::-moz-placeholder,
      &::-webkit-input-placeholder {
        color: #c0c0c9;
      }
    }

    textarea {
      min-height: 174px;
    }

    .input-icon {
      position: absolute;
      top: 40px;
      bottom: 0;
      right: 15px;
      cursor: pointer;
    }

    .prelogin-links {
      @extend %font12-medium;
      text-align: right;
      color: $theme-gray;
      width: auto;
      display: inline-block;

      &:hover {
        color: $theme-gray;
        text-decoration: none;
        color: $theme-red;
      }
    }

    .action__wrapper {
      button,
      .prelogin-links-btn {
        @extend %font15-semibold;
        width: 100%;
        background: $theme-red;
        color: #fff;
        border: 1px solid $theme-red;
        border-radius: 4px;
        height: 48px;
        display: inline-flex;
        justify-content: center;
        align-items: center;

        &:hover {
          text-decoration: none;
          box-shadow: 0 2px 10px 0 rgba(218, 0, 0, 0.17);
          background-color: #ec380b;
        }

        &.prelogin-links-outline-btn {
          background-color: unset;
          color: $theme-red;
        }
      }
      a{text-decoration: none;}
    }

    .rememberPass {
      padding-top: 24px;
      display: flex;
      justify-content: center;
      align-items: center;

      .prelogin-links {
        width: unset;
        display: unset;
        margin: unset;
        color: #414a6b;

        &.no-link {
          color: #c0c0c8 !important;
          margin-right: 5px;
        }
      }
    }
  }

  .about__business__wrapper{
    .custom-checkbox-wrapper {
        .custom-checkbox-container{
            @extend %font12-regular;
            line-height: 1.92;
            letter-spacing: normal;
            color: #131414;
            padding-left: 32px;
        }
    }
    .leftcard{
        .action__wrapper{
            margin-top: 36px;
            max-width: 210px !important;
            @include bp(mobile){
                max-width: 100% !important;
            }
        }
    }
  }

  .righside__mobile{
      text-align: center;

      .action__wrapper{
          max-width: 100% !important;
          margin-top: 24px !important;
          margin-bottom: 32px !important;
      }

      .content__box{
        max-width: 100% !important;
      }
      .or{
          margin: 32px 0px;
          p{
            @extend %font12-medium;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: #131414;
            position: relative;

            &::after{
                content: "";
                background-color: #c0c0c9;
                height: 1px;
                width: 60px;
                position: absolute;
                left: 60px;
                top: 0;
                bottom: 0;
                margin: auto;
            }

            &::before{
                content: "";
                background-color: #c0c0c9;
                height: 1px;
                width: 60px;
                position: absolute;
                right: 60px;
                top: 0;
                bottom: 0;
                margin: auto;
            }
          }
      }

      .rightside__links{
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 16px;

        .rightside__link{
            @extend %font15-semibold;
            color: #414a6b !important;

            &:not(:last-child){
                margin-right: 32px;
            }
        }
        }
  }

  .message__wrapper{
    background-color: #f8f9fa;
    padding: 48px 0px;
    height: 100vh;
    @include bp(mobile){
        height: auto;
    }

      .message__wrapper__inner{
        width: 100%;
        min-height: 410px;
        text-align: center;
        padding: 64px;
        border-radius: 4px;
        box-shadow: 0 5px 9px -3px rgba(0, 0, 0, 0.08);
        background-color: #fff;

        @include bp(mobile){
            padding: 20px 16px;
        }

        .img-box{
            margin-bottom: 32px;
        }
        .content-box{
            h1{
                font-family: 'Work Sans', sans-serif;  
                font-weight: 700;
                font-size: 32px;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.25;
                letter-spacing: normal;
                color: #131414;
                margin-bottom: 12px;
    
                @include bp(mobile){
                    font-size: 21px;
                    line-height: 1.43;
                }
            }
    
            p{
                @extend %font15-regular;
                color: #6d7f8b;
                opacity: 0.9;
                margin-bottom: 12px;
            }

            .action__wrapper{
                margin-top: 32px;
                button{
                    @extend %font16-semibold;
                    color: #fff;
                    min-width: 142px;
                    height: 48px;
                    padding: 13px 42px 14px 44px;
                    border-radius: 4px;
                    background-color: #ec380b;
                    outline: none;
                    border: 1px solid #ec380b ;
                }
            }
        }

      }
  }

  .signIn__bg{
      background: url('../../assets/images/signIn-banner-625.jpg') !important;
      box-shadow: 0 5px 9px -3px rgba(0, 0, 0, 0.08);
  }

  .aboutBusiness__bg{
    background: url('../../assets/images/aboutBusiness-bg.jpg') !important;
    box-shadow: 0 5px 9px -3px rgba(0, 0, 0, 0.08);
    height: inherit;
  }

  .rightside__bg.aboutBusiness__bg{
      .content__box{
          padding-top: 145px;
          padding-bottom: 40px;
      }
  }

  .terms-text{
      @extend %font12-medium;
      line-height: 1.92;
      letter-spacing: normal;
      color: #131414;
      padding-left:24px;

      a{
        color: #ec380b;
        text-decoration: underline;

        &:hover{
            color: #ec380b;
            text-decoration: none; 
        }
      }
  }