@import "~bootstrap/scss/bootstrap";
@import "../../../assets/scss/base/base-include.scss";

.Thankyou__wrapper {
  background-color: #f8f9fa;
  height: 80vh;

  .Thankyou__wrapper__inner {
    padding-top: 102px;

    @include bp(mobile) {
      padding-top: 40px;
    }
  }

  .thankyou__card {
    min-height: 410px;
    background: #fff;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    box-shadow: 0 5px 9px -3px rgba(0, 0, 0, 0.08);
    padding: 40px 0px;

    .img-box {
      margin-bottom: 32px;
    }
    .content {
      @include bp(xl) {
        max-width: 80%;
      }
      h1 {
        @extend %font32-bold;
        line-height: 1.25;
        letter-spacing: normal;
        color: #131414;
        margin-bottom: 12px;
      }

      p {
        @extend %font15-regular;
        line-height: 1.53;
        letter-spacing: normal;
        opacity: 0.9;
        color: #6d7f8b;
      }

      .btn-red {
        @extend %font16-semibold;
        width: 236px;
        height: 48px;
        padding: 15px;
        border-radius: 4px;
        background-color: #ec380b;
        color: #fff;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        margin-top: 32px;

        &:focus {
          box-shadow: none;
        }
      }
    }
  }
}
