@import "~bootstrap/scss/bootstrap";
@import "../../assets/scss/base/base-include.scss";

.reports__wrapper{

    .sections{
        .section__heading{
            margin-bottom: 16px;

            @include bp(mobile){
                margin-bottom: 32px;
            }
            h2{
                @extend%font25-bold;
                line-height: 1.32;
                letter-spacing: normal;
                color: #131414;

                @include bp(mobile){
                    font-size: 18px;
                }
            }
        }
    }
    .summary__card__wrapper{
        border-radius: 4px;
        box-shadow: 0 5px 9px -3px rgba(0, 0, 0, 0.08);
        background-color: #fff; 
        padding: 32px;

        @include bp(mobile){
            padding: 16px;
        }


        .summary__card__wrapper__inner{
            display: grid;
            grid-template-columns: repeat(3,1fr);
            grid-gap: 22px;

            @include bp(xl){
                grid-template-columns: repeat(3,1fr);  
                grid-gap: 22px;
            }

            @include bp(991){
                grid-template-columns: repeat(2,1fr);  
                grid-gap: 22px;
            }

            @include bp(mobile){
                grid-template-columns: repeat(2,1fr);  
                grid-gap: 16px;
            }

            .summary__card{
                padding: 20px 20px 18px;
                border-radius: 4px;
                background-color: #f6f9fb;
                min-height: 160px;

                .summary__card__data{
                    display: flex;
                    justify-content: space-between;
                    height: 100%;

                    @include bp(mobile){
                        flex-direction: column-reverse;
                    }

                    .summary__card__data__left{
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        justify-content: space-between;
                        p{
                            margin: 0;

                        }

                        .label{
                            @extend %font15-regular;
                            line-height: 1.53;
                            letter-spacing: normal;
                            color: #131414;

                            @include bp(mobile){
                                font-size: 12px;
                                line-height: 1.33;
                                margin-bottom: 8px;
                            }
                        }

                        .label__data{
                            @extend %font25-bold;
                            line-height: 1.32;
                            letter-spacing: normal;
                            color: #131414;

                            @include bp(mobile){
                                font-size: 21px;
                                line-height: 1.38;
                            }
                        }
                    }

                    .summary__card__data__right{

                        @include bp(mobile){
                            text-align: right;
                            margin-bottom: 10px;
                        }

                        .img-box{
                            width: 60px;
                            height: 60px;
                            box-shadow: 0 6px 9px 0 rgba(0, 0, 0, 0.1);
                            background-color: #fff;
                            border-radius: 50%;
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            @include bp(mobile){
                                width: 40px;
                                height: 40px;
                                margin-left: auto;
                            }
                        }
                    }
                }
            }
        }
    }

    .summary__restaurant__name{
        display: flex;
        align-items: center;
        padding-bottom: 24px;

        @include bp(mobile){
            padding-bottom: 20px;
        }

        .img-box{
            img{
                max-width: 32px;
            }
        }

        .content-box{
            margin-left: 10px;
            h5{
                font-family: 'Work Sans', sans-serif;
                font-size: 18px;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.44;
                letter-spacing: normal;
                color: #161626;
                margin-bottom: 0;
            }
        }
    }

    .reportdetails{
        margin-top: 64px;
        .summary__card__wrapper{
            &:not(:last-child){
                margin-bottom: 12px;

                @include bp(mobile){
                    margin-bottom: 32px;
                }
            }
        }
    }

    .reports__graph{
        margin-top: 32px;
        .reports__graph__wrapper{
            .reports__graph__wrapper__inner{
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-gap: 32px;

                @include bp(991){
                    grid-template-columns: repeat(1, 1fr);  
                }

                .reports__graph__card{
                    border-radius: 4px;
                    box-shadow: 0 5px 9px -3px rgba(0, 0, 0, 0.08);
                    background-color: #fff; 
                    padding: 40px 32px;
                }
            }
        }
    }

    .searchable-dropbox .searchable__dropdown__ckc__indicators::after{
        top: -6px;
    }

    .userselect button{background: transparent !important;}

    .user__custom__select{
        .userselect button{
            border: solid 1px #6d7f8b !important;
        }
    }

    .filters__apply__btn{
        width: 102px;
        height: 48px;
        padding: 12px 15px 10px 15px;
        border-radius: 4px;
        border: solid 1px #414a6b;
        background-color: #fff;
        @extend %font15-semibold;
        letter-spacing: normal;
        color: #414a6b;

        &:focus{
            box-shadow: none;
        }

        @include bp(mobile){
            width: 100%;
            height: 40px;
            padding: 8px 15px 9px 15px;
        }
    }

    .vertical-seperator{
        position: relative;
        width: 30px;
        height: 48px;
        
        &::after{
            content: "";
            position: absolute;
            height: 48px;
            width: 1px;
            background: #e0e0e0;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
        }
    }

    @media screen and (min-width:1200px) and (max-width:1320px) {
        .userselect {
            width: 180px;
        }

        .filters__apply__btn{
            width: 80px;
        }

        .export__wrapper .btn{
            width: 128px;
        }
    }

    @media screen and (min-width:1321px) and (max-width:1380px) {
        .userselect {
            width: 180px;
        }

        .filters__apply__btn{
            width: 80px;
        }

        .export__wrapper .btn{
            width: 128px;
        }
    }

    @media screen and (min-width:1440px) {
        .custom-datepicker .react-daterange-picker__wrapper{
            width: 300px;
        }

        .custom-datepicker .react-daterange-picker__range-divider {
            margin: 0 12px;
        }

        .userselect {
            width: 200px;
        }
    }

    .reports__filters__mobile__screens{
        @include bp(mobile){

            .userselect button{
                height: 40px;
            }
        }

        .userselect{
            width: auto;
        }

        .custom-datepicker .react-daterange-picker__range-divider {
            margin: 0 10% !important;
        }
    }

    .report-filter-mobile-one-col{
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-column-gap: 20px;
        grid-row-gap: 20px;
    }

    @include bp(mobile){
        .orders__page__wrapper .userselect .searchable-menu-close::after{
            top:16px;
        }

        .report-filter-mobile-two-col{
            grid-template-columns: repeat(1, 1fr);
            grid-row-gap: 20px;
        }
    }


    @media screen and (min-width:768px){
        .order__filters__mobile__screens .filters__hidden__options{
            margin: 0px;
        }
    }


    @media screen and (min-width:768px) and (max-width:1199px){
        .report-filter-mobile-one-col{
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-column-gap: 20px;
            grid-row-gap: 20px;
            align-items: flex-end
        }
    }

}