@import "~bootstrap/scss/bootstrap";
@import "../../../assets//scss/base/base-include.scss";

.basic__info {
  .main__heading {
    padding-bottom: 40px;

    @include bp(mobile) {
      padding-bottom: 24px;
    }

    h2 {
      @extend %font32-bold;
      margin-bottom: 12px;
      color: $heading-color;
      line-height: 1.25;

      @include bp(mobile) {
        font-size: 21px;
        margin-bottom: 6px;
      }
    }

    p {
      @extend %font15-regular;
      color: #6d7f8b;
      opacity: 0.9;
      margin-bottom: 0;
    }
  }
}

.rg_basic__info__content {
  background: white;
  padding: 25px 20px 25px 32px;

  .sub_heading {
    font-family: "Work Sans";
    font-style: "Bold";
    color: rgba(19, 20, 20, 1);
    font-size: 21px;
    font-weight: 700;
    line-height: 29px;
    letter-spacing: 0px;
    text-align: left;
  }

  .sub_heading1 {
    font-family: "Work Sans";
    font-size: 15px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0px;
    text-align: left;
    color: rgba(94, 94, 94, 1);
    margin-top: 5px;
  }

  label {
    @extend %font14-semibold;
    color: #131414;
    margin-bottom: 12px;
  }

  .form-control {
    // box-shadow: 0 6px 10px 0 rgba(13, 116, 134, 0.04);
    border: solid 1px #c0c0c8;
    background-color: #fff;
    border-radius: 4px;
    height: 52px;
    max-width: 503px;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.53;
    letter-spacing: normal;
    color: #131414;

    &:focus {
      border: solid 1px #414a6b;
    }

    &::placeholder,
    &::-moz-placeholder,
    &::-webkit-input-placeholder {
      color: #c0c0c9;
    }

    @include bp(xl) {
      font-size: 13px;
    }
  }

  textarea {
    min-height: 174px;
  }
}

.switches {
  .switch {
    position: relative;
    display: inline-block;
    width: 54px;
    height: 26px;
    margin-bottom: 5px !important;

    input {
      opacity: 0;
      width: 0;
      height: 0;

      &:checked+.slider {
        background-color: #fff;
        border-color: $theme-red;

        &:before {
          background-color: $theme-red;
        }
      }

      &:focus+.slider {
        box-shadow: 0 0 1px $theme-red;
      }

      &:checked+.slider:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
      }
    }

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #fff;
      border: 1px solid #c0c0c8;
      -webkit-transition: 0.4s;
      transition: 0.4s;

      /* Rounded sliders */
      &.round {
        border-radius: 34px;

        &:before {
          border-radius: 50%;
        }
      }

      &:before {
        position: absolute;
        content: "";
        height: 20px;
        width: 20px;
        left: 3px;
        bottom: 2px;
        background-color: #c0c0c8;
        -webkit-transition: 0.4s;
        transition: 0.4s;
      }
    }
  }
}

.rg_basic__info__content {
  .right-side-form-wrapper {
    background-color: #fff;
    border-radius: 8px;
    border: solid 1px #c0c0c8;
    height: 480px;
    width: 470px;

    @include bp(lg) {
      max-width: 95%;
    }

    .imgBox {
      height: 344px;
      border: none;

      @include bp(lg) {
        max-width: 100%;
      }

      .imgStyle {
        height: 344px;
        width: 470px;
        border: none;

        @include bp(lg) {
          max-width: 100%;
        }
      }
    }

    .item_name {
      font-family: "Work Sans";
      font-size: 21px;
      font-weight: 600;
      line-height: 28px;
      letter-spacing: 0px;
      color: rgba(19, 20, 20, 1);
      padding: 20px 0px 5px 0px;
      text-align: center;
    }

    .vb_name {
      font-family: "Work Sans";
      font-size: 15px;
      font-weight: 400;
      line-height: 13px;
      letter-spacing: 0px;
      text-align: center;
      color: rgba(119, 119, 120, 1);
    }
  }
}

.progress {
  border-radius: 10px;
  height: 8px;

  .progress-bar {
    background-color: $theme-red;
  }
}

.deliveryRadiuscount {
  font-family: 'Work Sans', sans-serif;
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3f4a6e;
  display: flex;
  justify-content: space-between;

  span {
      margin-top: 9px;
    }
}

.radius__value {
  font-size: 13px !important;
  width: 58px !important;
  height: 32px !important;
  padding: 0px 4px;
  border-radius: 4px !important;
  background-color: #414a6b !important;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    margin: 0px !important;
  }
}