@import "~bootstrap/scss/bootstrap";
@import "../../../assets/scss/base/base-include.scss";

.order__details__wrapper {
  overflow: hidden;
  overflow-y: scroll;
  .detailsCard__wrapper {
    border-radius: 4px;
    //box-shadow: 0 5px 9px -3px rgba(0, 0, 0, 0.08);
    background-color: #fff;

    .order-details-body-header {
      padding: 20px;
      //border-top-left-radius: 4px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      @include bp(mobile) {
        padding: 15px;
        border-top-right-radius: 4px;
      }
  }

  .status-text{
    > span {
      display: block;

      &:nth-of-type(1) {
        @extend %font12-medium;
        line-height: 1;
        color: #fff;
        text-transform: uppercase;
        margin-bottom: 5px;
      }

      &:nth-of-type(2) {
        @extend %font15-bold;
        line-height: 1.53;
        color: #fff;
      }
    }
  }

    .preparing {
      background-color: #fac101;
    }

    .cancelled {
      background-color: #797981;
    }

    .completed {
      background-color: #008bda;
    }

    .ready {
      background-color: #03b27f;
    }

    .pending {
      background-color: #e5870b;
    }

    .new {
      background-color: #e5870b;
    }

    .delivery {
      background-color: #9966cc;
    }

    .order-details-body-details {
      padding: 32px 30px;
      //border-right: 1px solid #dfe5ec;

      @include bp(mobile) {
        padding: 20px 15px;
        border-bottom: 1px solid #dfe5ec;
        border-right: 0 !important;
      }

      .body-heading {
        margin-bottom: 32px;
        @include bp(mobile) {
          margin-bottom: 20px;
        }
        h2 {
          @extend %font21-semibold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.38;
          letter-spacing: normal;
          color: #131414;
          margin-bottom: 0;
        }
      }

      .order-detail-card {
        padding-bottom: 5px;

        @include bp(mobile) {
          padding-bottom: 0px;
        }

        label {
          @extend %font12-bold;
          line-height: 1.67;
          letter-spacing: normal;
          color: #6d7f8b;
          text-transform: uppercase;

          @include bp(mobile) {
            margin-bottom: 4px;
          }
        }

        p {
          @extend %font15-regular;
          line-height: 1.53;
          letter-spacing: normal;
          color: #131414;
          white-space: pre-wrap;
        }
      }
    }


    .order-detail-error-card {
      padding: 12px 16px 0 12px;
      label {
        @extend %font12-bold;
        font-size: 14px;
        line-height: 1.52;
        letter-spacing: normal;
        text-transform: uppercase;
        margin-bottom: 0;
      }

      p {
        @extend %font15-regular;
        font-size: 12px;
        line-height: 1.53;
        letter-spacing: normal;
        color: #131414;
        white-space: pre-wrap;
        margin: 0;
      }
    }

    .logo__area {
      text-align: center;
      padding-top: 32px;
      padding-bottom: 16px;

      .img-box {
        margin-bottom: 12px;
        img {
          width: 64px;
        }
      }

      .content-box {
        h3 {
          @extend %font18-bold;
          line-height: 1.44;
          letter-spacing: normal;
          color: #131414;
          margin-bottom: 0;
        }

        .order-no {
          @extend %font12-medium;
          line-height: 1;
          letter-spacing: normal;
          color: #6d7f8b;
        }
      }
    }

    .items__price__breakups {
      padding: 0px 32px 0px 30px;
      overflow-y: auto;
      height: 600px;
      margin-bottom: 30px;

      @include bp(mobile) {
        padding-left: 15px;
        padding-right: 15px;
        padding-bottom: 30px;
      }

      /* @media screen and (min-width: 767px) and (max-width:991px) {
        padding-left: 15px;
        padding-right: 15px;
        padding-bottom: 30px;
      } */

      .main__heading {
        margin-bottom: 20px;
        label {
          margin-bottom: 0;
        }
      }

      label {
        @extend %font12-bold;
        line-height: 1.67;
        letter-spacing: normal;
        color: #6d7f8b;
        text-transform: uppercase;
      }

      .item__block {
        &:not(:last-child) {
          border-bottom: 2px solid #dfe5ec;
        }

        .item {
          padding: 12px 0px;
          display: flex;
          justify-content: space-between;

          > span {
            @extend %font15-medium;
            line-height: 1.53;
            letter-spacing: normal;
            color: #1b2236;

            &:nth-of-type(2) {
              @extend %font15-bold;
              color: #131414;
            }
          }
        }

        .inputTextField{
          width: 192px;
          @extend %font12-regular;
          @include bp(mobile) {
            width: 140px !important;
          }
        }
      
        .inputPriceField{
          width: 72px;
          @extend %font12-medium;
          color: rgba(236, 56, 11, 1);
          &::placeholder{
            color: rgba(236, 56, 11, 1) !important;
            @extend %font12-medium;
          } 
           @include bp(mobile) {
            width: 65px !important;
          }
        }

        @media screen and (min-width: 991px) and (max-width:1300px) {
           .inputTextField{
            max-width: 152px;
           }
           .inputPriceField{
           max-width: 60px;
           }
          }
          @media screen and (min-width: 767px) and (max-width:991px) {
             .inputTextField{
            width: 100px;
             }
              .inputPriceField{
              width: 40px;
              }
          }

        .currency-code{
          @extend %font12-medium;
          color: rgba(236, 56, 11, 1);
          position: relative;
          right: -25px;
        }

        .inputClass{
          input{
            border: 1px solid rgba(192, 192, 201, 1);
            border-radius: 4px;
            padding-left: 30px;
            height: 36px !important;
          }
          ::placeholder{
            @extend %font12-regular;
          }   
        }

        .item__modifiers__wrapper {
          .item-modifiers {
            display: flex;
            justify-content: space-between;
            margin-bottom: 8px;

            > span {
              @extend %font12-regular;
              line-height: 1.53;
              letter-spacing: normal;
              color: #161626;

              &:nth-of-type(2) {
                @extend %font12-medium;
                color: #131414;
              }
            }
          }
        }

        .errorCharges {
          color: rgba(236, 56, 11, 1) !important;
        }
        .positive__error__charges{
          color: rgba(3, 178, 127, 1) !important;
        }

        .deleteitem_icon{
          height: 20px;
          width: 20px;
          border: none;
          background: none;
          margin-top: 3px;
          margin-left: 3px;
        }

        .item__notes {
          padding-top: 8px;

          label {
            margin-bottom: 2px;
          }

          p {
            @extend %font15-regular;
            line-height: 1.53;
            letter-spacing: normal;
            color: #131414;
          }
        }
      }
      .btnClass{
        margin-top: 50px;
       .saveBtn {
          width: 100% !important;
          border: none;
          margin-bottom: 15px;
          @extend  %font15-semibold;
        }
        .cancelBtn{
          width: 100% !important;
          @extend  %font15-semibold;
        }
     }
    }

                .orderstatus__wrapper .saveAsDraft__btn>.btn {
                  height: 40px;
                  color: #1b2236;
        
                  /*  @include bp(mobile) {
                    height: 38px;
                    padding: 10px 15px;
                  } */
                }
        
                .orderstatus__wrapper .saveAsDraft__btn button#dropdown-split-basic::after {
                  background: url(../../../assets/images/select-arrow-icon.svg) center no-repeat;
                  background-size: 14px;
                }
        
                .orderstatus__wrapper .saveAsDraft__btn button#dropdown-split-basic {
                  padding-top: 14px;
        
                  @include bp(mobile) {
                    padding-top: 12px;
                  }
                }
        
                .orderstatus__wrapper .saveAsDraft__btn__gray {
                  border: solid 1px #6d7f8b;
        
                  >button {
                    background: transparent !important;
                  }
                }
        
                .orderstatus__wrapper {
                  max-width: 50px;
                  width: 50px;
                  margin-left: auto;
        
                  @include bp(mobile) {
                    max-width: 100%;
                  }
        
                  .saveAsDraft__btn {
                    border: solid 1px #fff;
                    @extend %font15-semibold;
                    width: 50px;
                    height: 40px;
                    padding: 15px 15px 13px 15px;
                    border-radius: 4px;
                    color: #fff;
                    text-align: left;
                    background: none;
                    display: flex;
                    display: -webkit-flex;
                    justify-content: space-between;
                    align-items: center;
        
                    @include bp(mobile) {
                      width: 100%;
                      flex: 1;
                    }
        
                  /*   &:hover {
                      background: #fff;
                      color: $theme-red;
                      border: solid 1px #da0000;
                    } */
        
                   /*  &:focus {
                      box-shadow: none;
                      background: #fff;
                      color: $theme-red;
                      border: solid 1px #da0000;
                    } */
        
                  /*   &:focus-visible {
                      box-shadow: none;
                      background: #fff;
                      color: $theme-red;
                      border: solid 1px #da0000;
                    } */
        
                   /*  &:active {
                      box-shadow: none;
                      background: #fff;
                      color: $theme-red;
                      border: solid 1px #da0000;
                    } */
        
                    &::after {
                      content: "";
                      background: url("../../../assets/images/arrow-white-dropdown.svg") no-repeat !important;
                      background-size: contain;
                      border: none;
                      height: 12px;
                      width: 16px;
                      background-position: bottom;
                      vertical-align: bottom;
                    }
        
        
                    >button {
                      @extend %font15-semibold;
                      height: 48px;
                      padding: 12px 15px 13px;
                      border-radius: 4px;
                      border: 0;
                      color: $theme-red;
                      text-align: left;
                      //background-color: #fff;
                      background-color: none;
        
                      &:focus {
                        box-shadow: none;
                      }
        
                      &:hover {
                        background: #fff;
                      }
                    }
        
                   /*  .btn-primary:not(:disabled):not(.disabled):active {
                      background-color: #fff;
                      border-color: #fff;
                      color: $theme-red;
                    } */
        
                    .btn-primary:not(:disabled):not(.disabled):focus {
                      box-shadow: none;
                    }
        
                    button#dropdown-basic {
                      width: auto;
                      border-left: 0;
                      border-color: $theme-red;
                      background-color: none;
        
                      @include bp(mobile) {
                        flex: 0;
                      }
        
                      &:focus {
                        box-shadow: none;
                      }
        
                      &::after {
                        content: "";
                        background: url("../../../assets/images/arrow-red-dropdown.svg") no-repeat;
                        background-size: contain;
                        border: none;
                        height: 16px;
                        width: 16px;
                        background-position: bottom;
                      }
                    }
        
                    .show>.btn-primary.dropdown-toggle {
                      color: #fff;
                      background-color: $theme-red;
                      border-color: $theme-red;
                      box-shadow: none;
                    }

                  }

                  .btn-primary:not(:disabled):not(.disabled):active,
                  .show>.btn-primary.dropdown-toggle {
                    box-shadow: none;
                    background: none;
                    color: #fff;
                    border: solid 1px #fff;
                  }

                  .dropdown-menu.show {
                    transform: translate(0px, 50px) !important;
                    border-radius: 4px;
                    box-shadow: 0 2px 13px 0 rgba(63, 74, 110, 0.1);
                    background-color: #fff;
                    border: 0;
                    padding: 12px;
                    width: 180px;

                    @include bp(mobile) {
                      width: 150px;
                    }
                  }
                  .dropdown-item {
                    @extend %font15-regular;
                    color: #3f4a6e;
                    height: 36px;
                    display: flex;
                    align-items: center;
                    padding: 6.5px 10px 6.5px 10px;
                    border-radius: 4px;
    
                    &:hover {
                      background: rgba(236, 56, 11, 0.07);
                      color: #ec380b;
                    }

                    @include bp(mobile) {
                      font-size: 12px;
                      padding: 5px 10px 5px 10px;
                    }
                  }
                }
        
                @media screen and (max-width: 575px) {
                  .orderstatus__wrapper.dropdown-item {
                    font-size: 12px;
                    color: #ec380b;
                  }
                }
        
        
                .orderstatus__wrapper .btn-primary.focus,
                .orderstatus__wrapper .btn-primary:focus {
                  box-shadow: none !important;
                }
        
                }

                .timerStyle {
                  font-family: 'Work Sans', sans-serif;
                  font-size: 18px;
                  font-weight: 600;
                }     
                
                .wrapper {
                  width: 300px;
                  font-family: 'Helvetica';
                  font-size: 14px;
                }
                
                .StepProgress {
                  position: relative;
                  padding-left: 80px;
                  list-style: none;

                  .span{
                    position: "relative"
                  }

                  span::before {
                    display: inline-block;
                    content: '';
                    position: absolute;
                    top: 0;
                    left: -29.5px;
                    width: 10px;
                    height: 100%;
                    border-left: 1px solid #C0C0C8 !important;
                    //opacity: 100%;
                  }

                  :last-of-type span::before{
                    border-left: none !important;
                  }

                  &-item {
                    position: relative;
                    counter-increment: list;
                    
                    &:not(:last-child) {
                      padding-bottom: 30px;
                    }
                    
                    &::before {
                      display: inline-block;
                      content: '';
                      position: absolute;
                      left: -30px;
                      height: 100%;
                      width: 10px;
                    }
                    
                    &::after {
                      content: '';
                      display: inline-block;
                      position: absolute;
                      top: 0;
                      left: -37px;
                      width: 15px;
                      height: 15px;
                      border: 1px solid rgba(63, 74, 110, 0.3);
                      border-radius: 50%;
                      background-color: #FFF;
                    }
                    
                    &.is-done {
                      &::before {
                      }
                      &::after {
                        content:url("../../../assets/images/status-completed-icon.svg");
                        font-size: 10px;
                        color: #FFF;
                        text-align: center;
                        object-fit: contain;
                      }
                    }
                    
                    &.current {
                      &::before {
                      }
                      
                      &::after {
                       content:url("../../../assets/images/status-current-icon.svg");
                        width: 15px;
                        height: 15px;
                        font-size: 14px;
                        text-align: center;
                      }
                    }
                  }
                  
                  .title {
                    display: block;
                    @extend %font15-semibold;
                    text-transform: uppercase;
                  }

                  .subTitle1 {
                    font-family: 'Work Sans';
                    font-size: 10px;
                    font-weight: 700;
                    line-height: 20px;         
                    color: #6D7F8B;
                    opacity: 0.8 !important;
                    text-transform: uppercase;
                  }

                  .subTitle2 {
                    @extend %font12-medium;
                    color: rgba(109, 127, 139, 1);
                  }
                }
                .order__details__action{
                  display: flex;
                  justify-content: space-between;
                  justify-content: flex-end;
                  margin-right: 15px;
                  margin-top: 15px;
                  overflow: auto;
                }

                @media screen and (min-width: 1280px) and (max-width: 1700px) {
                  white-space: nowrap;
              }

              .order__status__flex {
                display: flex;
                align-items: center;
                gap: 25px;
              }
                            
}

div#order__details__modal .modal-content {
  padding: 0px !important;
}

 .border__right {
   border-right: 1px solid #dfe5ec;
 }


 .close_btn_grey{
    position: absolute;
    right: 8px;
    top: 0;
    img{
      height: 10px;
      width: 10px;
    }
 }