@import "~bootstrap/scss/bootstrap";
@import "../../assets/scss/base/base-include.scss";

.bg-blue-btn {
  font-family: 'Work Sans', sans-serif;
          font-weight: 600;
  border: none;
  font-size: 15px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  padding: 14px 40px 14px 41px;
  border-radius: 4px;
  background-color: #414a6b;
  height: 48px;

  &:hover {
    text-decoration: none;
    box-shadow: 0 2px 10px 0 rgba(65, 74, 107, 0.17);
  }
}

.button__wrapper {
  margin-left: auto;
}
