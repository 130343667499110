@import "~bootstrap/scss/bootstrap";
@import "../../../assets/scss/base/base-include.scss";

.edit__restaurant {
  .detailCard {
    .shared__vbrands {
      p {
        margin-bottom: 0px;
      }
    }
  }
}
