// @import "./assets/fonts/Approach/stylesheet.scss";
@import "~bootstrap/scss/bootstrap";
@import "./assets/scss/base/base-include.scss";

// providing global styling  here

* {
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
  @include bp(mobile) {
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
  }
  @media screen and (max-width:768px){
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: 'Work Sans', sans-serif !important;                                                  
  transition: background-color 0.5s;
  @include bp(mobile) {
    padding: 0 !important;
    margin: 0 !important;
    overflow-x: hidden !important;
    scroll-behavior: smooth;
    width: auto !important;
  }
  @media screen and (max-width:768px){
    padding: 0 !important;
    margin: 0 !important;
    scroll-behavior: smooth;
    width: auto !important;
    overflow-x: hidden !important;
  }
}

body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  font-weight: 400;
  text-rendering: optimizeLegibility;
}

.body-overflow{
  @include bp(mobile) {
    padding: 0 !important;
    margin: 0 !important;
    scroll-behavior: smooth;
    width: auto !important;
    overflow: hidden !important;
  }
}

@-moz-document url-prefix() {
  body {
    font-weight: lighter;
  }
}

li {
  list-style-type: none;
}

a {
  text-decoration: none;
}

button:disabled,
a:disabled {
  opacity: 0.65;
  cursor: not-allowed;
  pointer-events: none;
}

.disable-link {
  pointer-events: none;
  opacity: 0.65;
  cursor: not-allowed;
}
.enable-link {
  pointer-events: all;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  border-color: #dc3545 !important;
  background-image: none !important;
}

::placeholder {
  color: #c0c0c9 !important;
  @include bp(mobile) {
    font-size: 12px;
  }
}

.form-control:focus {
  box-shadow: none !important;
}
.form-control::placeholder {
  @extend %font15-regular;
  color: #c0c0c9 !important;

  @include bp(mobile) {
    font-size: 12px;
  }
}

.container {
  padding-left: 16px !important;
  padding-right: 16px !important;

  @include bp(mobile) {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
}

@media (min-width: 1400px) {
  .container {
    max-width: 1250px !important;
    padding: 0px !important;
  }
  .container1{
    width: 800px !important;
    padding: 0px !important;
  }
  .container2{
    width: 450px !important;
    padding: 0px !important;
  }
  .orders__container{
    max-width: 1375px !important;
  }
}

.row {
  margin-left: -16px !important;
  margin-right: -16px !important;
}

[class*="col-"] {
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.goBack {
  float: left;
}

.back-btn-red {
  font-family: 'Work Sans', sans-serif;
  font-weight: 600;
  font-size: 15px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ec380b;
  cursor: pointer;
  text-decoration: none;
  background: transparent;
  border: unset;

  &:hover {
    color: #ec380b;
    text-decoration: none;
  }

  span {
    margin-right: 12px;
    display: inherit;
  }
}

.custom__badge {
  padding: 8px 15.5px 10px 15.5px;
  border-radius: 4px;
  font-family: 'Work Sans', sans-serif;
  font-weight: 600;
  font-size: 10px;
  font-weight: 600;
  height: 36px;
}

.status__badge {
  padding: 10px 36px 11px 37px;
  border-radius: 18px;

  @include bp(mobile) {
    padding: 8px 34px 9px;
  }
}

.badge__gray {
  color: $theme-gray;
  background-color: #e9e9e9;
}

.badge__blue {
  color: $theme-blue;
  background-color: rgba(0, 139, 218, 0.06);
}

.badge__yellow {
  color: $theme-yellow;
  background: rgba(245, 183, 0, 0.1);
}

.badge__red {
  color: $theme-red;
  background-color: rgba(236, 56, 11, 0.07);
}

.no-gutters {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

select.form-control {
  /* reset */
  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: #f6f9fb url(./assets/images/select-arrow-icon.svg) no-repeat 92%;
}

select.form-control:focus {
  box-shadow: none;
}

.nav.nav-tabs {
  background: #fff;

  .nav-item {
    .nav-link {
      border: 0;
      color: $theme-gray;
      @extend %font18-regular;
      line-height: normal;
      padding: 16px 0px !important;
      border-bottom: 3px solid transparent;

      &.active {
        color: $theme-red;
        border-color: $theme-red;
        @extend %font18-bold;

        @include bp(mobile) {
          font-size: 15px;
        }
      }

      @include bp(mobile) {
        font-size: 15px;
      }
    }

   /*  &:not(:last-child) { */
      .nav-link {
        margin-right: 40px;
      }
    //}
  }
}

.modal-header {
  background-color: #fff;
  border: 0 !important;
}

.skip__link {
  @extend %font15-medium;
  color: #414a6b;
  text-decoration: none;
  margin-right: 30px;
  border: unset;
  background: transparent;

  &:hover {
    color: $theme-red;
  }
}

.red-btn {
  @extend %font16-semibold;
  color: #fff;
  line-height: 1.25;
  background: $theme-red;
  width: 150px;
  height: 48px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.confirm-red-btn {
  @extend %font16-semibold;
  color: #fff;
  line-height: 1.25;
  background: $theme-red;
  width: 220px;
  height: 48px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.confirm-red-btn:hover {
  color: #fff;
  text-decoration: none;
  box-shadow: 0 2px 10px 0 rgba(218, 0, 0, 0.17);
}

.red-btn:hover {
  color: #fff;
  text-decoration: none;
  box-shadow: 0 2px 10px 0 rgba(218, 0, 0, 0.17);
}

.red-btn-ghost {
  @extend %font16-semibold;
  color: $theme-red;
  height: 48px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border-color: $theme-red;

  &:hover {
    box-shadow: 0 2px 10px 0 rgba(218, 0, 0, 0.17);
  }
}

.modal-footer,
.modal-header {
  border: 0px !important;
  padding: 0 !important;
}

.mendatory {
  color: $theme-red;
}

.modal-content {
  border: 0px !important;
  .close {
    position: absolute;
    top: 30px;
    right: 30px;
    color: #6d7f8b !important;
    font-size: 30px;
    opacity: 1;
    font-weight: lighter;

    span:first-child {
      font-size: 0;
      content: "";
      background: url("./assets/images/modal-close-icon.svg") no-repeat;
      height: 18px;
      width: 18px;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
    }

    @include bp(mobile) {
      top: 30px;
    }
  }
}

// custom radio button
/* The container */
.check-container {
  display: block;
  position: relative;
  padding-left: 24px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  // min-width: 40px;
  margin-right: 24px;
  min-width: 100px;
}

/* Hide the browser's default radio button */
.check-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 14px;
  width: 14px;
  background-color: #fff;
  border: 1px solid red;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.check-container:hover input ~ .checkmark {
  background-color: #fff;
}

/* When the radio button is checked, add a blue background */
.check-container input:checked ~ .checkmark {
  background-color: #fff;
  border: 1px solid red;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.check-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.check-container .checkmark:after {
  top: 2px;
  left: 2px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: $theme-red;
}

// custom checkbox

.custom-checkbox-wrapper {
  /* The container */
  .custom-checkbox-container {
    display: block;
    position: relative;
    padding-left: 16px;
    margin-bottom: 0px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  /* Hide the browser's default checkbox */
  .custom-checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 3px;
    left: 0;
    height: 16px;
    width: 16px;
    background-color: #fff;
    border: solid 1px #414a6b;
    border-radius: 2px;
  }

  /* On mouse-over, add a grey background color */
  .custom-checkbox-container:hover input ~ .checkmark {
    background-color: #fff;
    border: solid 1px #414a6b;
  }

  /* When the checkbox is checked, add a blue background */
  .custom-checkbox-container input:checked ~ .checkmark {
    background-color: #fff;
    border: solid 1px $theme-red;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .custom-checkbox-container input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .custom-checkbox-container .checkmark:after {
    top: 1px;
    left: 5px;
    width: 5px;
    height: 10px;
    border: solid $theme-red;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

.check__wrapper {
  display: flex;
}

.accordion {
  .card-header {
    padding: 16px;

    button {
      padding: 0;

      &:focus {
        box-shadow: none;
      }
    }
  }
  .card-body {
    padding: 16px;
  }
}

/* modal backdrop fix */
// .modal:nth-of-type(even) {
//   z-index: 1052 !important;
// }
// .modal-backdrop.show:nth-of-type(even) {
//   z-index: 1051 !important;
// }

a.bg-blue-btn:hover {
  color: #fff;
  text-decoration: none;
}

.centered {
  > .modal-dialog {
    @include bp(ipad-min) {
      max-width: 610px;
    }

    > .modal-content {
      @include bp(mobile) {
        padding: 32px;
      }
    }
  }

  .modal-footer {
    justify-content: center;
  }
}

.pagination {
  justify-content: flex-end;
  @include bp(mobile) {
    justify-content: center;
  }
  .page-link {
    @extend %font12-medium;
    border: none;
    color: #6d7f8b;
    line-height: 1;
    padding: 6px 8px 6px 9px;
    background-color: transparent;
    margin: 0px 6px;

    @include bp(mobile) {
      margin: 0px 2px;
    }

    @include bp(360) {
      padding: 5px;
    }

    &:hover {
      background-color: unset;
    }

    .arrow {
      position: relative;
      width: 12px;
      display: inline-block;

      &::before {
        content: "";
        position: absolute;
        background-position: center;
        background-repeat: no-repeat;
        width: 12px;
        height: 12px;
        background-size: contain;
        right: 0;
        top: -10px;
      }
    }
  }

  .page-item {
    &:first-child {
      .page-link {
        color: #131414;
        .arrow {
          margin-left: 22px;
          &::before {
            background-image: url("./assets/images/pagination-arrow-active-right.svg");
            // transform: rotate(180deg);
          }
        }
      }
    }

    &:last-child {
      .page-link {
        color: #131414;
        .arrow {
          margin-right: 22px;
          &::before {
            background-image: url("./assets/images/pagination-arrow-active-right.svg");
          }
        }
      }
    }
    .page-link {
      background-color: transparent;
      border: none;
    }
  }

  .page-item.disabled {
    &:first-child {
      .page-link {
        color: #6d7f8b;
        .arrow {
          &::before {
            background-image: url("./assets/images/pagination-arrow-disabled.svg");
            transform: unset;
          }
        }
      }
    }

    &:last-child {
      .page-link {
        color: #6d7f8b;
        .arrow {
          &::before {
            background-image: url("./assets/images/pagination-arrow-disabled.svg");
            // transform: rotate(180deg);
          }
        }
      }
    }
    .page-link {
      background-color: transparent;
      border: none;
    }
  }

  .page-item.active {
    .page-link {
      border-radius: 4px;
      background-color: #3f4a6e;
      border-color: #3f4a6e;
      color: #fff;
    }
  }
}

.threeDotsBropdown.drop-icon
  #dropdown-button-drop-left[aria-expanded="false"]::before {
  background: #fff url("./assets/images/drop-icon.svg") no-repeat;
  width: 24px;
  height: 24px;
  background-size: contain;
  background-position: center;
  margin: 0;
}

.threeDotsBropdown.drop-icon
  #dropdown-button-drop-left[aria-expanded="true"]::before {
  background: #fff url("./assets/images/drop-icon-open.svg") no-repeat;
  width: 24px;
  height: 24px;
  background-size: contain;
  background-position: center;
  margin: 0;
}

.threeDotsBropdown.drop-icon {
  .dropdown-menu {
    width: 200px;
    top: 36px !important;
    left: 36px !important;
  }
}

.threeDotsBropdown.drop-icon {
  .dropdown-menu {
    z-index: 1;
  }
}

.threeDotsBropdown {
  #dropdown-button-drop-left {
    background: #fff;
    border: none;
    padding: 0;

    &::before {
      background: #fff url("./assets/images/icon-three-dots.svg") no-repeat;
      border: none;
      height: 18px;
      width: 5px;
    }

    &:focus {
      box-shadow: none;
      outline: none;
    }
  }

  .dropdown-menu {
    padding: 11px 12px;
    border-radius: 4px;
    box-shadow: 0 2px 13px 0 rgba(63, 74, 110, 0.1);
    background-color: #fff;
    border: none;
    width: 225px;

    .dropdown-item {
      padding: 6px 87px 7px 12px;
      border-radius: 4px;
      background-color: #fff;
      color: #6d7f8b;
      @extend %font15-medium;
      line-height: 1.53;

      &:hover,
      &:focus {
        background-color: rgba(236, 56, 11, 0.07);
        color: #ec380b;
      }
    }
  }
}

.modal-content {
  border-radius: 0px !important;
}

.modal-backdrop {
  background-color: #414a6b !important;
}

.modal-backdrop.show {
  opacity: 0.83 !important;
}

.placeholder {
  display: inline-block;
  height: 1.25em;
  vertical-align: middle;
  background-color: $theme-gray;
  opacity: 0.5;
  width: 30%;
  margin-bottom: 0;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.pagination {
  li {
    .page-item[role="button"] {
      text-decoration: none;
      color: inherit;

      &:hover {
        color: inherit;
        text-decoration: none;
      }
    }
    &:first-child {
      a {
        position: relative;
        padding-right: 40px;

        @include bp(mobile) {
          padding-right: 24px;
        }

        &:after {
          position: absolute;
          content: "";
          background: transparent
            url("./assets/images/paginatin-arrow-right-active.svg") no-repeat;
          border: none;
          height: 14px;
          width: 14px;
          background-size: contain;
          margin-left: 8px;
          transform: rotate(180deg);
          right: 4px;
        }
      }
    }

    &:last-child {
      a {
        position: relative;
        padding-left: 40px;

        @include bp(mobile) {
          padding-left: 18px;
        }

        &::before {
          position: absolute;
          content: "";
          background: transparent
            url("./assets/images/paginatin-arrow-right-active.svg") no-repeat;
          border: none;
          height: 14px;
          width: 14px;
          background-size: contain;
          left: 0;
          margin-right: 8px;
        }
      }
    }
  }

  li.page-item.disabled {
    &:first-child {
      a {
        position: relative;
        padding-right: 40px;

        @include bp(mobile) {
          padding-right: 24px;
        }

        &:after {
          position: absolute;
          content: "";
          background: transparent
            url("./assets/images/paginatin-arrow-left-deactive.svg") no-repeat;
          border: none;
          height: 14px;
          width: 14px;
          background-size: contain;
          margin-left: 8px;
          transform: unset;
          right: 4px;
        }
      }
    }

    &:last-child {
      a {
        position: relative;
        padding-right: 40px;

        @include bp(mobile) {
          padding-right: 24px;
        }

        &:after {
          position: absolute;
          content: "";
          background: transparent
            url("./assets/images/paginatin-arrow-left-deactive.svg") no-repeat;
          border: none;
          height: 14px;
          width: 14px;
          background-size: contain;
          margin-left: 8px;
        }
      }
    }
  }
}

.edit-btn {
  background: none;
  border: none;
  outline: none;
  margin-left: 12px;
}

.delete-btn {
  background: none;
  border: none;
  outline: none;
  align-items: center;
}

.delete-option {
  color: #414a6b;
  border: none;
  background: none;
  outline: none;
  @extend %font15-medium;
  line-height: 1.33;
  letter-spacing: normal;
  margin-right: auto;
  flex: 1;
  text-align: left;

  &:hover {
    color: $theme-red;
  }
}

.threeDotsBropdown-menu.threeDotsBropdown.drop-icon .dropdown-menu {
  left: auto !important;
  right: -24px !important;
}

.nothing__card__wrapperInner {
  max-width: 350px;
  margin: auto;
  text-align: center;
  padding: 60px 0px;

  .img__box {
    margin-bottom: 24px;
  }

  .content__box {
    h2 {
      font-family: 'Work Sans', sans-serif;
      font-weight: 600;
      font-size: 31px;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #161626;

      @include bp(mobile) {
        font-size: 21px;
        line-height: 1.43;
      }
    }

    p {
      @extend %font15-regular;
      color: #949494;

      .text__highlighted {
        color: #414a6b;
      }
    }
  }
}

.red-button-filled-hover:hover {
  box-shadow: 0 2px 10px 0 rgba(218, 0, 0, 0.17);
  background-color: #ec380b;
  text-decoration: none;
}

.red-button-outlined-hover:hover {
  box-shadow: 0 2px 10px 0 rgba(218, 0, 0, 0.17);
  border: solid 1px #da0000;
  text-decoration: none;
}

.blue-button-filled-hover:hover {
  box-shadow: 0 2px 10px 0 rgba(65, 74, 107, 0.17);
  background-color: #414a6b;
  text-decoration: none;
}

#confirmation__modal {
  z-index: 1053 !important;
  box-shadow: 10px 11px 0px 1200px rgba(65, 74, 107, 0.85);
  -webkit-box-shadow: 10px 11px 0px 1200px rgba(65, 74, 107, 0.85);
  -moz-box-shadow: 10px 11px 0px 1200px rgba(65, 74, 107, 0.85);
  background: rgba(65px, 74px, 107px, 0.85);
  max-width: 610px;
}

#confirmation__modal {
  .modal-footer {
    margin-top: 40px;
  }

  .modal-body {
    p {
      @include bp(mobile) {
        font-size: 15px;
      }
    }
  }
}

.card {
  border: solid 1px #c0c0c8;
}

@include bp(mobile) {
  .modal-title {
    h1 {
      font-size: 21px !important;
    }
  }
}
.subheader.non-auth-subheader-desktop {
  top: 60px !important;
}

.addModifiers {
  .icons {
    margin-right: 12px;
  }
}

// @include bp(mobile) {
//   .subheader.non-auth-subheader-desktop {
//     .goBack {
//       .icons {
//         margin-top: 0px;
//         display: flex;
//         align-items: center;
//         padding-top: 3px;
//       }
//     }
//   }
// }

#modifierOptionsModal
  .SelectModifiersOptions__table
  .modifierGroupTable
  .custom-checkbox-wrapper
  .checkmark {
  top: 4px !important;
}

#modifierOptionsModal .modifierGroupTable tbody td .custom-checkbox-container,
#modifierOptionsModal .modifierGroupTable tbody td .modifiers__type {
  font-family: 'Work Sans', sans-serif;
  font-weight: 500;
}

.modifiersCard .addModifiers {
  .icons {
    margin-right: 5px;
  }
}

.oulined-button-wrapper {
  .bg-blue-btn {
    background: #fff;
    color: #414a6b;
    border: 1px solid #414a6b;

    .icons {
      margin-right: 9px;
      margin-top: 0px;
      img {
        content: url("./assets/images/add-blue-icon.svg");
      }
    }
  }
}

.btn-link-type {
  .bg-blue-btn {
    background: #fff;
    color: #414a6b;
    border: 1px solid #fff;
    padding: 0px 5px;
    position: relative;

    &:hover {
      box-shadow: none;

      // ::after {
      //   position: absolute;
      //   content: "";
      //   height: 1px;
      //   width: 90%;
      //   background: #414a6b;
      //   top: auto;
      //   bottom: 7px;
      //   left: 11px;
      //   right: 0;
      // }

      color: #ec380b;
      .icons {
        margin-right: 9px;
        margin-top: 0px;
        img {
          content: url("./assets/images/add-red-hovered-icon.svg");
        }
      }
    }

    .icons {
      margin-right: 9px;
      margin-top: 0px;
      img {
        content: url("./assets/images/add-blue-icon.svg");
      }
    }
  }
}

.form-control:disabled,
.form-control[readonly],
.form-field-disabled {
  border: solid 1px #c0c0c9 !important;
  background-color: #f5f5f5 !important;
}

.form-field-disabled:before {
  border: solid 1px #c0c0c9 !important;
  background-color: #c0c0c9 !important;
}

.progressBar.rangeslider-disabled .rangeslider__handle,
.progressBar.rangeslider-disabled .rangeslider__fill {
  background-color: #c0c0c9 !important;
}

.radius__value.rangeslider-disabled  {
  background-color: #c0c0c9 !important;
}

.modal-footer .btn {
  display: inline-block !important;
}

.modal-footer .btn .icons-right {
  margin-top: 0px;
}

.modal-footer .btn .icons-right > img {
  vertical-align: unset;
}

.no-data{
  .no-data-inner-wrapper{
    height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .img-box{
      margin-bottom: 24px;
    }
  }
}

.no-data h3 {
  font-family: 'Work Sans', sans-serif;
  font-weight: 700;
  font-size: 28px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #131414;

  @include bp(mobile){
    font-size: 21px;
  }
}

// .managermodifier__wrapper, .modifierDetails__wrapper{
//   border-top: 1px solid #ececec;
// }
.header {
  border-bottom: 1px solid #ececec;
}
.header.sticky {
  border-bottom: 2px solid #ececec;
  // box-shadow: 0 0 14px 0 rgba(0, 0, 0, .40);
}

table thead tr.mat-header-row {
  margin-bottom: 0px;
}

.searchable-dropbox {
  border: 0px !important;
  margin-top: 0px !important;
  box-shadow: 0 2px 13px 0 rgba(63, 74, 110, 0.1) !important;
}

.searchable-dropbox .css-106o0pu-Menu {
  padding: 12px;
  padding-top: 0px;
  border-radius: 4px;
  scrollbar-width: none;
  font-family: 'Work Sans', sans-serif;
  font-weight: 400;
  font-size: 15px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.53;
  letter-spacing: normal;
  color: #3f4a6e;
}

.searchable-dropbox .css-106o0pu-Menu,
#react-select-2-listbox {
  border: 0px !important;
  box-shadow: none;
}

.searchable-dropbox #react-select-2-listbox {
  padding-top: 0px !important;
}

.searchable-dropbox .css-4ljt47-MenuList::-webkit-scrollbar {
  display: none;
}

.searchable-dropbox .css-4ljt47-MenuList {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.searchable-dropbox .css-yt9ioa-option:hover {
  border-radius: 4px;
  background-color: rgba(236, 56, 11, 0.07);
  color: #ec380b;
}

.searchable-dropbox .css-1n7v3ny-option {
  font-family: 'Work Sans', sans-serif;
  font-weight: 500;
  border-radius: 4px !important;
  background-color: rgba(236, 56, 11, 0.07) !important;
  color: #ec380b !important;
}
.searchable-dropbox .searchable__dropdown__ckc__indicators {
  position: relative;
}

.searchable-dropbox .searchable__dropdown__ckc__indicators svg {
  display: none;
}
.searchable-dropbox .searchable__dropdown__ckc__indicators::after {
  position: absolute;
  content: url("./assets/images/icon-search.svg");
  width: 15px;
  height: 15px;
  top: 0;
  bottom: 0;
  margin: auto;
}

.searchable-menu-close {
  position: relative;
}

.searchable-menu-close::after,
.searchable-menu-open::after {
  position: absolute;
  content: url("./assets/images/dropdown-arrow.svg");
  width: 15px;
  height: 30px;
  top: 0;
  bottom: 0;
  right: 15px;
  margin: auto;
}

.user__custom__select {
  .custom-select-ckc__control {
    display: flex;
    border: solid 1px #6d7f8b !important;
    background-color: transparent;
    border-radius: 4px;
    @extend %font15-medium;
    height: 52px;
    color: #3f4a6e;
    position: relative;
    cursor: pointer;
  }

  .custom-select-ckc__control::after {
    position: absolute;
    content: url("./assets/images/Icon-arrow-top-ckc.svg");
    width: 15px;
    height: 30px;
    // height: 20px;
    top: 0;
    bottom: 0;
    right: 15px;
    margin: auto;
  }

  .custom-select-ckc__indicatorSeparator {
    background: none;
  }

  .custom-select-ckc__indicatorContainer {
    color: #3f4a6e;
    position: relative;
  }

  .custom-select-ckc__indicatorContainer svg,
  .custom-select-ckc__indicatorContainer svg {
    display: none;
  }

  #react-select-3-listbox,
  #react-select-2-listbox {
    margin-top: 0px !important;
    box-shadow: 0 2px 13px 0 rgba(63, 74, 110, 0.1);
    padding: 12px;
  }

  .custom-select-ckc__menu{
    margin-top: 0;
  }

  .custom-select-ckc__option:hover {
    font-family: 'Work Sans', sans-serif;
    font-weight: 500;
    border-radius: 4px !important;
    background-color: rgba(236, 56, 11, 0.07) !important;
    color: #ec380b !important;
  }
}

.managermodifier__listinPage,
.modifierDetails__wrapper {
  min-height: 100vh;
}

.brandcard .brand__name h6 small{
  color: #777778;
}

.details__wrapper .basic__info__content .form-row {
  @include bp(mobile){
    margin: 0;
  }
}

.details__wrapper__inner .right_content > span{
  margin-right: 8px;
}

.staff_listing_actions{
  .user__custom__select .custom-select-ckc__control{
    height: 48px;

    @include bp(mobile){
      height: 40px;
    }
  }

  .basic__info__content .user__custom__select .custom-select-ckc__control::after{
    @include bp(mobile){
      height: 15px;
    }
  }
}

// .goBack{
//   @include bp(mobile){
//     .back-btn-red span{
//       margin-top: 3px;
//     }
//   }
// }

.modifierDetails__wrapper{
  @include bp(mobile){
    padding-top: 80px !important;
  }
}


@include bp(991){
  .searchable__dropdown__ckc__indicators::after{
    top: -5px !important;
  }
}


.modifierListing__table .right_content>span{
  flex: unset;
}

.accordion{
  .card-header{
    .btn{
      &:focus{
        text-decoration: none;
      }
    }
  }
}
 
.btn_new{
  &:focus{
    text-decoration: none;
    outline: none;
    border: none;
  }
}
// date picker css

.react-daterange-picker__calendar .react-calendar{
  width: 100%;
}

.react-daterange-picker__calendar{
  width: 100% !important;
}

.react-daterange-picker__calendar{
  top: 38px !important;
}

.react-calendar__tile--now{
  background: $theme-red !important;
  color: #fff !important;
}

.react-calendar__tile--now:hover{
  background: $theme-red !important;
  color: #fff !important;
}

button.react-calendar__tile.react-calendar__tile--now.react-calendar__year-view__months__month:hover, button.react-calendar__tile.react-calendar__tile--now.react-calendar__decade-view__years__year:hover, button.react-calendar__tile.react-calendar__tile--now.react-calendar__century-view__decades__decade:hover{
  background: $theme-red !important;
  color: #fff !important;
}



@include bp(ipadpro){
  .react-daterange-picker{
    width: 100%;
  }

  .custom-datepicker .react-daterange-picker__range-divider {
    margin: 0 24% !important;
  }

  .react-daterange-picker__calendar{
    top: 38px;
  }
}

@include bp(mid-device){
  .react-daterange-picker{
    width: 100%;
  }
}

@include bp(mobile){
  .custom-datepicker .react-daterange-picker__range-divider {
    margin: 0 12% !important;
  }
}


.shared__vbs__wrapper{
  .accordion__header{
    margin-bottom: 12px;
    display: flex;
    justify-content: space-between;
    padding-left: 26px;
    padding-right: 27px;

    > span{
      font-family: 'Work Sans', sans-serif;
      font-weight: 700;
      font-size: 12px;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.67;
      letter-spacing: normal;
      color: #6d7f8b;
      text-transform: uppercase;
    }
  }
}

.orders__page__wrapper{
  .searchable__dropdown__ckc__indicators{
    align-items: flex-start;
  }

  .custom-checkbox-wrapper .checkmark {
    top: 3px;
  }
}


.custom-datepicker .react-daterange-picker__wrapper{
  padding: 0px 16px;

  @include bp(mid-device) {
    padding: 0px 6px;
  }

  @include bp(ipadpro){
    padding: 0px 8px;
  }
}

.react-daterange-picker__calendar{
  box-shadow: 0 5px 9px -3px rgba(0, 0, 0, 0.08);
  background-color: #fff;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  padding: 10px;
}

.react-daterange-picker__calendar .react-calendar{
  border: none;
  font-family: 'Work Sans', sans-serif;  
  font-size: 14px;
  font-weight: 400; 
}

.custom-datepicker .react-calendar__month-view__weekdays__weekday{
  border: solid 0.9px #e0e7ef;
  background-color: #f1f4f7 !important;
  font-family: 'Work Sans', sans-serif;   
  font-size: 11.3px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  color: #1b2236 !important;
  border: none;
  border-right: solid 0.9px #e0e7ef;
  padding: 10px 5px;
}

.custom-datepicker .react-calendar__month-view__weekdays__weekday abbr[title]{
  text-decoration: none;
  font-family: 'Work Sans', sans-serif;   
  font-size: 11.3px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  color: #6d7f8b;
}

.custom-datepicker .react-calendar__navigation{
  margin-bottom: 0px;
}

.react-calendar__navigation button:enabled:focus, .react-calendar__navigation button:enabled:hover, .react-calendar__tile:enabled:focus, .react-calendar__tile:enabled:hover{
  background-color: #f1f4f7 !important;
  font-family: 'Work Sans', sans-serif;  
}

.react-daterange-picker__inputGroup__input:invalid{
  background-color: unset !important;
  font-family: 'Work Sans', sans-serif;  
}

.react-daterange-picker__inputGroup__input:focus-visible{
  outline: none !important;
  font-family: 'Work Sans', sans-serif;  
}

.react-calendar__tile--now:hover, .react-calendar__tile--now:focus {
  background: $theme-red !important;
  color: #fff !important;
  font-family: 'Work Sans', sans-serif;  
  font-size: 14px;
  font-weight: 400;
}

.react-calendar__tile.react-calendar__tile--now.react-calendar__month-view__days__day:hover{
  background: $theme-red !important;
  color: #fff !important;
  font-family: 'Work Sans', sans-serif;  
  font-size: 14px;
  font-weight: 400;
}

.custom-datepicker .react-calendar__tile--active:enabled:hover, .custom-datepicker .react-calendar__tile--active:enabled:focus, .custom-datepicker .react-calendar__tile--active{
  background: $theme-red !important;
  color: #fff !important;
}

.react-calendar__navigation__label{
  font-family: 'Work Sans', sans-serif;  
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  text-align: center;
  color: #191d20;
}

.react-calendar__month-view__days__day--weekend{
  color: #1b2236 !important;
}

.react-calendar__month-view__days__day--neighboringMonth{
  color: #8d8e91 !important;
}

button.react-calendar__navigation__arrow.react-calendar__navigation__next2-button{
  position: relative;
  font-size: 0px;

  &::after{
    content: url('./assets/images/arrow_Right_Double.svg');
    position: absolute;
    top: 35%;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    font-size: 14px;
  }
}

button.react-calendar__navigation__arrow.react-calendar__navigation__prev2-button{
  position: relative;
  font-size: 0px;

  &::after{
    content: url('./assets/images/arrow_Left_Double.svg');
    position: absolute;
    top: 35%;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    font-size: 14px;
  }
}

button.react-calendar__navigation__arrow.react-calendar__navigation__next-button{
  position: relative;
  font-size: 0px;

  &::after{
    content: url('./assets/images/arrow_Right.svg');
    position: absolute;
    top: 35%;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    font-size: 14px;
  }
}

button.react-calendar__navigation__arrow.react-calendar__navigation__prev-button{
  position: relative;
  font-size: 0px;

  &::after{
    content: url('./assets/images/arrow_Left.svg');
    position: absolute;
    top: 35%;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    font-size: 14px;
  }
}

.react-calendar__tile{
  padding: 0.65em 0.5em;
}

.landingPage__wrapper.static__pages__wrapper .sections p{
  font-size: 15px;
}

// @media screen and (min-width:1200px) and (max-width:1440px){
//   .landingPage__wrapper.static__pages__wrapper .landingpage__header{
//     max-width: 1285px;
//     left: 0;
//     right: 0;
//     margin-left: auto;
//     margin-right: auto;
//   }
// }

@media screen and (min-width:768px){
  .landingPage__wrapper{
    .subheader{
      display: none;
    }
  }
}

// for accoridion

.accordion{

  .card-header {
    button{
      &::after {
          content: "";
          position: absolute;
          background-image: url("./assets/images/open-plus.svg");
          background-repeat: no-repeat;
          background-position: center;
          width: 10px;
          height: 10px;
          top: 0;
          right: 0;
          bottom: 0;
          margin: auto;
        }
     }
    
    button.collapsed{
      &::after{
        content: "";
          position: absolute;
          background-image: url("./assets/images/accordion_minus-icon.svg");
          background-repeat: no-repeat;
          background-position: center;
          width: 10px;
          height: 3px;
          top: 0;
          right: 0;
          bottom: 0;
          margin: auto;
      }
    }
  }

}

.orders__page__wrapper {
  .searchable-menu-close:after, .searchable-menu-open:after{
    content: url("./assets/images/Icon-arrow-top-ckc.svg") !important;
  }
}


@include bp(mobile){
  .subheader.non-auth-subheader-desktop {
    top: 60px !important;

    // .back-btn-red .icons{
    //   display: inline-flex;
    // }
}

.savedraft__wrapper .saveAsDraft__btn::after{
  height: 10px !important;
}
}

.view-mode + .confirm_page_header .savedraft__wrapper{
  padding-top: 0px;
}

.view-mode + .confirm_page_header h1{
  margin-bottom: 0px;
}

button.search-btn{
  top: 0;
  bottom: 0;
}

.filter__applied{
  position: relative;
  &::before{
    content: "";
    position: absolute;
    width: 8px;
    height: 8px;
    border: solid 1px #fff;
    background-color: #ec380b;
    border-radius: 50%;
    top:6px;
    right:6px;
  }
}

.filters.filter__applied{
  box-shadow: 0 6px 10px 0 rgba(134, 13, 13, 0.04) !important;
  border: solid 1px #da0000 !important;
  background-color: #fff;
}


@include bp(mobile){
  .subheader.non-auth-subheader-desktop.staticPages_subheader{
    top: 44px !important;

    .back-btn-red .icons{
      display: initial;
    }
  }

  .goBack.view-mode + .basic__info {
    margin-top: 32px;
  }

  .goBack.view-mode+.confirm_page_subheader{
    margin-top: 32px;
  }

  .goBack.view-mode+.confirm_page_header{
    margin-top: 32px;
  }

  .view-mode+.confirm_page_header h1{
    margin-bottom: 12px;
  }
}

.modal-footer .btn{
  font-weight: 600;
}

.credentials__modal{
  .modal-content{
    padding-left: 32px;
    padding-right: 32px;
    border-radius: 4px !important;
  }

  .modal-body{
    padding-left: 0px;
    padding-right: 0px;
  }

  .cancel-btn:hover{
    background: #fff;
  }

  .modal-header{
    justify-content: center;
  }

  .modal-title.h4{
    font-size: 25px;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.32;
    letter-spacing: normal;
    text-align: center;
    color: #131414;
  }
}

.signIn__wrapper{
.back-btn-red{
  display: flex;
  align-items: center;
  .back-btn-red span{
    display: initial;
    margin-top: 0;
  }

  .icons{
    margin-top: 0px;
  }
}
}

.orders__page__wrapper .searchable__dropdown__ckc__placeholder{
  font-size: 12px;
  text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.create-banner-btn {
  font-size: 14px;
  .icons{
    margin-right: 6px;
  }
}

@media screen and (max-width:1600px){
  .react-calendar__navigation button{
    min-width: 26px !important;
  }

  button.react-calendar__tile.react-calendar__year-view__months__month {
    font-size: 12px;
  }
}

.associated__devices__wrapper .modifierListing__table .right_content>span{
  margin-right: 0px;
}

.subsections h4{
  font-family: 'Work Sans', sans-serif;
    font-weight: 700;
    font-size: 18px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: #131414;
    margin-bottom: 8px;
}

.privacy__wrapper a{
  color: #ec380b;
  &:hover{
    color: #ec380b;
  }
}


@include bp(mobile){
  .reports__wrapper .orders__page__wrapper .managermodifier__wrapper .managermodifier__header{
    padding-bottom: 20px;
  }
}

.static_page_important{
  font-weight: 600 !important;
  text-transform: uppercase;
}

.recipeGuideImgClass {
  width: 40px !important;
  height: 40px !important;
  border-radius: 50%;
  vertical-align: middle;
}

.rgcontent-box {
  display: flex;
  justify-content: center;
  align-items: center;

  @include bp(mobile) {
    flex-direction: column;
    align-items: flex-start !important;
  }

  @include bp(ipadpro){
    flex-direction: column;
    align-items: flex-start !important;
  }
  
  @include bp(mid-device) {
    flex-direction: column;
    align-items: flex-start !important;
  }
}

.order-status-btn{
  background: none;
  border: none;
}

.attachments-btn {
  @extend %font15-semibold;
  color: #fff;
  line-height: 1.25;
  background: $theme-red;
  width: 180px;
  height: 48px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  position: absolute;
  top: 25px;
  right: 25px;
  >img{
    margin-right: 4px;
  }

  @include bp(mobile) {
    width: 125px;
    height: 33px;
    font-size: 12px;
    >img {
        width: 20px;
        height: 20px;
      }
  }
}

.recipeguides-btn{
  width: 195px !important;
  @include bp(mobile) {
    width: 150px !important;
  }
}

.recipeSelectionModal {
  .modal-content {
    height: 680px !important;
    width: 570px !important;
    padding: 0 !important;
  }
  .modal-footer{
    margin-block-start: 5px;
    margin-bottom: 25px;
  }
  .selectrecipe-tbody{
    height: 405px;
    width: 100%;
    display:block;
    overflow-y: scroll;
  }
  .item-style{
    padding: 12px 12px;
    border-radius: 4px;
    &:hover {
      background: rgba(236, 56, 11, 0.07);
      color: #ec380b;
      @extend %font15-medium;
    }
    &:focus {
      background: rgba(236, 56, 11, 0.07);
      color: #ec380b;
      @extend %font15-medium;
    }
  }
  .content-desc{
    p {
        color: rgba(19, 20, 20, 1);
        opacity: 50%;
        @extend %font12-regular;
      }
  }
}

.btn__no__style{
  @extend %font15-regular;
  background: none;
  border: none;
  outline: none;
  padding: 0;
}

.view_form_input{
  font-family: Work Sans;
  font-size: 15px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0px;
  text-align: left;
}

.cursor__pointer {
  cursor: pointer;
}

// nav tabs styling for restaurant details//
.nav_tabs_styling {
  border-bottom: none !important;
  text-transform: capitalize;
}

.nav_item_styling {
  margin-right: 90px;

  @include bp(mobile) {
    margin: 0;
  }
}

.addnewrecipeCat__wrapper {
  padding-top: 30px !important;
}

.ingredient__value {
  font-weight: 300 !important;
  border-bottom: 1px solid #B0B2C5;
  width: fit-content; 
}

.card__no__border{
  border-radius: 0px !important;
  box-shadow: none !important;
  border: none !important;
}
.card-body__no__padding{
padding-left: 0px !important;
}
.card-header__no__padding{
padding-left: 13px !important;
}

// order tracking button
.track__btn {
  margin-right: 20px;
  color: rgb(48, 175, 39);
  background-color: rgba(137, 210, 41, 0.24);
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  padding: 6px 10px;
  border: none;
  @extend %font12-medium;
}

// flex container
.flex__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-container{
  display: flex;
  justify-content: space-between;
}

.flex_col_container {
  display: flex;
  justify-content: space-between;
  flex-direction: column
}

.input_end_container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.sort__info {
  display: flex;
  @extend %font15-medium;
}

.sort_end_info {
  display: flex;
  justify-content: flex-end;
  @extend %font15-medium;
  @media screen and (max-width:768px) {
      justify-content: center !important;
    }
}

.flex__row{
  flex-direction: row;
}

.flex__col {
  flex-direction: column;
}

.flex__end{
  justify-content: flex-end !important;
}

.ml__auto{
  margin-left: auto;
}

.input__container{
  .form-control.is-invalid{
    padding-right: 0px !important;
  }
}

//order list row flex action 
.flex-container-action {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.center__content{
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center !important;
}

.flex__start{
  justify-content: flex-start !important;
}

.no__wrap{
  white-space: nowrap;
}

.calendarWrap {
  display: inline-block;
  position: relative;
  width: 100%;
}

.calendarElement {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 40px;
  border: 1px solid #ccc;
  z-index: 999;
}
.calendarElement .rdrDateDisplayWrapper {
  display: none;
}
.calendarElement {
  margin-top: 4px;
  width: 100% !important;
}
.calendarElement .rdrNextButton i {
  margin: 0 0 0 3px;
}

.date-range-picker{
  color: #1b2236 !important;
  font-size: 13px !important;
  font-weight: 500 !important;
}
.date-range-picker:read-only{
  background-color: #FFFFFF !important;
}

.font__15__semibold {
  @extend %font15-semibold;
}

.display__flex{
  display: flex;
}
.flex__center__container{
  justify-content: center;
  align-items: center;
}
.m-30{
  margin: 30px;
}
