@import "~bootstrap/scss/bootstrap";
@import "../../assets/scss/base/base-include.scss";

.bannerhompega-bg {
  @extend %banner;
  background: #ccc url("../../assets/images/homepage-banner.jpg");
  background-repeat: no-repeat;
  background-position: right;
  background-size: cover;

  @include bp(ipadpro) {
    min-height: 360px;
    height: 360px;
    display: flex;
    align-items: center;
  }

  .banner-content-wrapper {
    padding: 156px 0px;
    max-width: 515px;
    margin-left: 95px;
    color: #fff;

    @include bp(ipadpro) {
      padding: 30px 0px;
      margin-left: 62px;
    }

    h1 {
      font-family: 'Work Sans', sans-serif;
      font-size: 38px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.21;
      letter-spacing: normal;
      margin-bottom: 16px;

      @include bp(ipadpro) {
        font-size: 32px;
      }
    }

    p {
      @extend %font21-regular;

      @include bp(ipadpro) {
        font-size: 18px;
      }
    }

    a.create-banner-btn {
      @extend %btn-red;
      font-family: 'Work Sans', sans-serif;
      border: none;
      font-size: 16px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: normal;
      text-align: center;
      color: #fff;
      margin-top: 32px;
      padding: 14px 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      max-width: 220px;
      text-decoration: none;

      @include bp(ipad) {
        padding: 14px 20px;
      }

      &:hover {
        text-decoration: none;
      }
    }
  }
}

.icons {
  margin-right: 16px;
}

.homepage-mobile {
  .banner-content-wrapper {
    padding: 24px 16px;
    text-align: center;
    h1 {
      @extend %font25-bold;
      text-align: center;
      color: #414a6b;
      margin-bottom: 10px;
    }

    p {
      @extend %font15-regular;
      color: #414a6b;
      margin-bottom: 0;
    }

    .create-banner-btn {
      @extend %btn-red;
      font-family: 'Work Sans', sans-serif;
      border: none;
      font-size: 16px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: normal;
      text-align: center;
      color: #fff;
      margin-top: 32px;
      padding: 14px 5px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;

      &:hover {
        text-decoration: none;
      }
    }
  }
  .banner__image {
    margin-top: 8px;
  }
}

.hompage-main {
  padding: 112px 0px 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  @include bp(ipad) {
    padding: 64px 0px;
  }

  .section-wrapper {
    h2 {
      font-family: 'Work Sans', sans-serif;
          font-weight: 700;
      font-size: 31px;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #161626;
      margin-top: 24px;
      margin-bottom: 16px;

      @include bp(ipad) {
        font-size: 25px;
        line-height: normal;
        margin-bottom: 12px;
      }
    }

    p {
      font-size: 15px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.53;
      letter-spacing: normal;
      text-align: center;
      color: #949494;

      .quoted-text {
        color: #414a6b;
      }
    }

    .file-icon {
      > img {
        @include bp(ipad) {
          max-width: 140px;
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
  }
}


.no-data{
  min-height: 30vh;
  display: flex;
  align-items: center;
  justify-content: center;
}