@import "~bootstrap/scss/bootstrap";
@import "../../assets/scss/base/base-include.scss";

.browsefile {
  margin-bottom: 8px;

  .custom-file-input {
    color: transparent;
    opacity: 1;
    background: #414a6b;
    width: 126px;
    text-align: center;
    border-radius: 4px;
    height: 40px;
  }
  .custom-file-input::-webkit-file-upload-button {
    visibility: hidden;
  }
  .custom-file-input::before {
    @extend %choosefile-btn;
    content: "Choose File";
    display: flex;
    justify-content: center;
    align-items: center;
    background: -webkit-linear-gradient(#414a6b);
    border: 1px solid #414a6b;
    border-radius: 3px;
    outline: none;
    white-space: nowrap;
    -webkit-user-select: none;
    cursor: pointer;
    position: absolute;
    width: 100%;
  }
  .custom-file-input:hover::before {
    border-color: #414a6b;
  }
  .custom-file-input:active {
    outline: 0;
  }
  .custom-file-input:active::before {
    background: -webkit-linear-gradient(#414a6b);
  }
}

.uploadAssets__wrapper {
  h3 {
    font-size: 21px;

    @include bp(mobile) {
      font-size: 18px !important;
    }
  }

  .wrapper {
    text-align: center;
    padding: 57px 32px 56px;
    border-radius: 4px;
    border: dashed 1px #414a6b;
    background-color: #fdfdff;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 315px;
    cursor: pointer;

    &.logo-image-wrapper {
      max-width: 290px;
      margin-right: 30px;

      @include bp(mobile) {
        max-width: 100% !important;
      }
    }
    .inner-content {
      > .icon {
        margin-bottom: 10px;
      }

      > p {
        @extend %font12-medium;
        color: #414a6b;
      }
    }
  }
}

.instructions {
  @extend %font12-regular;
  text-align: center;
  color: #c0c0c8;
  max-width: 226px;
  margin-left: auto;
  margin-right: auto;
}

.uploadAssets-wrapper {
  h3 {
    @extend %font21-semibold;
    margin-bottom: 16px;
  }
}

.uploaded__files {
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #161626;
  min-height: 27px;
  background-color: rgba($color: #d8d8d8, $alpha: 0.18);
  padding: 7px 6px;
  margin-bottom: 8px;
  border-radius: 4px;
  max-width: 288px;
  // margin-left: auto;
  margin-right: auto;

  .file {
    margin-right: 8px;
  }

  .file__name {
    flex: 1;
    text-align: left;
  }

  button {
    border: none;
    outline: none;
    background: none;
    display: flex;
    align-items: center;
  }

  &.upload__error {
    background-color: rgba($color: #ffd5d5, $alpha: 0.18);
    color: $error-color;
    border: solid 1px #ffd5d5;
  }
}

.menu__image__wrapper {
  margin-top: 32px;
  .banner__image {
    img {
      border-radius: 4px;
    }
  }

  .instructions {
    max-width: 100%;
  }

  .button__wrapper {
    text-align: center !important;
    padding: 16px 0px;
    justify-content: center;
    align-items: center;

    > .btn-blue {
      width: 141px;
      height: 40px;
      padding: 10px 13px 10px 13px;
      border-radius: 4px;
      background-color: #414a6b;
      color: #fff;
      @extend %font12-medium;
      line-height: normal;
    }
  }
}
