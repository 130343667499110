@import "~bootstrap/scss/bootstrap";
@import "../../../assets/scss/base/base-include.scss";

.CreateVirtualBrand__wrapper {
  background: #f8f9fa;
  padding: 48px 0px;
  padding-top: 80px;

  @include bp(mobile) {
    padding-top: 16px;
    padding-bottom: 64px;
  }

  .goBack {
    @include bp(mobile) {
      display: none;
    }
  }
}

.step__wrapper {
  @include bp(mobile) {
    padding-top: 32px;
  }
  > div {
    @extend %font15-medium;
    > div:first-child {
      max-width: 700px;
      margin: auto;
      padding-bottom: 48px;
      counter-reset: step;
      @extend %font15-medium;

      @include bp(991) {
        max-width: 500px;
      }

      @include bp(mobile) {
        padding-bottom: 0px;
        font-size: 12px;
      }

      > .sc-bdfBQB {
        width: 57px;
        height: 57px;
        border-radius: 50%;
        background-color: #f8f8f8;
        border: solid 1px #c0c0c8;
        @extend %font15-medium;

        @include bp(mobile) {
          width: 34px;
          height: 34px;
          font-size: 12px;
        }

        &::before {
          // content: counter(step, decimal-leading-zero);
          content: counter(step);
          counter-increment: step;
          height: 100%;
          width: 100%;
          background: unset;
          transform: none;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
          display: flex;
          align-items: center;
          justify-content: center;
          @extend %font15-medium;
          color: #6d7f8b;
        }

        &::after {
          @extend %font18-medium;
          bottom: -35px;
          text-transform: unset;

          @include bp(mobile) {
            display: none;
            font-size: 12px;
          }
        }
      }

      .hOqXoq,
      .llfJbO,
      .daZhlp,
      .gNqZVc,
      .dZhYoU,
      .dkWkys,
      .itsuzW,
      .dRmPhP,
      .bzXsuR,
      .dhbaEc,
      .tcHki,
      .dtMlMD {
        background-color: $theme-red;
        color: #fff;
        border-color: $theme-red;

        &::before {
          color: #fff;
        }

        &::after {
          color: $theme-red;
          font-family: 'Work Sans', sans-serif;
          font-weight: 700;
          font-size: 18px;
        }
      }

      .kmptMj,
      .iRxQAh,
      .hQKZCL,
      .gznikt,
      .jXNJBH,
      .hwHPGc,
      .iPHZG,
      .cwRbBM,
      .gmGLLJ,
      .gNqZVc,
      .fVPSFh,
      .gnsKKC {
        &::before {
          content: "";
          background: url("../../../assets/images/step-done.svg") no-repeat;
          background-size: 100%;
          height: 57px;
          width: 57px;
          border-color: $theme-red;

          @include bp(mobile) {
            width: 34px;
            height: 34px;
            background-size: cover;
          }
        }

        &::after {
          color: $theme-red;
        }
      }

      .extmMd,
      .giMUZf {
        background-color: $theme-red;
      }

      .gYSznr {
        background-color: #e0e0e0;
      }
    }
  }
}

.icons-right {
  margin-left: 12px;
  margin-top: -5px;
}

.multiForm__actions {
  display: inline-flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;

  .skip__link {
    margin-right: 30px;

    @include bp(mobile) {
      margin-top: 32px;
      margin-right: auto;
      margin-left: auto;
    }

    &:hover {
      text-decoration: none;
      color: $theme-red;
    }
  }

  .red-btn {
    border: unset;
  }

  .confirm-red-btn {
    border: unset;
  }

  margin-top: 56px;

  @include bp(mobile) {
    flex-direction: column-reverse;
    .red-btn {
      width: 100%;
      max-width: 100%;
    }
    .confirm-red-btn {
      width: 100%;
      max-width: 100%;
    }
  }
}

.mobile-steps {
  @extend %font12-SemiBold;
  color: $theme-red;
  p {
    margin-bottom: 12px;
  }
}

.confirm_page_header {
  margin-bottom: 32px;

  h1 {
    @extend %font32-bold;
    margin-bottom: 12px;

    @include bp(mobile) {
      font-size: 21px;
    }
  }

  p {
    @extend %font15-regular;
    line-height: 1.53;
    letter-spacing: normal;
    color: #6d7f8b;
    opacity: 0.9;
    margin-bottom: 0;
    letter-spacing: -0.6px;

    @include bp(mobile) {
      font-size: 15px;
    }
  }
}

.confirm_page_subheader {
  margin-bottom: 16px;
  h2 {
    @extend %font32-bold;
    line-height: 1.32;
    @include bp(mobile) {
      font-size: 18px;
    }
  }
}

.btn.bg-blue-btn {
  font-family: 'Work Sans', sans-serif;
  border: none;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  padding: 14px 20px;
  border-radius: 4px;
  background-color: #414a6b;
  height: 48px;

  &:hover {
    color: #fff;
  }
}

.btn.red-btn-ghost {
  background: unset;
  color: #ec380b;
  border: 1px solid $theme-red;

  &:hover {
    color: #ec380b;
    box-shadow: 0 2px 10px 0 rgba(218, 0, 0, 0.17);
  }
}

.button__wrapper {
  text-align: right;

  .btn:not(:last-child) {
    margin-right: 30px;

    @include bp(ipadpro) {
      margin-right: 25px;
    }
  }

  @include bp(mobile) {
    text-align: center;
    display: flex;
    flex-direction: column;
    padding-top: 16px;

    .btn {
      width: 100%;
      margin: 0;

      &:not(:last-child) {
        margin-bottom: 24px;
      }
    }
  }
}

.savedraft__wrapper {

  padding: 40px 0px;
  text-align: right;


  .saveAsDraft__btn {
    border: solid 1px #da0000;
    @extend %font15-semibold;
    width: 225px;
    height: 48px;
    padding: 12px 15px 13px 15px;
    border-radius: 4px;
    color: $theme-red;
    text-align: left;
    background-color: #fff;
    display: flex;
    display: -webkit-flex;
    justify-content: space-between;
    align-items: center;

    @include bp(mobile) {
      width: 100%;
      flex: 1;
    }

    &:hover{
      background: #fff;
      color: $theme-red;
      border: solid 1px #da0000;
    }

    &:focus {
      box-shadow: none;
      background: #fff;
      color: $theme-red;
      border: solid 1px #da0000;
    }

    &:focus-visible {
      box-shadow: none;
      background: #fff;
      color: $theme-red;
      border: solid 1px #da0000;
    }

    &:active{
      box-shadow: none;
      background: #fff;
      color: $theme-red;
      border: solid 1px #da0000;
    }

    &::after{
      content: "";
        background: url("../../../assets/images/arrow-red-dropdown.svg")
          no-repeat !important;
        background-size: contain;
        border: none;
        height: 12px;
        width: 16px;
        background-position: bottom;
        vertical-align: bottom;
    }


    > button {
      @extend %font15-semibold;
      width: 160px;
      height: 48px;
      padding: 12px 15px 13px;
      border-radius: 4px;
      border: 0;
      color: $theme-red;
      text-align: left;
      background-color: #fff;

      &:focus {
        box-shadow: none;
      }

      &:hover{
        background: #fff;
      }
    }

    .btn-primary:not(:disabled):not(.disabled):active {
      background-color: #fff;
      border-color: #fff;
      color: $theme-red;
    }

    .btn-primary:not(:disabled):not(.disabled):focus {
      box-shadow: none;
    }

    button#dropdown-basic{
      width: auto;
      border-left: 0;
      border-color: $theme-red;
      background-color: #fff;

      @include bp(mobile) {
        flex: 0;
      }

      &:focus {
        box-shadow: none;
      }

      &::after {
        content: "";
        background: url("../../../assets/images/arrow-red-dropdown.svg")
          no-repeat;
        background-size: contain;
        border: none;
        height: 16px;
        width: 16px;
        background-position: bottom;
      }
    }

    .dropdown-menu.show {
      transform: translate(0px, 50px) !important;
      width: 100% !important;
      border-radius: 4px;
      box-shadow: 0 2px 13px 0 rgba(63, 74, 110, 0.1);
      background-color: #fff;
      border: 0;
      padding: 12px;

      > .dropdown-item {
        @extend %font15-medium;
        color: #3f4a6e;
        padding: 0px;
        height: 36px;
        max-width: 200px;
        display: flex;
        align-items: center;
        padding-left: 6px;
        border-radius: 4px;

        &:hover {
          background: rgba(236, 56, 11, 0.07);
          color: #ec380b;
        }
      }
    }

    .show > .btn-primary.dropdown-toggle {
      color: #fff;
      background-color: $theme-red;
      border-color: $theme-red;
      box-shadow: none;
    }
  }
}

.gYSznr,
.extmMd {
  height: 1px !important;
}

.step__wrapper > div > div:first-child > .sc-bdfBQB.hQKZCL,
.step__wrapper > div > div:first-child > .sc-bdfBQB.kmptMj,
.step__wrapper > div > div:first-child > .sc-bdfBQB.hwHPGc {
  border: none;
}

.sc-bdfBQB.hOqXoq + .gYSznr,
.sc-bdfBQB.llfJbO + .gYSznr,
.sc-bdfBQB.daZhlp + .gYSznr {
  background-color: $theme-red !important;
}

@media screen and (max-width: 575px) {
  .step__wrapper > div,
  .step__wrapper > div > div:first-child,
  .step__wrapper > div > div:first-child > .sc-bdfBQB,
  .step__wrapper > div > div:first-child > .sc-bdfBQB::before,
  .savedraft__wrapper .saveAsDraft__btn .dropdown-menu.show > .dropdown-item {
    font-size: 12px;
  }
}

@include bp(mobile) {
  .confirm_page_header {
    .savedraft__wrapper {
      padding-top: 8px;
      padding-bottom: 0;
    }
  }
}

.goBack.view-mode {
  float: unset;
  margin-bottom: 32px;

  @include bp(mobile){
    float: left;
    margin-bottom: 0;
  }
}


.savedraft__wrapper .btn-primary:not(:disabled):not(.disabled):active, .savedraft__wrapper .btn-primary:not(:disabled):not(.disabled).active, .savedraft__wrapper .show > .btn-primary.dropdown-toggle{
  box-shadow: none;
  background: #fff;
  color: $theme-red;
  border: solid 1px #da0000;
}

.savedraft__wrapper .btn-primary.focus, .savedraft__wrapper .btn-primary:focus{
  box-shadow: none !important;
}


.savedraft__wrapper{
  max-width: 225px;
  margin-left: auto;

  @include bp(mobile){
    max-width: 100%;

    .saveAsDraft__btn{
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  .dropdown-menu.show{
    transform: translate(0px, 50px) !important;
    width: 100% !important;
    border-radius: 4px;
    box-shadow: 0 2px 13px 0 rgba(63, 74, 110, 0.1);
    background-color: #fff;
    border: 0;
    padding: 12px;
  }
  .dropdown-item {
    @extend %font15-medium;
    color: #3f4a6e;
    padding: 0px;
    height: 36px;
    max-width: 200px;
    display: flex;
    align-items: center;
    padding-left: 6px;
    border-radius: 4px;
  
    &:hover {
      background: rgba(236, 56, 11, 0.07);
      color: #ec380b;
    }
  }
}
