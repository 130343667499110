@import "~bootstrap/scss/bootstrap";
@import "../../../../../assets/scss/base/base-include.scss";

.right > .modal-dialog {
  position: fixed;
  margin: auto;
  width: 705px;
  height: 100%;
  box-shadow: -1px 1px 23px 0 rgba(0, 0, 0, 0.07);
  -webkit-transform: translate3d(0%, 0, 0);
  -ms-transform: translate3d(0%, 0, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
  @include bp(ipad-min) {
    max-width: 704px;
  }
}

.right > .modal-dialog > .modal-content {
  height: 100%;
  padding-right: 95px;
}

.right .modal-content {
  overflow-y: auto;
  box-shadow: -1px 1px 23px 0 rgba(0, 0, 0, 0.07);
}

.right .modal-body {
  padding: 0;
}

/*Right*/
.right > .modal-dialog {
  right: -704px;
  -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
  -o-transition: opacity 0.3s linear, right 0.3s ease-out;
  transition: opacity 0.3s linear, right 0.3s ease-out;
}

.right.show .modal-dialog {
  right: 0px;
}

/* ----- MODAL STYLE ----- */
.modal-content {
  border-radius: 0;
  border: none;
  padding: 40px 40px 41px 40px;
}

.modal-header {
  padding: 0;
  h1 {
    @extend %font32-bold;
    color: #131414;
  }
}

.createMenustabs {
  margin-top: 16px;
}

.modal-footer {
  .btn {
    min-width: 128px;
    border: 1px solid #ec380b;
    background-color: $theme-red;
    color: #fff;
    // display: flex;
    // align-items: inherit;
    width: auto;
    padding: 10px 24px;

    &:not(:last-child) {
      margin-right: 30px;
    }

    &:hover {
      color: #fff;
    }

    // .icons-right {
    //   margin-top: -10px;
    // }

    &.red-btn-ghost {
      background: unset;
      color: $theme-red;

      &:hover {
        color: #fff;
        color: $theme-red;
        box-shadow: 0 2px 10px 0 rgba(218, 0, 0, 0.17);
      }
    }
  }

  .skip__link {
    margin-right: 30px;
  }
}
