@import "~bootstrap/scss/bootstrap";
@import "../../../../../../assets/scss/base/base-include.scss";

#AddnewmodifierModal {
  z-index: 1052 !important;
  box-shadow: 10px 11px 0px 1200px rgba(65, 74, 107, 0.85);
  -webkit-box-shadow: 10px 11px 0px 1200px rgba(65, 74, 107, 0.85);
  -moz-box-shadow: 10px 11px 0px 1200px rgba(65, 74, 107, 0.85);
  background: rgba(65px, 74px, 107px, .85);
}

.addNewModifier__wrapper {
  h2 {
    @extend %font25-bold;
    line-height: 1.32;
    color: #131414;

    @include bp(mobile) {
      font-size: 25px !important;
    }
  }

  .label__header{
    font-size: 14px;
    text-transform: uppercase;
    color: #6d7f8b;
    font-weight: 600;

    @include bp(mobile) {
      font-size: 12px;
    }
  }
  .label__info{
    font-size: 14px;
    font-weight: 500;
    text-transform: capitalize;
    @include bp(mobile) {
      font-size: 12px;
    }
  }

  .addNewModifier__wrapper__inner {
    padding-top: 32px;
    text-align: left;
  }

  .addmodifiers__options {
    padding-top: 40px;
    > button {
      background: unset;
      border: none;
      outline: none;
    }
  }
}

.addModifiers:hover {
  color: $theme-red;
  text-decoration: none;
}

.modifieroptions__wrap {
  display: flex;

    > div {
    width: 50%;

    &:not(:last-child) {
      margin-right: 12px;
    }
  }
}

.delete_modifier_wrapper {
  display: flex;

  .delete_modifier {
    border: none;
    outline: none;
    background: none;
    margin-left: 12px;
    img{
      margin:unset;
    }
  }
}


.itemModifierGroupTable{
  tr{
    th{
      padding-left: 0px;
    }
    td{
      padding-left: 0px;
    }
  }

  .custom-checkbox-wrapper .checkmark{
    top: 4px;
  }
}

#AddnewmodifierModal{
  .modal-header{justify-content: center;}
  .addNewModifier__wrapper .addNewModifier__wrapper__inner{
    padding-top: 0px;
  }
  .modal-body{padding-top: 0px !important;}
  .modal-content{border-radius: 4px !important;}
  select.form-control{background-position: 95%;}

  
}
