@import "~bootstrap/scss/bootstrap";
@import "../../../assets/scss/base/base-include.scss";
.modifierDetails__wrapper {
  background-color: #f8f9fa;
  padding-top: 48px;
  padding-bottom: 278px;
  height: auto;
  padding-bottom: 65px;

  @include bp(mobile) {
    padding-top: 40px;
    padding-bottom: 64px;
    height: auto;
  }

  .goBack {
    margin-bottom: 32px;
    @include bp(mobile) {
      margin-bottom: 24px;
      display: none;
    }
  }

  .main_heading {
    padding-bottom: 24px;

    h1 {
      @extend %font32-bold;
      line-height: 1.25;
      color: #131414;
      margin-bottom: 0;

      @include bp(mobile) {
        font-size: 21px;
        margin-bottom: 8px;
      }
    }
  }

  .details__wrapper {
    border-radius: 4px;
    box-shadow: 0 5px 9px -3px rgba(0, 0, 0, 0.08);
    background-color: #fff;
    padding: 29px 26px 47px 32px;

    @include bp(ipadpro) {
      padding: 29px 26px 30px 32px;
    }

    @include bp(mobile) {
      padding: 20px 15px;
    }

    .details__wrapper__inner {
      display: flex;
      justify-content: space-between;

      .detailCard {
        h5 {
          @extend %font12-bold;
          line-height: 1.67;
          color: #6d7f8b;
          text-transform: uppercase;
          @include bp(mobile) {
            margin-bottom: 0px;
          }
        }

        p {
          @extend %font15-regular;
          line-height: 1.53;
          color: #131414;
        }

        &:not(:last-child) {
          padding-bottom: 24px;

          @include bp(mobile) {
            padding-bottom: 0px;
          }
        }
      }

      .card-two-col {
        display: flex;
        width: 288px;
        justify-content: space-between;

        @include bp(mobile) {
          width: 210px;
        }
      }
    }
  }
}

.restaurantDetails__wrapper {
  .details__wrapper__inner {
    @include bp(991) {
      flex-direction: column;

      .right_content {
        display: flex;
        > span {
          // flex: 1;
          text-align: center;
          margin-right: 8px;
        }
      }
    }
  }
  .tab_details__wrapper {
    border-radius: 4px;
    box-shadow: 0 5px 9px -3px rgba(0, 0, 0, 0.08);
    background-color: #fff;
    padding: 6px 0px 6px 32px;
    margin: 32px 0px 25px 0px;

    @include bp(ipadpro) {
      padding: 6px 0px 6px 32px;
    }

    @include bp(mobile) {
      padding: 6px 4px;
      .nav{
        flex-wrap: nowrap;
        overflow-x: scroll;
        overflow-y: hidden;
      }
      .nav-item {
        .nav-link {
          white-space: nowrap;
          width: fit-content;
          margin-right: 12px;
          margin-left: 12px;
        }
      }
    }
  }
}

.shared__vb__listing__wrapper {
  padding: 40px 0px 0px 0px;

  @include bp(mobile) {
    padding-top: 64px;
  }

    h2 {
      @extend %font22-semibold;
      color: #131414;
  
      @include bp(mobile) {
        font-size: 15px;
      }
    }

  .shared__vb__table {
    margin-top: 24px;
    .mat-header-row {
      margin-bottom: 0;
    }
  }
}

.address-map {
  height: 234px;
  background: #eee;

  @include bp(mobile) {
    margin-bottom: 16px;
  }
}

.restaurantDetails__wrapper {
  .shared__vb__listing__wrapper {
    padding-top: 0px;

    .managermodifier__wrapper {
      padding-bottom: 0;
    }
  }
}

// .restaurantDetails__wrapper
//   .managermodifier__wrapper
//   .managermodifier__listinPage__wrapper
//   .modifierListing__table
//   tbody
//   tr:last-child {
//   margin-bottom: 0;
// }

.restra__g__map > div {
  height: 250px;
  width: 100%;
  position: relative;
}

.restra__g__map {
  width: 396px;
  height: 243px;

  @include bp(991) {
    width: auto;
  }
}

.restaurantDetails__wrapper-map {
  padding-bottom: 196px !important;
}

.managedevice_listinPage {
  min-height: auto;
}

#commission__modal {
  max-width: 700px !important;
  .custom-input {
      max-width: none !important;
    }
}

.addnew__platform__btn{
  width: 110px !important;
  height: 32px !important;
  font-size: 13px !important;
  white-space: nowrap;
}

.icon-right{
  margin-right: 10px;
}

.percentage-adornment {
  position: absolute;
  top: 42px;
  right: 22px;
  color: #3f4a6e;
}