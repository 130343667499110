@import "~bootstrap/scss/bootstrap";
@import "../../assets/scss/base/base-include.scss";

.brandcard {
  max-width: 290px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.04);

  @include bp(laptop) {
    max-width: 100%;
    width: 100%;
  }

  a:hover {
    text-decoration: none;
  }

  .img__box {
    @include bp(ipad-min) {
      > img {
        max-width: 100%;
        width: 100%;
        height: 194px;
        object-fit: cover;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
      }
    }

    @include bp(mobile) {
      > img {
        max-width: 100%;
        width: 100%;
        height: 233px;
        object-fit: cover;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
      }
    }
  }

  @include bp(mobile) {
    width: 100%;
    max-width: 100%;
    min-height: 400px;
  }

  .brand__info {
    padding: 10px 16px;
    padding-bottom: 16px
  }

  .brand__info__header {
    @extend %font12-bold;
    line-height: normal;
    color: $theme-gray;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0px;

    a {
      @extend %font12-bold;
      line-height: normal;
      color: $theme-gray;
    }
  }

  .brand__name {
    margin-bottom: 16px;
    min-height: 56px;
    h3 {
      @extend %font21-semibold;
      line-height: 1.33;
      margin-bottom: 0;
      // max-width: 200px;
    }
  }

  .brand__info__footer {
    @extend %font11-medium;
    color: #777778;
    margin-bottom: 6px;

    @include bp(mobile) {
      font-size: 13px;
    }
  }
}

.sharedBrand__card {
  a.create-banner-btn,
  button {
    @extend %btn-red;
    @extend %font15-semibold;
    border: 0;
    color: #fff;
    margin-top: 18px;
    padding: 8px 30px;
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    height: 36px;

    @include bp(laptop) {
      padding: 8px 15px;
    }

    &:hover {
      text-decoration: none;
    }
  }
  .create-banner-btn.added-btn, button.added-btn{
    background: #6D7F8B !important;
    opacity: 1 !important;
  }
}

.icons {
  margin-right: 5px;
  margin-top: -3px;
}

.brand__info__header {
  .threeDotsBropdown.drop-icon
    #dropdown-button-drop-left[aria-expanded="false"]::before {
    width: 25px;
    height: 24px;
  }
  .threeDotsBropdown.drop-icon
    #dropdown-button-drop-left[aria-expanded="true"]::before {
    width: 25px;
    height: 24px;
  }
  .threeDotsBropdown.drop-icon .dropdown-menu {
    top: 30px !important;
  }
}

.brand__info__header {
  .custom__badge {
    height: 24px;
    padding: 7px 15.5px 5px 12.5px;
    font-family: 'Work Sans', sans-serif;
          font-weight: 600;
    font-size: 10px;
  }
}

.admin-logo {
  position: absolute;
  top: 16px;
  right: 10px;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: flex-end;
}
