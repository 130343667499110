@import "~bootstrap/scss/bootstrap";
@import "../../../../../assets/scss/base/base-include.scss";

.addnewCat__wrapper {
  padding-top: 70px;

  @include bp(mobile){
    padding-top: 48px;
  }
  
  .main__heading {
    h1 {
      @extend %font25-bold;
      line-height: 1.32;
      color: #131414;
      @include bp(ipad-min){
        margin-bottom: 0px;
      }

      @include bp(mobile){
        font-size: 21px;
      }
    }
  }
}
