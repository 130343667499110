@import "~bootstrap/scss/bootstrap";
@import "../../../assets/scss/base/base-include.scss";

.editModifier__wrapper {
  .modifieroptions__wrap > div:not(:last-child) {
    margin-right: 25px;
    @include bp(mobile) {
      margin-right: 15px;
    }
  }

  .modifieroptions__wrap > div {
    width: 50%;
  }

  .modifieroptions__wrap {
    @include bp(mobile) {
      .form-control:not(:last-child) {
        margin-bottom: 12px !important;
      }
    }
  }

  .basic__info__content {
    width: 100%;
    .form-row {
      max-width: 470px;
    }

    select.form-control {
      background: #fff url(../../../assets/images/select-arrow-icon.svg)
        no-repeat 96%;
    }
  }

  .modal-footer {
    @include bp(mobile) {
      margin-top: 48px;
    }
  }

  .modal-footer .btn {
    @include bp(mobile) {
      width: 100%;
      max-width: 100%;
      margin-left: auto;
      margin-right: auto;

      &:not(:last-child) {
        margin-bottom: 24px;
      }
    }
  }
}

.addmodifiers__options {
  .addModifiers-btn {
    background: transparent;
    border: none;
  }
}


.details__wrapper__inner{
  form{
    width: 100%;
  }
}